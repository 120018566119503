import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StorePerformanceInterval } from '../../../constants';
import { BudgetPlanned, TrendEnum } from '../../../constants/budgetPlanned';
import { SalesReceiptType } from '../../../global-state/types';
import {
  BudgetPlannedData,
  ReceiptSalesAnalysisResponse,
  useAsyncAction,
  useTransaction,
  useUser,
} from '../../../hooks';
import { Container, Icon, Icons, Typography } from '../../../stories/atoms';
import { Card, DropdownMenu } from '../../../stories/molecules';
import { ContainerDrawer } from '../../../stories/molecules/container-drawer/ContainerDrawer';
import { homeTranslations } from '../../../translations';
import { formatAmountWithSeparator, isFailureResponse, isKey } from '../../../utils';
import { computeBudgetPlannedData } from '../../../utils/budgetPlanned';
import {
  BudgetPlannedContent,
  getBudgetAndPlannedPermissions,
} from '../budgetPlannedContent/BudgetPlannedContent';
import { StyledTrendIconComponent } from '../styledTrendIcon/StyledTrendIcon';
import { WidgetCommonState } from '../widgetCommonState/WidgetCommonState';
import {
  StyledContainer,
  StyledIcon,
  StyledSalesReceiptWrapper,
  StyledSection,
  StyledWrapper,
} from './ReceiptSalesWidget.styles';

type BudgetPlannedSliderType = 'Takt1Slider' | 'SptSlider';
const budgetPlannedSliderTypes: BudgetPlannedSliderType[] = ['Takt1Slider', 'SptSlider'];

export type ReceiptSalesWidgetProps = {
  currentDate: Date;
  interval?: StorePerformanceInterval;
};

export const ReceiptSalesWidget: FC<ReceiptSalesWidgetProps> = observer(({ currentDate, interval }) => {
  const { t } = useTranslation();
  const {
    get: { userPermissions },
  } = useUser();

  const { fetchReceiptSalesAnalysisData, fetchTakt1Budget, fetchSptBudget } = useTransaction();

  const { common, receiptSales: receiptSalesTranslation, budgetPlanned } = homeTranslations;

  const [isBudgetSliderOpen, setIsBudgetSliderOpen] = useState(false);
  const [selectedSlider, setSelectedSlider] = useState<BudgetPlannedSliderType>('Takt1Slider');

  const { canViewBudget, canViewPlanned, showBudgetAndPlanned } = getBudgetAndPlannedPermissions(userPermissions);

  const {
    result: receiptSalesAnalysisData,
    isLoading: receiptSalesAnalysisLoading,
    isError: receiptSalesAnalysisError,
    triggerAction: triggerFetchReceiptSalesAnalysis,
  } = useAsyncAction<ReceiptSalesAnalysisResponse>();

  const {
    result: takt1BudgetPlanned,
    isLoading: takt1BudgetPlannedLoading,
    isError: takt1BudgetPlannedError,
    triggerAction: triggerFetchTakt1BBudgetPlanned,
  } = useAsyncAction<BudgetPlannedData>();

  const {
    result: sptBudgetPlanned,
    isLoading: sptBudgetPlannedLoading,
    isError: sptBudgetPlannedError,
    triggerAction: triggerFetchSptBudgetPlanned,
  } = useAsyncAction<BudgetPlannedData>();

  const fetchReceiptSalesAnalysis = async () => {
    const date = format(currentDate as Date, 'yyyy-MM-dd');
    const response = await fetchReceiptSalesAnalysisData(date);

    if (isFailureResponse(response)) {
      throw new Error();
    }

    return response.data;
  };

  const fetchTakt1BudgetPlanned = async () => {
    const date = format(currentDate as Date, 'yyyy-MM-dd');
    const response = await fetchTakt1Budget(date);

    if (isFailureResponse(response)) {
      throw new Error();
    }

    return response.data;
  };

  const fetchSptBudgetPlanned = async () => {
    const date = format(currentDate as Date, 'yyyy-MM-dd');
    const response = await fetchSptBudget(date);

    if (isFailureResponse(response)) {
      throw new Error();
    }

    return response.data;
  };

  useEffect(() => {
    if (interval === StorePerformanceInterval.WEEKLY) return; // TODO: Remove once backend implements this
    triggerFetchReceiptSalesAnalysis(fetchReceiptSalesAnalysis);
    triggerFetchTakt1BBudgetPlanned(fetchTakt1BudgetPlanned);
    triggerFetchSptBudgetPlanned(fetchSptBudgetPlanned);
  }, [currentDate, interval]);

  const {
    budgetValue: takt1Budget,
    plannedValue: takt1Planned,
    budgetCalc: takt1BudgetCalc,
    plannedCalc: takt1PlannedCalc,
  } = computeBudgetPlannedData(receiptSalesAnalysisData?.takt1.today, takt1BudgetPlanned);

  const {
    budgetValue: sptBudget,
    plannedValue: sptPlanned,
    budgetCalc: sptBudgetCalc,
    plannedCalc: sptPlannedCalc,
  } = computeBudgetPlannedData(receiptSalesAnalysisData?.spt.today, sptBudgetPlanned);

  const takt1BudgetData: BudgetPlanned = {
    trend: takt1BudgetCalc < 100 ? TrendEnum.DOWN : TrendEnum.UP,
    data: takt1Budget.toLocaleString(),
    percentage: takt1BudgetCalc,
  };

  const takt1PlannedData: BudgetPlanned = {
    trend: takt1PlannedCalc < 100 ? TrendEnum.DOWN : TrendEnum.UP,
    data: takt1Planned.toLocaleString(),
    percentage: takt1PlannedCalc,
  };

  const sptBudgetData: BudgetPlanned = {
    trend: sptBudgetCalc < 100 ? TrendEnum.DOWN : TrendEnum.UP,
    data: sptBudget.toLocaleString(),
    percentage: sptBudgetCalc,
  };

  const sptPlannedData: BudgetPlanned = {
    trend: sptPlannedCalc < 100 ? TrendEnum.DOWN : TrendEnum.UP,
    data: sptPlanned.toLocaleString(),
    percentage: sptPlannedCalc,
  };

  const isNoData =
    receiptSalesAnalysisData &&
    receiptSalesAnalysisData.takt1.today === 0 &&
    receiptSalesAnalysisData.spt.today === 0;

  return (
    <ContainerDrawer
      isOpen={isBudgetSliderOpen}
      onClose={() => setIsBudgetSliderOpen(false)}
      sliderContent={
        <>
          {selectedSlider === 'Takt1Slider' && takt1BudgetPlanned && (
            <BudgetPlannedContent
              isLoading={takt1BudgetPlannedLoading}
              isError={takt1BudgetPlannedError}
              canViewBudget={canViewBudget}
              canViewPlanned={canViewPlanned}
              budget={takt1BudgetData}
              planned={takt1PlannedData}
            />
          )}

          {selectedSlider === 'SptSlider' && sptBudgetPlanned && (
            <BudgetPlannedContent
              isLoading={sptBudgetPlannedLoading}
              isError={sptBudgetPlannedError}
              canViewBudget={canViewBudget}
              canViewPlanned={canViewPlanned}
              budget={sptBudgetData}
              planned={sptPlannedData}
            />
          )}
        </>
      }
      width="250px"
    >
      <Card
        headerIcon={Icons.SALESEFFICIENCY}
        headerText={t(receiptSalesTranslation.title.key, receiptSalesTranslation.title.defaultValue)}
        subHeaderText={t(receiptSalesTranslation.subTitle.key, receiptSalesTranslation.subTitle.defaultValue)}
        toolTipText={t(receiptSalesTranslation.toolTip.key, receiptSalesTranslation.toolTip.defaultValue)}
        headerRightElement={
          <StyledWrapper>
            <DropdownMenu
              data-testid="kpiDropdown"
              dropdownLabel={''}
              buttonContentProps={{
                iconOptions: { customIcon: <Icon icon={Icons.MORE} /> },
              }}
              menuItems={[
                {
                  key: budgetPlannedSliderTypes[0],
                  disabled:
                    !showBudgetAndPlanned ||
                    takt1BudgetPlannedError ||
                    interval === StorePerformanceInterval.WEEKLY,
                  hasTooltip: !showBudgetAndPlanned || interval === StorePerformanceInterval.WEEKLY,
                  tooltipText: (
                    <Container padding={[1]}>
                      <Typography color="white">
                        {t(
                          budgetPlanned.noPermissionBudgetTooltip.key,
                          budgetPlanned.noPermissionBudgetTooltip.defaultValue,
                        )}
                      </Typography>
                    </Container>
                  ),
                  item: (
                    <Typography margin={[0, 2, 0]}>
                      {t(receiptSalesTranslation.budgetTakt.key, receiptSalesTranslation.budgetTakt.defaultValue)}
                    </Typography>
                  ),
                },
                {
                  key: budgetPlannedSliderTypes[1],
                  disabled:
                    !showBudgetAndPlanned || sptBudgetPlannedError || interval === StorePerformanceInterval.WEEKLY,
                  hasTooltip: !showBudgetAndPlanned || interval === StorePerformanceInterval.WEEKLY,
                  tooltipText: (
                    <Container padding={[1]}>
                      <Typography color="white">
                        {t(
                          budgetPlanned.noPermissionBudgetTooltip.key,
                          budgetPlanned.noPermissionBudgetTooltip.defaultValue,
                        )}
                      </Typography>
                    </Container>
                  ),
                  item: (
                    <Typography margin={[0, 2, 0]}>
                      {t(receiptSalesTranslation.budgetSpt.key, receiptSalesTranslation.budgetSpt.defaultValue)}
                    </Typography>
                  ),
                },
              ]}
              minWidth={'0px'}
              isCompact
              initialSelected={[]}
              onSelect={(selectedItems) => {
                setSelectedSlider(selectedItems[0] as BudgetPlannedSliderType);
                setIsBudgetSliderOpen(true);
              }}
              isOnlyIcon
              allowTooltip
            />
          </StyledWrapper>
        }
        content={
          <>
            {receiptSalesAnalysisLoading && <WidgetCommonState isLoading />}

            {interval === StorePerformanceInterval.WEEKLY && <WidgetCommonState isNoData isNoWeeklyData />}

            {receiptSalesAnalysisError && (
              <WidgetCommonState
                isError
                errorText={t(receiptSalesTranslation.error.key, receiptSalesTranslation.error.defaultValue)}
              />
            )}

            {!receiptSalesAnalysisLoading && !receiptSalesAnalysisError && isNoData && (
              <WidgetCommonState isNoData />
            )}

            {!receiptSalesAnalysisLoading && !receiptSalesAnalysisError && !isNoData && (
              <StyledSalesReceiptWrapper
                wrap="nowrap"
                padding={[4]}
                data-testid="sales-receipt-wrapper"
                height="100%"
              >
                {receiptSalesAnalysisData &&
                  Object.keys(receiptSalesAnalysisData).map((key) => {
                    if (!isKey(receiptSalesAnalysisData, key)) return;

                    const data = receiptSalesAnalysisData[key];

                    return (
                      <StyledSection
                        key={key}
                        wrap="nowrap"
                        padding={[4]}
                        margin={[0, 0, 5]}
                        direction="horizontal"
                        space="between"
                      >
                        <Container style={{ flex: 1 }}>
                          <StyledContainer direction="horizontal">
                            <StyledIcon
                              icon={key === SalesReceiptType.Takt1 ? Icons.DOLLAR : Icons.BAG}
                              size="small"
                              margin={[0, 1, 0, 0]}
                            />
                            <Typography>
                              {t(receiptSalesTranslation[key].key, receiptSalesTranslation[key].defaultValue)}
                            </Typography>
                          </StyledContainer>
                          <Typography type="h3">{formatAmountWithSeparator(data.today)}</Typography>
                        </Container>

                        <Container>
                          <Container margin={[0, 0, 3]}>
                            <Typography color="textGray" margin={[0, 0, 1]}>
                              {t(common.lastWeek.key, common.lastWeek.defaultValue)}
                            </Typography>
                            <Container direction="horizontal" space="between">
                              <StyledTrendIconComponent
                                originalAmount={data.today}
                                previousAmount={data.lastWeek}
                                padding={[0, 0, 0, 2]}
                                direction="horizontal"
                                valueFormatter={(value) => formatAmountWithSeparator(value)}
                              />
                            </Container>
                          </Container>
                          <Container>
                            <Typography color="textGray" margin={[0, 0, 1]}>
                              {t(common.lastYear.key, common.lastYear.defaultValue)}
                            </Typography>
                            <Container direction="horizontal" space="between">
                              <StyledTrendIconComponent
                                originalAmount={data.today}
                                previousAmount={data.lastYear}
                                padding={[0, 0, 0, 2]}
                                direction="horizontal"
                                valueFormatter={(value) => formatAmountWithSeparator(value)}
                              />
                            </Container>
                          </Container>
                        </Container>
                      </StyledSection>
                    );
                  })}
              </StyledSalesReceiptWrapper>
            )}
          </>
        }
      />
    </ContainerDrawer>
  );
});
