import { ProductIndexItem } from '../../hooks';

const mockProductIndex: ProductIndexItem[] = [
  {
    id: '1016974',
    lastUpdated: '2024-07-12',
    productName: 'Unknown Product Name',
    departmentId: 'Unknown Department',
    departmentName: 'Unknown Department Name',
    season: 202409,
    whitePrice: 0,
    currency: '',
    divisionName: 'Unknown Division',
    sectionName: 'Unknown Section',
    indexGroupName: 'Unknown Index Group',
    indexDescription: 'Unknown Index Description',
    subindex: 'Unknown SubIndex',
    garmentGroup: 'Unknown Garment Group',
    salesNet: 55.31,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1017488',
    lastUpdated: '2024-07-01T07:26:30.070Z',
    productName: 'Connie tulle skirt',
    departmentId: '497',
    departmentName: 'Kids Girl Dresses',
    season: 202409,
    whitePrice: 149,
    currency: 'SEK',
    divisionName: 'Kids',
    sectionName: 'Kids Girl',
    indexGroupName: 'Baby/Children',
    indexDescription: 'Children Sizes 92-140',
    subindex: 'Girl 92-140 H&M Kids',
    garmentGroup: 'Dresses/Skirts girls',
    salesNet: 90,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1017708',
    lastUpdated: '2024-07-01T07:26:29.938Z',
    productName: 'BELLA TANKS',
    departmentId: '539',
    departmentName: 'Young Girl Jersey Basic',
    season: 202409,
    whitePrice: 49.99,
    currency: 'SEK',
    divisionName: 'Young',
    sectionName: 'Young Girl',
    indexGroupName: 'Baby/Children',
    indexDescription: 'Children Sizes 134-170',
    subindex: 'Girl 134-170. Basic, NW, UW & Socks',
    garmentGroup: 'Jersey Basic',
    salesNet: 158.58999999999997,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1019586',
    lastUpdated: '2024-07-01T07:26:28.474Z',
    productName: 'SHORTS PULL ON BELLE',
    departmentId: '535',
    departmentName: 'Kids Girl Shorts',
    season: 202409,
    whitePrice: 99,
    currency: 'SEK',
    divisionName: 'Kids',
    sectionName: 'Kids Girl',
    indexGroupName: 'Baby/Children',
    indexDescription: 'Children Sizes 92-140',
    subindex: 'Girl 92-140 H&M Kids',
    garmentGroup: 'Shorts',
    salesNet: 490.84000000000003,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1019679',
    lastUpdated: '2024-07-01T07:26:30.796Z',
    productName: 'Yate LO hood',
    departmentId: '492',
    departmentName: 'Heavy Graphic Jersey',
    season: 202409,
    whitePrice: 299,
    currency: 'SEK',
    divisionName: 'Menswear',
    sectionName: 'Men Jersey Graphics',
    indexGroupName: 'Menswear',
    indexDescription: 'Menswear',
    subindex: 'Menswear Collection',
    garmentGroup: 'Jersey Fancy',
    salesNet: 1123.23,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1020258',
    lastUpdated: '2024-07-01T07:26:28.628Z',
    productName: 'Maxi claw',
    departmentId: '262',
    departmentName: 'Hair',
    season: 202409,
    whitePrice: 39.99,
    currency: 'SEK',
    divisionName: 'Womens Accessories',
    sectionName: 'Womens Small accessories',
    indexGroupName: 'Ladieswear',
    indexDescription: 'Womenswear Accessories',
    subindex: 'Womens Small Accessories',
    garmentGroup: 'Accessories',
    salesNet: 139.78,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1020274',
    lastUpdated: '2024-07-01T07:50:16.128Z',
    productName: 'The Ordinary Serum Niacinamide 1',
    departmentId: '1071',
    departmentName: 'Skincare',
    season: 0,
    whitePrice: 0,
    currency: '',
    divisionName: 'Cosmetics',
    sectionName: 'External Cosmetics',
    indexGroupName: 'Cosmetic',
    indexDescription: 'Cosmetic',
    subindex: 'External Cosmetic',
    garmentGroup: 'Cosmetic',
    salesNet: 178,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1020278',
    lastUpdated: '2024-07-01T07:50:16.092Z',
    productName: 'The Ordinary Serum Granactive Re',
    departmentId: '1071',
    departmentName: 'Skincare',
    season: 0,
    whitePrice: 0,
    currency: '',
    divisionName: 'Cosmetics',
    sectionName: 'External Cosmetics',
    indexGroupName: 'Cosmetic',
    indexDescription: 'Cosmetic',
    subindex: 'External Cosmetic',
    garmentGroup: 'Cosmetic',
    salesNet: 157,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1020279',
    lastUpdated: '2024-07-01T12:14:45.603Z',
    productName: 'The Ordinary Toner Glycolic Acid',
    departmentId: '1071',
    departmentName: 'Skincare',
    season: 0,
    whitePrice: 0,
    currency: '',
    divisionName: 'Cosmetics',
    sectionName: 'External Cosmetics',
    indexGroupName: 'Cosmetic',
    indexDescription: 'Cosmetic',
    subindex: 'External Cosmetic',
    garmentGroup: 'Cosmetic',
    salesNet: 174,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1020281',
    lastUpdated: '2024-07-12',
    productName: 'The Ordinary Eye Serum Caffeine',
    departmentId: '1071',
    departmentName: 'Skincare',
    season: 0,
    whitePrice: 0,
    currency: '',
    divisionName: 'Cosmetics',
    sectionName: 'External Cosmetics',
    indexGroupName: 'Cosmetic',
    indexDescription: 'Cosmetic',
    subindex: 'External Cosmetic',
    garmentGroup: 'Cosmetic',
    salesNet: 119,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1020286',
    lastUpdated: '2024-07-12',
    productName: 'The Ordinary Exfoliater AHA 30%',
    departmentId: '1071',
    departmentName: 'Skincare',
    season: 0,
    whitePrice: 0,
    currency: '',
    divisionName: 'Cosmetics',
    sectionName: 'External Cosmetics',
    indexGroupName: 'Cosmetic',
    indexDescription: 'Cosmetic',
    subindex: 'External Cosmetic',
    garmentGroup: 'Cosmetic',
    salesNet: 127,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1020977',
    lastUpdated: '2024-07-01T07:26:30.780Z',
    productName: 'Dahlia dress',
    departmentId: '497',
    departmentName: 'Kids Girl Dresses',
    season: 202409,
    whitePrice: 99,
    currency: 'SEK',
    divisionName: 'Kids',
    sectionName: 'Kids Girl',
    indexGroupName: 'Baby/Children',
    indexDescription: 'Children Sizes 92-140',
    subindex: 'Girl 92-140 H&M Kids',
    garmentGroup: 'Dresses/Skirts girls',
    salesNet: 1228.78,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1021953',
    lastUpdated: '2024-07-12',
    productName: 'Unknown Product Name',
    departmentId: 'Unknown Department',
    departmentName: 'Unknown Department Name',
    season: 202409,
    whitePrice: 0,
    currency: '',
    divisionName: 'Unknown Division',
    sectionName: 'Unknown Section',
    indexGroupName: 'Unknown Index Group',
    indexDescription: 'Unknown Index Description',
    subindex: 'Unknown SubIndex',
    garmentGroup: 'Unknown Garment Group',
    salesNet: 240,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1022630',
    lastUpdated: '2024-07-12',
    productName: 'Unknown Product Name',
    departmentId: 'Unknown Department',
    departmentName: 'Unknown Department Name',
    season: 202409,
    whitePrice: 0,
    currency: '',
    divisionName: 'Unknown Division',
    sectionName: 'Unknown Section',
    indexGroupName: 'Unknown Index Group',
    indexDescription: 'Unknown Index Description',
    subindex: 'Unknown SubIndex',
    garmentGroup: 'Unknown Garment Group',
    salesNet: 355.91,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1022631',
    lastUpdated: '2024-07-01T07:26:28.963Z',
    productName: 'MY DRESS 3PACK',
    departmentId: '536',
    departmentName: 'Kids Girl Jersey Basic',
    season: 202409,
    whitePrice: 249,
    currency: 'SEK',
    divisionName: 'Kids',
    sectionName: 'Kids Essentials',
    indexGroupName: 'Baby/Children',
    indexDescription: 'Children Sizes 92-140',
    subindex: 'Girl 92-140. Basic, NW, UW & Socks',
    garmentGroup: 'Jersey Basic',
    salesNet: 1569.15,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1022736',
    lastUpdated: '2024-07-12',
    productName: 'Unknown Product Name',
    departmentId: 'Unknown Department',
    departmentName: 'Unknown Department Name',
    season: 202409,
    whitePrice: 0,
    currency: '',
    divisionName: 'Unknown Division',
    sectionName: 'Unknown Section',
    indexGroupName: 'Unknown Index Group',
    indexDescription: 'Unknown Index Description',
    subindex: 'Unknown SubIndex',
    garmentGroup: 'Unknown Garment Group',
    salesNet: 75,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1024141',
    lastUpdated: '2024-07-12',
    productName: 'Unknown Product Name',
    departmentId: 'Unknown Department',
    departmentName: 'Unknown Department Name',
    season: 202409,
    whitePrice: 0,
    currency: '',
    divisionName: 'Unknown Division',
    sectionName: 'Unknown Section',
    indexGroupName: 'Unknown Index Group',
    indexDescription: 'Unknown Index Description',
    subindex: 'Unknown SubIndex',
    garmentGroup: 'Unknown Garment Group',
    salesNet: 50,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1024256',
    lastUpdated: '2024-07-01T07:26:32.310Z',
    productName: 'Slim LP Jeans',
    departmentId: '340',
    departmentName: 'Denim trousers',
    season: 202409,
    whitePrice: 299,
    currency: 'SEK',
    divisionName: 'Menswear',
    sectionName: 'Men Denim',
    indexGroupName: 'Menswear',
    indexDescription: 'Menswear',
    subindex: 'Denim Men',
    garmentGroup: 'Trousers Denim',
    salesNet: 175,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1024711',
    lastUpdated: '2024-07-01T07:26:32.725Z',
    productName: 'Slim MP Jeans',
    departmentId: '340',
    departmentName: 'Denim trousers',
    season: 202409,
    whitePrice: 449,
    currency: 'SEK',
    divisionName: 'Menswear',
    sectionName: 'Men Denim',
    indexGroupName: 'Menswear',
    indexDescription: 'Menswear',
    subindex: 'Denim Men',
    garmentGroup: 'Trousers Denim',
    salesNet: 649,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1024745',
    lastUpdated: '2024-07-01T07:26:30.698Z',
    productName: 'YG MIA one shoulder swimsuit',
    departmentId: '258',
    departmentName: 'Young Girl Swimwear',
    season: 202409,
    whitePrice: 199,
    currency: 'SEK',
    divisionName: 'Young',
    sectionName: 'Young Essentials & Complements',
    indexGroupName: 'Baby/Children',
    indexDescription: 'Children Accessories, Swimwear, Shoes & Sport',
    subindex: 'Young 134-170, Accessories & swimwear',
    garmentGroup: 'Swimwear',
    salesNet: 1165,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1025664',
    lastUpdated: '2024-07-01T07:26:30.873Z',
    productName: 'REX REG CA TECH SHIRT',
    departmentId: '671',
    departmentName: 'Shirt S&T',
    season: 202409,
    whitePrice: 349,
    currency: 'SEK',
    divisionName: 'Menswear',
    sectionName: 'Men Shirts',
    indexGroupName: 'Menswear',
    indexDescription: 'Menswear',
    subindex: 'Mens Suit',
    garmentGroup: 'Shirts',
    salesNet: 450,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1025726',
    lastUpdated: '2024-07-12',
    productName: 'Unknown Product Name',
    departmentId: 'Unknown Department',
    departmentName: 'Unknown Department Name',
    season: 202409,
    whitePrice: 0,
    currency: '',
    divisionName: 'Unknown Division',
    sectionName: 'Unknown Section',
    indexGroupName: 'Unknown Index Group',
    indexDescription: 'Unknown Index Description',
    subindex: 'Unknown SubIndex',
    garmentGroup: 'Unknown Garment Group',
    salesNet: 56.25,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1025935',
    lastUpdated: '2024-07-01T07:51:22.351Z',
    productName: 'MY RIB DRESS',
    departmentId: '536',
    departmentName: 'Kids Girl Jersey Basic',
    season: 202409,
    whitePrice: 79.99,
    currency: 'SEK',
    divisionName: 'Kids',
    sectionName: 'Kids Essentials',
    indexGroupName: 'Baby/Children',
    indexDescription: 'Children Sizes 92-140',
    subindex: 'Girl 92-140. Basic, NW, UW & Socks',
    garmentGroup: 'Jersey Basic',
    salesNet: 2651.6099999999997,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1026483',
    lastUpdated: '2024-07-01T07:26:29.201Z',
    productName: 'Maggie dress',
    departmentId: '497',
    departmentName: 'Kids Girl Dresses',
    season: 202409,
    whitePrice: 199,
    currency: 'SEK',
    divisionName: 'Kids',
    sectionName: 'Kids Girl',
    indexGroupName: 'Baby/Children',
    indexDescription: 'Children Sizes 92-140',
    subindex: 'Girl 92-140 H&M Kids',
    garmentGroup: 'Dresses/Skirts girls',
    salesNet: 2788.92,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1026706',
    lastUpdated: '2024-07-12',
    productName: 'FRANCIS DRESS',
    departmentId: '536',
    departmentName: 'Kids Girl Jersey Basic',
    season: 202409,
    whitePrice: 149,
    currency: 'SEK',
    divisionName: 'Kids',
    sectionName: 'Kids Essentials',
    indexGroupName: 'Baby/Children',
    indexDescription: 'Children Sizes 92-140',
    subindex: 'Girl 92-140. Basic, NW, UW & Socks',
    garmentGroup: 'Jersey Basic',
    salesNet: 95.28999999999999,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1026876',
    lastUpdated: '2024-07-12',
    productName: 'Unknown Product Name',
    departmentId: 'Unknown Department',
    departmentName: 'Unknown Department Name',
    season: 202409,
    whitePrice: 0,
    currency: '',
    divisionName: 'Unknown Division',
    sectionName: 'Unknown Section',
    indexGroupName: 'Unknown Index Group',
    indexDescription: 'Unknown Index Description',
    subindex: 'Unknown SubIndex',
    garmentGroup: 'Unknown Garment Group',
    salesNet: 60,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1026908',
    lastUpdated: '2024-07-12',
    productName: 'Unknown Product Name',
    departmentId: 'Unknown Department',
    departmentName: 'Unknown Department Name',
    season: 202409,
    whitePrice: 0,
    currency: '',
    divisionName: 'Unknown Division',
    sectionName: 'Unknown Section',
    indexGroupName: 'Unknown Index Group',
    indexDescription: 'Unknown Index Description',
    subindex: 'Unknown SubIndex',
    garmentGroup: 'Unknown Garment Group',
    salesNet: 60,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1027119',
    lastUpdated: '2024-07-12',
    productName: 'Cool Bo hoop pk',
    departmentId: '263',
    departmentName: 'Jewellery',
    season: 202409,
    whitePrice: 69.99,
    currency: 'SEK',
    divisionName: 'Womens Accessories',
    sectionName: 'Womens Small accessories',
    indexGroupName: 'Ladieswear',
    indexDescription: 'Womenswear Accessories',
    subindex: 'Womens Small Accessories',
    garmentGroup: 'Accessories',
    salesNet: 20,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1027477',
    lastUpdated: '2024-06-28T10:35:27.649Z',
    productName: 'KB ASTON LICENSE',
    departmentId: '241',
    departmentName: 'Kids Boy Swimwear Inactive from s.2',
    season: 202409,
    whitePrice: 179,
    currency: 'SEK',
    divisionName: 'Kids',
    sectionName: 'Kids Complements',
    indexGroupName: 'Baby/Children',
    indexDescription: 'Children Accessories, Swimwear, Shoes & Sport',
    subindex: 'Kids 92-140, Accessories & swimwear',
    garmentGroup: 'Swimwear',
    salesNet: 1285,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1027479',
    lastUpdated: '2024-07-01T07:26:29.683Z',
    productName: 'KB TRUNKS LICENSE',
    departmentId: '241',
    departmentName: 'Kids Boy Swimwear Inactive from s.2',
    season: 202409,
    whitePrice: 99,
    currency: 'SEK',
    divisionName: 'Kids',
    sectionName: 'Kids Complements',
    indexGroupName: 'Baby/Children',
    indexDescription: 'Children Accessories, Swimwear, Shoes & Sport',
    subindex: 'Kids 92-140, Accessories & swimwear',
    garmentGroup: 'Swimwear',
    salesNet: 858.8299999999999,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1027481',
    lastUpdated: '2024-07-01T12:14:46.236Z',
    productName: 'KB LUKE Trunks',
    departmentId: '241',
    departmentName: 'Kids Boy Swimwear Inactive from s.2',
    season: 202409,
    whitePrice: 79.99,
    currency: 'SEK',
    divisionName: 'Kids',
    sectionName: 'Kids Complements',
    indexGroupName: 'Baby/Children',
    indexDescription: 'Children Accessories, Swimwear, Shoes & Sport',
    subindex: 'Kids 92-140, Accessories & swimwear',
    garmentGroup: 'Swimwear',
    salesNet: 319.96,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1027487',
    lastUpdated: '2024-07-01T07:26:28.581Z',
    productName: 'KB UV SUIT LICENSE',
    departmentId: '241',
    departmentName: 'Kids Boy Swimwear Inactive from s.2',
    season: 202409,
    whitePrice: 199,
    currency: 'SEK',
    divisionName: 'Kids',
    sectionName: 'Kids Complements',
    indexGroupName: 'Baby/Children',
    indexDescription: 'Children Accessories, Swimwear, Shoes & Sport',
    subindex: 'Kids 92-140, Accessories & swimwear',
    garmentGroup: 'Swimwear',
    salesNet: 663,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1027490',
    lastUpdated: '2024-07-12',
    productName: 'KB PRICE TRUNKS',
    departmentId: '241',
    departmentName: 'Kids Boy Swimwear Inactive from s.2',
    season: 202409,
    whitePrice: 49.99,
    currency: 'SEK',
    divisionName: 'Kids',
    sectionName: 'Kids Complements',
    indexGroupName: 'Baby/Children',
    indexDescription: 'Children Accessories, Swimwear, Shoes & Sport',
    subindex: 'Kids 92-140, Accessories & swimwear',
    garmentGroup: 'Swimwear',
    salesNet: 49.99,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1027844',
    lastUpdated: '2024-07-01T12:14:47.208Z',
    productName: 'Astrid linen shirt',
    departmentId: '46',
    departmentName: 'Sets (Casual Blouse)',
    season: 202409,
    whitePrice: 199,
    currency: 'SEK',
    divisionName: 'Womens Light Woven',
    sectionName: 'Womens Blouse, Shirt, Sets & Skirt',
    indexGroupName: 'Ladieswear',
    indexDescription: 'Womenswear',
    subindex: 'H&M Womens Casual',
    garmentGroup: 'Dresses Ladies',
    salesNet: 319,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1028144',
    lastUpdated: '2024-07-01T07:26:31.364Z',
    productName: 'CU Zed Lse SSL',
    departmentId: '353',
    departmentName: 'Shirt Street',
    season: 202409,
    whitePrice: 199,
    currency: 'SEK',
    divisionName: 'Menswear',
    sectionName: 'Men Shirts',
    indexGroupName: 'Menswear',
    indexDescription: 'Menswear',
    subindex: 'Contemporary Street',
    garmentGroup: 'Shirts',
    salesNet: 199,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1029317',
    lastUpdated: '2024-07-01T12:14:45.644Z',
    productName: 'ROMAN RG LS T-SHIRT',
    departmentId: '1340',
    departmentName: 'Light Basic Jersey',
    season: 202409,
    whitePrice: 99,
    currency: 'SEK',
    divisionName: 'Menswear',
    sectionName: 'Men Jersey Basic',
    indexGroupName: 'Menswear',
    indexDescription: 'Menswear',
    subindex: 'H&M Men Basics',
    garmentGroup: 'Jersey Basic',
    salesNet: 106,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1030667',
    lastUpdated: '2024-07-12',
    productName: 'Unknown Product Name',
    departmentId: 'Unknown Department',
    departmentName: 'Unknown Department Name',
    season: 202409,
    whitePrice: 0,
    currency: '',
    divisionName: 'Unknown Division',
    sectionName: 'Unknown Section',
    indexGroupName: 'Unknown Index Group',
    indexDescription: 'Unknown Index Description',
    subindex: 'Unknown SubIndex',
    garmentGroup: 'Unknown Garment Group',
    salesNet: 70,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1031048',
    lastUpdated: '2024-07-01T07:26:30.355Z',
    productName: 'Shorts Loose',
    departmentId: '967',
    departmentName: 'Young Boy Shorts',
    season: 202409,
    whitePrice: 199,
    currency: 'SEK',
    divisionName: 'Young',
    sectionName: 'Young Boy',
    indexGroupName: 'Baby/Children',
    indexDescription: 'Children Sizes 134-170',
    subindex: 'Boy 134-170 H&M Young',
    garmentGroup: 'Shorts',
    salesNet: 434.1,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1031495',
    lastUpdated: '2024-07-01',
    productName: 'Unknown Product Name',
    departmentId: 'Unknown Department',
    departmentName: 'Unknown Department Name',
    season: 202409,
    whitePrice: 0,
    currency: '',
    divisionName: 'Unknown Division',
    sectionName: 'Unknown Section',
    indexGroupName: 'Unknown Index Group',
    indexDescription: 'Unknown Index Description',
    subindex: 'Unknown SubIndex',
    garmentGroup: 'Unknown Garment Group',
    salesNet: 49.56,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
  {
    id: '1031505',
    lastUpdated: '2024-07-01T07:26:31.085Z',
    productName: 'Heather set 2PCS',
    departmentId: '497',
    departmentName: 'Kids Girl Dresses',
    season: 202409,
    whitePrice: 179,
    currency: 'SEK',
    divisionName: 'Kids',
    sectionName: 'Kids Girl',
    indexGroupName: 'Baby/Children',
    indexDescription: 'Children Sizes 92-140',
    subindex: 'Girl 92-140 H&M Kids',
    garmentGroup: 'Dresses/Skirts girls',
    salesNet: 1136.9,
    salesNetLastWeek: 0,
    salesNetLastYear: 0,
  },
];

export const getMockProductIndex = () => mockProductIndex;
