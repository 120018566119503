import { useRef, useState } from 'react';
import { StorePerformanceInterval } from '../constants';
import { getClippedDailyPerformanceItems, getForecastPerformanceItems, getTodayPerformanceItems } from '../utils';
import { StorePerformanceItem } from './useTransaction';
import { useAsyncAction } from './useAsyncAction';
import { useStoreOpeningHours } from './useStoreOpeningHours';
import { useStoreInformation } from './useStoreInformation';

type GetDataAction<T> = () => Promise<T[]>;
type GetForecastDataAction<T> = () => Promise<T[]>;
type PerformanceItemValueResolver<T> = (storePerformanceItem: T) => number;

export const useStorePerformance = <T extends StorePerformanceItem>() => {
  const { getStoreOpeningHours } = useStoreOpeningHours();
  const { timezone } = useStoreInformation();

  const {
    result: data,
    isLoading: isDataLoading,
    isError: isDataError,
    triggerAction: triggerFetchData,
  } = useAsyncAction<T[]>();

  const {
    result: forecastData,
    isLoading: isForecastDataLoading,
    isError: isForecastDataError,
    triggerAction: triggerFetchForecastData,
  } = useAsyncAction<T[]>();

  const [interval, setInterval] = useState<StorePerformanceInterval>(StorePerformanceInterval.WEEKLY);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [isToday, setIsToday] = useState<boolean>(false);
  const valueResolver = useRef<PerformanceItemValueResolver<T>>(() => 0);

  const triggerGetStorePerformance = async (params: {
    interval: StorePerformanceInterval;
    currentDate: Date;
    isToday: boolean;
    getDataAction: GetDataAction<T>;
    valueResolver: PerformanceItemValueResolver<T>;
    getForecastDataAction?: GetForecastDataAction<T>;
  }) => {
    setInterval(params.interval);
    setCurrentDate(params.currentDate);
    setIsToday(params.isToday);
    valueResolver.current = params.valueResolver;

    triggerFetchData(params.getDataAction);

    const { getForecastDataAction } = params;

    if (params.isToday && getForecastDataAction) {
      triggerFetchForecastData(getForecastDataAction);
    }
  };

  const { storeOpeningHour, storeClosingHour } = getStoreOpeningHours(currentDate);

  const clippedItems = getClippedDailyPerformanceItems({
    interval,
    storeOpeningHour,
    storeClosingHour,
    storePerformanceItems: data || [],
  });

  const filteredItems = !isToday
    ? clippedItems
    : getTodayPerformanceItems({
        interval,
        currentUTCTime: currentDate,
        storeTimezone: timezone,
        storePerformanceItems: clippedItems,
        valueResolver: valueResolver.current,
      });

  const filteredForecastItems = getForecastPerformanceItems({
    interval,
    performanceItems: clippedItems,
    filteredPerformanceItems: filteredItems,
    forecastItems: forecastData || [],
  });

  return {
    performanceItems: clippedItems,
    performanceItemsLoading: isDataLoading,
    performanceItemsError: isDataError,

    filteredPerformanceItems: filteredItems,

    forecastPerformanceItems: filteredForecastItems,
    forecastPerformanceItemsLoading: isForecastDataLoading,
    forecastPerformanceItemsError: isForecastDataError,
    triggerGetStorePerformance,
  };
};
