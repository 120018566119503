import { format } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StorePerformanceInterval, TransactionFilterType } from '../../../constants';
import { RegisteredReceipts, useTransaction, useTransactions } from '../../../hooks';
import { Container, Icons, Typography } from '../../../stories/atoms';
import { Card } from '../../../stories/molecules';
import { homeTranslations } from '../../../translations/home';
import { isFailureResponse } from '../../../utils';
import { WidgetCommonState } from '../widgetCommonState/WidgetCommonState';
import {
  StyledContainer,
  StyledIcon,
  StyledIconWithText,
  StyledSection,
  StyledSubHeader,
  StyledWrapper,
} from './RegisteredReceiptsWidget.styles';

export type RegisteredReceiptProps = {
  isNonSigninUser?: boolean;
  transactionEventsCount: number;
  hasTransactionSearchPageReadPermission: boolean;
  currentDate: Date;
  interval?: StorePerformanceInterval;
};

type ReceiptCategory = {
  sale: RegisteredReceipts[];
  returns: RegisteredReceipts[];
};

export const RegisteredReceiptsWidget: FC<RegisteredReceiptProps> = ({
  isNonSigninUser,
  transactionEventsCount,
  hasTransactionSearchPageReadPermission,
  currentDate,
  interval,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { fetchCount, fetchTransactions, fetchRegisteredReceiptsData } = useTransaction();
  const {
    get: { table, isTransactionsConnectedToWidgetData, receiptWidgetTable },
    set: setTransactions,
  } = useTransactions();

  const { registeredReceipts } = homeTranslations;
  const [category, setCategory] = useState<ReceiptCategory>({
    sale: [],
    returns: [],
  });

  const [receiptDataList, setReceiptDataList] = useState<{
    data: RegisteredReceipts[];
    isLoading: boolean;
    isError: boolean;
  }>({
    data: [],
    isError: false,
    isLoading: false,
  });

  // Registered Receipts use effect
  useEffect(() => {
    if (isNonSigninUser) return;

    if (interval === StorePerformanceInterval.WEEKLY) {
      setReceiptDataList({ data: [], isError: false, isLoading: false });
      return;
    }

    const fetchCountData = async () => {
      setReceiptDataList({ data: [], isError: false, isLoading: true });

      const response = await fetchRegisteredReceiptsData(
        format(currentDate as Date, 'yyyy-MM-dd'),
        format(currentDate as Date, 'yyyy-MM-dd'),
      );

      if (isFailureResponse(response)) {
        setReceiptDataList({ data: [], isError: true, isLoading: false });
        return;
      }

      const { data } = response;

      setReceiptDataList({ data, isError: false, isLoading: false });
      setTransactions({
        receiptWidgetTable: {
          ...receiptWidgetTable,
          rows: data,
        },
      });
    };

    fetchCountData();
  }, [transactionEventsCount, currentDate, interval]);

  useEffect(() => {
    if (!receiptDataList.data.length) return;

    const saleCategories = receiptDataList.data.filter(({ item }) => item.includes('Sale'));
    const returnCategories = receiptDataList.data.filter(({ item }) => !item.includes('Sale'));
    setCategory({ sale: saleCategories, returns: returnCategories });
  }, [receiptDataList.data]);

  const fetchSelectedTransactions = async (selectedItem: string) => {
    const transactionResponse = await fetchTransactions(
      format(currentDate, 'yyyy-MM-dd'),
      format(currentDate, 'yyyy-MM-dd'),
      1,
      100,
      undefined,
      selectedItem,
    );

    if (isFailureResponse(transactionResponse)) {
      setTransactions({ isTransactionError: true });
      return;
    }

    return transactionResponse.data;
  };

  const fetchSelectedTransactionsCount = async (selectedItem: string) => {
    const countResponse = await fetchCount(
      format(currentDate, 'yyyy-MM-dd'),
      format(currentDate, 'yyyy-MM-dd'),
      TransactionFilterType.ReceiptSubType,
      undefined,
      selectedItem,
    );

    if (isFailureResponse(countResponse)) {
      setTransactions({ isReceiptSubTypeError: true });
      return;
    }

    return countResponse.data;
  };

  const setFilteredTransactions = async (selectedItem: string) => {
    const response = await fetchSelectedTransactions(selectedItem);

    if (!response || !response.data) return;

    setTransactions({
      table: {
        ...table,
        rows: [...response.data],
      },
      isFetchingTransaction: false,
    });

    return response;
  };

  const setFilteredCounts = async (selectedItem: string) => {
    let totalRows = 0;

    const response = await fetchSelectedTransactionsCount(selectedItem);

    if (!response || !response.filterData) return;

    if (totalRows < +response.totalCount) totalRows = +response.totalCount;

    setTransactions({
      quickFilterData: { ...response.filterData },
      totalCount: +totalRows,
      isFetchingCount: false,
    });

    return response;
  };

  const handleRowClick = async (item: string) => {
    if (!item) return;
    navigate(`/transaction`, { replace: true });

    if (!isTransactionsConnectedToWidgetData) {
      setTransactions({ isTransactionsConnectedToWidgetData: true });
    }

    setFilteredTransactions(item);
    setFilteredCounts(item);
    setTransactions({ selectedFilters: [item] });
  };

  const totalSaleCount = category.sale.reduce((acc, { count }) => acc + count, 0);
  const totalReturnCount = category.returns.reduce((acc, { count }) => acc + count, 0);

  const getContent = () => {
    const defaultView = (
      <StyledWrapper wrap="nowrap" padding={[3, 5]} data-testid="registered-receipts-wrapper" height="100%">
        <StyledSection
          padding={[2]}
          margin={[0, 0, 2, 0]}
          direction="horizontal"
          position="center"
          space="between"
        >
          <Typography type="body2">
            {t(registeredReceipts.header.key, registeredReceipts.header.defaultValue)}
          </Typography>
          <Typography data-testid="total-count" type="button">
            {totalSaleCount + totalReturnCount}
          </Typography>
        </StyledSection>

        {category.sale.length !== 0 && (
          <StyledSection
            wrap="nowrap"
            padding={[2]}
            direction="horizontal"
            position="top"
            margin={[0, 0, 2, 0]}
            data-testid="sale-wrapper"
          >
            <StyledIcon icon={Icons.BAG} size="small" margin={[0, 2, 0, 0]} />
            <Container width="100%">
              <StyledSubHeader direction="horizontal" space="between" padding={[0, 0, 2, 0]} margin={[0, 0, 2, 0]}>
                <Typography color="textGray" type="button">
                  {t(registeredReceipts.saleHeader.key, registeredReceipts.saleHeader.defaultValue)}
                </Typography>
                <Typography type="button">{totalSaleCount}</Typography>
              </StyledSubHeader>
              {category.sale.map(({ item, count }, index) => (
                <StyledContainer
                  padding={[1, 2]}
                  direction="horizontal"
                  position="center"
                  space="between"
                  key={`sale - ${index}`}
                  data-testid={`sale - ${index}`}
                >
                  {hasTransactionSearchPageReadPermission ? (
                    <StyledIconWithText
                      data-testid="transaction-item"
                      label={item}
                      iconOptions={{
                        icon: Icons.ARROW,
                        size: 'small',
                      }}
                      canClick={hasTransactionSearchPageReadPermission}
                      onClick={() => handleRowClick(item)}
                      labelPosition="left"
                    />
                  ) : (
                    <Typography data-testid="transaction-item">{item}</Typography>
                  )}

                  <Typography>{count}</Typography>
                </StyledContainer>
              ))}
            </Container>
          </StyledSection>
        )}

        {category.returns.length !== 0 && (
          <StyledSection
            wrap="nowrap"
            padding={[2]}
            direction="horizontal"
            position="top"
            data-testid="returns-wrapper"
          >
            <StyledIcon icon={Icons.RETURNS} size="small" margin={[0, 2, 0, 0]} />
            <Container width="100%">
              <StyledSubHeader direction="horizontal" space="between" padding={[0, 0, 2, 0]} margin={[0, 0, 2, 0]}>
                <Typography color="textGray" type="button">
                  {t(registeredReceipts.returnHeader.key, registeredReceipts.returnHeader.defaultValue)}
                </Typography>
                <Typography type="button">{totalReturnCount}</Typography>
              </StyledSubHeader>
              {category.returns.map(({ item, count }, index) => (
                <StyledContainer
                  padding={[1, 2]}
                  direction="horizontal"
                  position="center"
                  space="between"
                  key={`return - ${index}`}
                >
                  {hasTransactionSearchPageReadPermission ? (
                    <StyledIconWithText
                      data-testid="transaction-item"
                      label={item}
                      iconOptions={{
                        icon: Icons.ARROW,
                        size: 'small',
                      }}
                      canClick={hasTransactionSearchPageReadPermission}
                      onClick={() => handleRowClick(item)}
                      labelPosition="left"
                    />
                  ) : (
                    <Typography data-testid="transaction-item">{item}</Typography>
                  )}
                  <Typography>{count}</Typography>
                </StyledContainer>
              ))}
            </Container>
          </StyledSection>
        )}
      </StyledWrapper>
    );

    if (interval === StorePerformanceInterval.WEEKLY) return <WidgetCommonState isNoData isNoWeeklyData />;
    if (receiptDataList.isError)
      return (
        <WidgetCommonState
          isError
          errorText={t(registeredReceipts.error.key, registeredReceipts.error.defaultValue)}
        />
      );
    if (receiptDataList.isLoading) return <WidgetCommonState isLoading />;
    if (!receiptDataList.data.length && !receiptDataList.isLoading) return <WidgetCommonState isNoData />;

    return defaultView;
  };

  return (
    <Card
      headerIcon={Icons.TRANSACTIONS}
      headerText={t(registeredReceipts.title.key, registeredReceipts.title.defaultValue)}
      subHeaderText={t(registeredReceipts.subTitle.key, registeredReceipts.subTitle.defaultValue)}
      content={getContent()}
      toolTipText={t(registeredReceipts.toolTip.key, registeredReceipts.toolTip.defaultValue)}
    />
  );
};
