import { useMediaQuery } from '@mui/material';
import { Icons, Typography } from '../../../stories/atoms';
import { IconWithText } from '../../../stories/molecules';
import { muiTheme } from '../../../theme';
import { formatAmountWithSeparator } from '../../../utils';
import { columnWidths } from '../ProductCategory.styles';
import { ProgressLine } from '../ProgressLine';
import { getPercentDiff } from '../util';
import {
  AmountContainer,
  Container,
  MobileContainer,
  MobileContainerWrapper,
  MobileTitleContainer,
  MobileValueContainer,
  MobileValuesContainer,
  ProgressLineContainer,
  SobContainer,
  StyledArrowIconContainer,
  StyledDropdownIconContainer,
  TitleContainer,
} from './TableItem.styles';

type TableItemProps = {
  depth: number;
  isParent: boolean;
  isExpanded: boolean;
  title: string;
  salesNet: number;
  lastWeek: number;
  lastYear: number;
  sob: number;
  expandButtonHandler?: () => void;
  garmentGroupButtonHandler?: () => void;
};

export const TableItem = ({
  depth,
  isParent,
  isExpanded,
  title,
  salesNet,
  lastWeek,
  lastYear,
  sob,
  expandButtonHandler,
  garmentGroupButtonHandler,
}: TableItemProps) => {
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

  // TODO: enable when BTCOBUI-2370 is fixed
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const lastWeekDiff = salesNet - lastWeek;
  const lastWeekPercentDiff = getPercentDiff(salesNet, lastWeek);

  const lastYearDiff = salesNet - lastYear;
  const lastYearPercentDiff = getPercentDiff(salesNet, lastYear);
  /* eslint-enable @typescript-eslint/no-unused-vars */

  return (
    <>
      {!isMobile && (
        <Container style={{ marginLeft: depth * 30 }} data-testid={`${depth}-${title}`}>
          <TitleContainer style={{ flex: 1 }}>
            {isParent && (
              <StyledDropdownIconContainer onClick={expandButtonHandler} aria-hidden="true">
                <IconWithText
                  data-testid="transaction-item"
                  iconOptions={{
                    icon: Icons.DROPDOWN,
                    size: 'small',
                    rotate: isExpanded ? undefined : 'right',
                  }}
                  labelPosition="left"
                />
              </StyledDropdownIconContainer>
            )}

            {isParent && depth === 0 && <Typography type="h4">{title}</Typography>}

            {isParent && depth !== 0 && <Typography type="button">{title}</Typography>}

            {!isParent && <Typography>{title}</Typography>}

            {isParent && (
              <StyledArrowIconContainer onClick={garmentGroupButtonHandler} aria-hidden="true">
                <IconWithText
                  data-testid="transaction-item"
                  iconOptions={{
                    icon: Icons.ARROW,
                    size: 'small',
                    rotate: 'custom',
                    rotateValue: -30,
                  }}
                  labelPosition="left"
                />
              </StyledArrowIconContainer>
            )}
          </TitleContainer>

          <AmountContainer style={{ width: `${columnWidths.salesNet}vw` }}>
            <Typography type={isParent ? 'button' : 'body2'}>{formatAmountWithSeparator(salesNet)}</Typography>
          </AmountContainer>

          {/* <AmountContainer style={{ width: `${columnWidths.lastWeek}vw` }}>
            <TrendContent
              textOptions={{ type: isParent ? 'button' : 'body2' }}
              value={lastWeek}
              diff={lastWeekDiff}
              percentageDiff={lastWeekPercentDiff}
            />
          </AmountContainer>

          <AmountContainer style={{ width: `${columnWidths.lastYear}vw` }}>
            <TrendContent
              textOptions={{ type: isParent ? 'button' : 'body2' }}
              value={lastYear}
              diff={lastYearDiff}
              percentageDiff={lastYearPercentDiff}
            />
          </AmountContainer> */}

          <SobContainer style={{ width: `${columnWidths.sob}vw` }}>
            <Typography type={isParent ? 'button' : 'body2'}>{sob.toFixed(2)}%</Typography>
            <ProgressLineContainer>
              <ProgressLine valuePercent={sob} />
            </ProgressLineContainer>
          </SobContainer>
        </Container>
      )}

      {isMobile && (
        <MobileContainerWrapper style={{}}>
          {Array.from(Array(depth)).map(() => {
            return (
              <div
                key={depth}
                id="ll"
                style={{ alignSelf: 'stretch', width: '1px', background: '#cfcfcf', marginRight: 20 }}
              ></div>
            );
          })}

          <MobileContainer aria-hidden="true" style={{ marginLeft: 0 * 15 }}>
            <MobileTitleContainer>
              {isParent && (
                <StyledDropdownIconContainer onClick={expandButtonHandler} aria-hidden="true">
                  <IconWithText
                    data-testid="transaction-item"
                    iconOptions={{
                      icon: Icons.DROPDOWN,
                      size: 'small',
                      rotate: isExpanded ? undefined : 'right',
                    }}
                    labelPosition="left"
                  />
                </StyledDropdownIconContainer>
              )}

              {isParent && depth === 0 && <Typography type="h4">{title}</Typography>}

              {isParent && depth !== 0 && <Typography type="button">{title}</Typography>}

              {!isParent && <Typography>{title}</Typography>}

              {isParent && (
                <StyledArrowIconContainer onClick={garmentGroupButtonHandler} aria-hidden="true">
                  <IconWithText
                    data-testid="transaction-item"
                    iconOptions={{
                      icon: Icons.ARROW,
                      size: 'small',
                      rotate: 'custom',
                      rotateValue: -30,
                    }}
                    labelPosition="left"
                  />
                </StyledArrowIconContainer>
              )}
            </MobileTitleContainer>

            <MobileValuesContainer>
              <MobileValueContainer>
                <Typography>{formatAmountWithSeparator(salesNet)}</Typography>
                <Typography>Sales Net</Typography>
              </MobileValueContainer>

              {/* <MobileValueContainer>
                <TrendContent value={lastWeek} diff={lastWeekDiff} percentageDiff={lastWeekPercentDiff} />
                <Typography>LW</Typography>
              </MobileValueContainer>

              <MobileValueContainer>
                <TrendContent value={lastYear} diff={lastYearDiff} percentageDiff={lastYearPercentDiff} />
                <Typography>LY</Typography>
              </MobileValueContainer> */}

              <MobileValueContainer>
                <Typography>{sob.toFixed(2)}%</Typography>
                <Typography>SoB</Typography>
              </MobileValueContainer>
            </MobileValuesContainer>
          </MobileContainer>
        </MobileContainerWrapper>
      )}
    </>
  );
};
