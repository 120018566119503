import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { xml2json } from 'xml-js';
import { intervalToDuration } from 'date-fns';
import { useUser } from '../../../hooks/useController';
import { axiosRequest, isFailureResponse, getUniqueId } from '../../../utils';
import { Delay, baseUrlCDN, avatarsEndpoint, avatarIsUniqueEndpoint } from '../../../constants';
import { banners } from '../../../translations';
import { useAuthAxiosRequest, useProfile } from '../../../hooks';
import { Page } from '../../../stories/organisms/page/Page';
import { TypographyProps } from '../../../stories/atoms';
import { ActionButton, Banner, BannerItemsType } from '../../../stories/molecules/banner';
import { EditAvatar } from './edit-avatar/EditAvatar';
import { StyledButton, StyledContainer, StyledPageHeader } from './AvatarSettings.styles';
import { AddAvatarLoader, EditAvatarLoader } from './avatar-settings-loader/AvatarSettingsLoader';
import { AddAvatar } from './add-avatar/AddAvatar';
import { AvatarSelect } from './avatar-select/AvatarSelect';
import { AvatarSettingsView } from './avatarSettings.const';

export type AvatarListType = {
  category: string;
  urls: string[];
};

export type AvatarIsUniqueResponse = {
  isUnique: boolean;
};

type Element = {
  type: string;
  name: string;
  text: string;
  elements: Element[];
};

type AvatarResponse = {
  elements: {
    attributes: { ServiceEndpoint: string; ContainerName: string };
    elements: Element[];
  }[];
};

type BannerType = 'success' | 'error' | 'avatarNotUnique' | 'avatarsFetchFailed';

const randomCategory = 'randomize';

export const AvatarSettings = observer(() => {
  const {
    get: {
      profile: { firstName, lastName, upn, avatar, id },
      userPermissions,
    },
    set,
  } = useUser();

  const { authAxiosRequest } = useAuthAxiosRequest();

  const { t } = useTranslation();
  const { updateProfile } = useProfile();

  const [view, setView] = useState<AvatarSettingsView>();
  const [isLoading, setIsLoading] = useState(false);
  const [avatarList, setAvatarList] = useState<AvatarListType[]>([]);
  const [previousView, setPreviousView] = useState<AvatarSettingsView>();
  const [selectedAvatarUrl, setSelectedAvatarUrl] = useState<string>();
  const [isProfileUpdated, setIsProfileUpdated] = useState(false);
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [shouldFetchAvatars, setShouldFetchAvatars] = useState(false);

  const [bannerItems, setBannerItems] = useState<BannerItemsType[]>([]);

  useEffect(() => {
    if (!id) return;

    setView(avatar?.url ? AvatarSettingsView.Edit : AvatarSettingsView.Add);
    if (avatar?.url) setSelectedAvatarUrl(avatar?.url);
  }, [id]);

  useEffect(() => {
    if (shouldFetchAvatars) {
      fetchAllAvatars();
      setShouldFetchAvatars(false);
    }

    if (!avatar?.url) {
      setShouldFetchAvatars(true);
    }
  }, [shouldFetchAvatars]);

  useEffect(() => {
    let handler: NodeJS.Timeout;
    if (isProfileUpdated && view === AvatarSettingsView.Add) {
      handler = setTimeout(() => {
        setView(AvatarSettingsView.Edit);
      }, Delay.Medium);
    }

    return () => clearTimeout(handler);
  }, [isProfileUpdated]);

  const checkIsAvatarUnique = async (avatarUrl?: string) => {
    const avatarIsUniqueResponse = await authAxiosRequest<AvatarIsUniqueResponse>(avatarIsUniqueEndpoint, {
      params: {
        avatarUrl,
        firstName,
        lastName,
        upn,
      },
    });

    if (isFailureResponse(avatarIsUniqueResponse)) {
      return false;
    }

    const {
      data: { isUnique },
    } = avatarIsUniqueResponse;

    return isUnique;
  };

  const fetchAllAvatars = async () => {
    setIsLoading(true);
    const avatarsXMLResponse = await axiosRequest<string>(`${avatarsEndpoint}${process.env.REACT_APP_CDN_TOKEN}`, {
      baseURL: baseUrlCDN,
      params: {
        restype: 'container',
        comp: 'list',
      },
    });

    if (isFailureResponse(avatarsXMLResponse)) {
      setIsLoading(false);
      return;
    }

    const parsedResponse = getAvatarsFromXMLString(avatarsXMLResponse.data);
    const updatedAvatars = [
      {
        category: randomCategory,
        urls: parsedResponse
          .map(({ urls }) => urls)
          .flat()
          .sort(() => Math.random() - 0.5),
      },
      ...parsedResponse.map((a) => ({ ...a, urls: a.urls.sort(() => Math.random() - 0.5) })),
    ];

    setAvatarList(updatedAvatars);

    if (!avatar?.url) {
      const categoryIndex = Math.floor(Math.random() * parsedResponse.length);
      const urlIndex = Math.floor(Math.random() * parsedResponse[categoryIndex].urls.length);
      const randomUrl = parsedResponse[categoryIndex].urls[urlIndex];
      setSelectedAvatarUrl(randomUrl);
    }
    setIsLoading(false);
  };

  const handleUpdateProfile = async (url: string) => {
    const updateProfileResponse = await updateProfile(upn, [{ op: 'add', value: { url }, path: '/avatar' }]);

    if (isFailureResponse(updateProfileResponse)) {
      return false;
    }

    const { data } = updateProfileResponse;
    set({
      profile: data,
    });

    return true;
  };

  const getAvatarsFromXMLString = (data: string) => {
    const avatars: AvatarResponse = JSON.parse(xml2json(data));

    const {
      elements: rootElements,
      attributes: { ContainerName },
    } = avatars.elements[0];
    const { elements } = rootElements[0];

    return elements.reduce((avatarMapping, element) => {
      const path = element.elements[0].elements[0].text;
      const category = path.split('/')[0];
      const url = `${baseUrlCDN}/${ContainerName}/${path}`;

      const index = avatarMapping.findIndex((a) => a.category === category);
      if (index > -1) {
        avatarMapping[index].urls.push(url);
      } else avatarMapping.push({ category, urls: [url] });

      return avatarMapping;
    }, [] as AvatarListType[]);
  };

  const getExpiresIn = (maxExpiryDays = 90) => {
    const { days } = intervalToDuration({
      start: new Date(avatar?.lastUpdated as Date),
      end: new Date(),
    });
    return maxExpiryDays - (days || 0);
  };

  const getFilteredUrls = () => {
    return avatarList
      .filter(({ category }) => category === selectedCategory)
      .map(({ urls: avatarUrls, category }) =>
        category === randomCategory || selectedAvatarUrl?.includes(`${selectedCategory}/`)
          ? [selectedAvatarUrl, ...avatarUrls.filter((url) => url !== selectedAvatarUrl)]
          : avatarUrls,
      )
      .flat() as string[];
  };

  const handleSubmit = async (url?: string) => {
    if (!url) return;

    const isAvatarUnique = await checkIsAvatarUnique(url);

    if (!isAvatarUnique) {
      showBanners('avatarNotUnique');
      return;
    }

    const isSaved = await handleUpdateProfile(url);
    if (!isSaved) {
      showBanners('error');
      return;
    }

    setIsProfileUpdated(true);
    showBanners('success');
  };

  const showBanners = (bannerType: BannerType) => {
    const successLabel =
      view === AvatarSettingsView.Add
        ? t(banners.avatarCreated.key, banners.avatarCreated.defaultValue)
        : t(banners.avatarUpdated.key, banners.avatarUpdated.defaultValue);

    const errorLabel =
      view === AvatarSettingsView.Add
        ? t(banners.avatarCreationFailed.key, banners.avatarCreationFailed.defaultValue)
        : t(banners.avatarUpdateFailed.key, banners.avatarUpdateFailed.defaultValue);

    setBannerItems((items) => [
      ...items,
      {
        id: getUniqueId(),
        label:
          bannerType === 'avatarNotUnique'
            ? t(banners.avatarNotUnique.key, banners.avatarNotUnique.defaultValue)
            : bannerType === 'avatarsFetchFailed'
            ? t(banners.avatarsFetchFailed.key, banners.avatarsFetchFailed.defaultValue)
            : bannerType === 'error'
            ? errorLabel
            : successLabel,
        type: bannerType === 'success' ? 'success' : 'error',
        actionButton: bannerType === 'error' || bannerType === 'avatarNotUnique' ? ActionButton.Close : undefined,
      },
    ]);
  };

  const handleAvatarAssigned = async (url?: string) => {
    setSelectedAvatarUrl(url);
    setIsSaveBtnDisabled(true);

    await handleSubmit(url);

    setIsSaveBtnDisabled(false);
    setView(previousView);
  };

  const handleAvatarChange = () => {
    setShouldFetchAvatars(true);
    setSelectedCategory(randomCategory);
    setPreviousView(view);
    setView(AvatarSettingsView.SelectAvatar);
  };

  const getPageHeader = () => {
    return view === AvatarSettingsView.SelectAvatar ? (
      <StyledPageHeader>
        {avatarList.map(({ category }) => {
          const selectedProps: Partial<TypographyProps> =
            category === selectedCategory ? { color: 'red', borderBottomColor: 'red' } : {};

          return (
            <StyledButton
              label={t(`avatarSettings.category.${category}`, category)}
              key={category}
              variant="text"
              buttonContentProps={{ textOptions: selectedProps }}
              onClick={() => setSelectedCategory(category)}
            />
          );
        })}
      </StyledPageHeader>
    ) : undefined;
  };

  const canEditAvatar = userPermissions.personalSettings.canUpdate;

  return (
    <Page
      header={getPageHeader()}
      body={
        <StyledContainer>
          {isLoading && view === AvatarSettingsView.Add && <AddAvatarLoader />}
          {isLoading && view === AvatarSettingsView.Edit && <EditAvatarLoader />}
          <Banner items={bannerItems} onChange={(items) => setBannerItems(items)} />
          {!isLoading && view === AvatarSettingsView.Add && (
            <AddAvatar
              avatarUrl={selectedAvatarUrl}
              isProfileUpdated={isProfileUpdated}
              onClick={handleAvatarChange}
              onSubmit={() => handleSubmit(selectedAvatarUrl)}
            />
          )}
          {!isLoading && view === AvatarSettingsView.Edit && avatar?.url && (
            <EditAvatar
              avatarUrl={avatar.url}
              firstName={firstName}
              lastName={lastName}
              upn={upn}
              expireIn={getExpiresIn()}
              onUpdateAvatar={handleAvatarChange}
              canEditAvatar={canEditAvatar}
            />
          )}
          {!isLoading && view === AvatarSettingsView.SelectAvatar && (
            <AvatarSelect
              avatarList={avatarList}
              initialAvatarUrl={selectedAvatarUrl}
              avatarUrlList={getFilteredUrls()}
              selectedCategory={selectedCategory}
              isSaveBtnDisabled={isSaveBtnDisabled}
              onClick={handleAvatarAssigned}
              onCategoryClick={(category) => setSelectedCategory(category)}
              onCancel={() => setView(previousView)}
              onRetry={() => {
                fetchAllAvatars();
                showBanners('avatarsFetchFailed');
              }}
            />
          )}
        </StyledContainer>
      }
    />
  );
});
