import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SortDirection } from '../../global-state/types';
import { Icons, Typography } from '../../stories/atoms';
import { IconWithText } from '../../stories/molecules';
import { productCategoryTranslations } from '../../translations/productCategory';
import { columnWidths } from './ProductCategory.styles';

const Container = styled.div`
  height: ${({ theme: { spacing } }) => spacing(12)};
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundTableHeaderDark : color.backgroundTableHeader};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top-left-radius: ${({ theme: { spacing } }) => spacing(2)};
  border-top-right-radius: ${({ theme: { spacing } }) => spacing(2)};
  padding: ${({ theme: { spacing } }) => spacing(0, 5, 0, 5)};
  margin-top: ${({ theme: { spacing } }) => spacing(3)};
  margin-bottom: ${({ theme: { spacing } }) => spacing(2)};
`;

const StyledGroupHeaderCategoryItem = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const StyledHeaderItem = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: ${({ theme: { spacing } }) => spacing(8)};
  min-width: ${columnWidths.minPxWidth}px;
`;

const StyledSobHeaderItem = styled(StyledHeaderItem)`
  justify-content: flex-start;
  padding-right: ${({ theme: { spacing } }) => spacing(0)};
  min-width: ${columnWidths.minPxWidth}px;
`;

type Props = {
  sortType: SortDirection | null;
  sortButtonHandler: () => void;
};

export const TableHeader = ({ sortType, sortButtonHandler }: Props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <StyledGroupHeaderCategoryItem style={{ flex: 1 }}>
        <Typography type="button">
          {t(
            productCategoryTranslations.tableHeader.category.key,
            productCategoryTranslations.tableHeader.category.defaultValue,
          )}
        </Typography>
      </StyledGroupHeaderCategoryItem>

      <StyledHeaderItem style={{ width: `${columnWidths.salesNet}vw` }}>
        <IconWithText
          label={t(
            productCategoryTranslations.tableHeader.salesNet.key,
            productCategoryTranslations.tableHeader.salesNet.defaultValue,
          )}
          iconOptions={
            !sortType
              ? undefined
              : {
                  icon: sortType === SortDirection.Descending ? Icons.DROPDOWN : Icons.DROPDOWN,
                  size: 'small',
                  rotate: sortType === SortDirection.Ascending ? 'up' : undefined,
                }
          }
          textOptions={{
            type: 'button',
          }}
          labelPosition="left"
          onClick={sortButtonHandler}
          padding={[0, 0, 0, 2]}
        />
      </StyledHeaderItem>

      {/* <StyledHeaderItem style={{ width: `${columnWidths.lastWeek}vw` }}>
        <Typography type="button">
          {t(
            productCategoryTranslations.tableHeader.lastWeek.key,
            productCategoryTranslations.tableHeader.lastWeek.defaultValue,
          )}
        </Typography>
      </StyledHeaderItem>

      <StyledHeaderItem style={{ width: `${columnWidths.lastYear}vw` }}>
        <Typography type="button">
          {t(
            productCategoryTranslations.tableHeader.lastYear.key,
            productCategoryTranslations.tableHeader.lastYear.defaultValue,
          )}
        </Typography>
      </StyledHeaderItem> */}

      <StyledSobHeaderItem style={{ width: `${columnWidths.sob}vw` }}>
        <Typography type="button">
          {t(
            productCategoryTranslations.tableHeader.sob.key,
            productCategoryTranslations.tableHeader.sob.defaultValue,
          )}
        </Typography>
      </StyledSobHeaderItem>
    </Container>
  );
};
