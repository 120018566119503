import { authorizationEndpoint, permissionsEndpoint } from '../constants';
import { useAuthAxiosRequest } from './useAuthAxiosRequest';

export const permissionTypes = [
  'transactionSearchPage',
  'employeesAndRolesPage',
  'avatarManagementPage',
  'kpiTargetsPage',
  'chatBoxPage',
  'shareOfReceiptWidget',
  'topSellingGarmentsWidget',
  'salesPerformanceWidget',
  'conversionRateWidget',
  'registeredReceiptsWidget',
  'tenderOverviewWidget',
  'assetManagementWidget',
  'avgPiecesPerReceiptWidget',
  'avgPurchasePerReceiptWidget',
  'effectiveHoursWidget',
  'takt1Widget',
  'sptWidget',
  'personalSettings',
  'kpiTargets',
  'areaCaps',
  'lockTargets',
  'highlightWidget',
  'financialPlanned',
  'financialBudget',
  'newMembersWidget',
  'appCustomers',
  'employeePermissions',
] as const;

export type PermissionType = (typeof permissionTypes)[number];

export type PermissionItemsMap = {
  [key in PermissionType]: string;
};

export const useUserPermissions = () => {
  const { authAxiosRequest } = useAuthAxiosRequest();

  const fetchUserPermissions = async (upn: string, storeId: string) => {
    const roleMatrixResponse = await authAxiosRequest<PermissionItemsMap>(
      `${authorizationEndpoint}${permissionsEndpoint}/${upn}`,
      {
        params: { storeId },
      },
    );

    return roleMatrixResponse;
  };

  return { fetchUserPermissions };
};
