import { rest } from 'msw';
import { baseUrl, soldProductsEndpoint } from '../../constants';

import { SoldProductItem } from '../../hooks';

import { getMockProductIndex } from '../mocks';

export const getProducts = (count: number): SoldProductItem[] => {
  const garments: SoldProductItem[] = [];

  for (let i = 0; i < count; i++) {
    const garment: SoldProductItem = {
      productName: `Garment ${i + 1}`,
      productId: `${Math.floor(Math.random() * 1000000)}`,
      price:
        Math.random() < 0.5
          ? parseFloat((Math.random() * 1000).toFixed(2))
          : parseFloat((Math.random() * 12450).toFixed(2)),
      soldPieces: Math.floor(Math.random() * 10),
      departmentId: `${Math.floor(Math.random() * 1000000)}`,
      departmentName: Math.random() < 0.33 ? 'Dividend' : Math.random() < 0.66 ? 'H&M Kids' : 'H&M Menswear',
      season: Math.random() < 0.33 ? 'Summer' : 'Winter',
      salesNet: 0,
      mostSoldArticle: '',
    };

    garments.push(garment);
  }

  return garments;
};

export const fetchSoldProductsPage = (addDelay = false) =>
  rest.get(`${baseUrl}${soldProductsEndpoint}/:storeId/soldProducts`, (_, res, ctx) => {
    const mockData = getProducts(10);

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });

export const fetchProductIndex = (addDelay = false) =>
  rest.get(`${baseUrl}${soldProductsEndpoint}/:storeId/productIndex`, (_, res, ctx) => {
    const mockData = getMockProductIndex();

    return res(
      ctx.status(200),
      ctx.json(mockData),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });
