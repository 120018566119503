import { Client, Profile, RoleMatrix } from '../global-state/types';
import { commonTranslations } from '../translations';
import { isSupportedSuamRole, RoleMapping } from './isSupportedSuamRole';

export const permissionTypes = ['C', 'R', 'U', 'D'] as const;
export type PermissionType = (typeof permissionTypes)[number];

export const permissionTypeMapping: { [key in PermissionType]: { key: string; defaultValue: string } } = {
  C: commonTranslations.create,
  R: commonTranslations.read,
  U: commonTranslations.update,
  D: commonTranslations.delete,
};

export const getSuamRoles = (userProfile: Profile, currentStoreId: string) => {
  const suamRoles =
    userProfile.assignments
      ?.filter(({ locationCode }) => locationCode === currentStoreId)
      .map(({ suamRoleCode }) => suamRoleCode) || [];
  return suamRoles;
};

export const isEditRoleCheckBoxDisabled = (
  profile: Profile,
  currentStoreId: string,
  employeeRoles: string[],
  roleMatrix: RoleMatrix,
) => {
  const profileSuamRoles = getSuamRoles(profile, currentStoreId);
  const hasEditPermission = !profileSuamRoles.some((suamRoleCode) => {
    return employeeRoles.some((role) => {
      const { roleManagement: roleManagementMapping } = roleMatrix.box;
      const userPermissions =
        roleManagementMapping[isSupportedSuamRole(suamRoleCode) ? RoleMapping[suamRoleCode] : 'other'];
      if (!userPermissions) return false;
      const employeeRole = userPermissions[isSupportedSuamRole(role) ? RoleMapping[role] : 'other'];
      return employeeRole?.includes('U');
    });
  });
  return hasEditPermission;
};

export const isEditPermissionCheckBoxDisabled = (
  employeeRoles: string[],
  roleMatrix: RoleMatrix,
  profile: Profile,
  currentStoreId: string,
  client: Client,
  feature: string,
  type: string,
) => {
  const isDefaultPermission = employeeRoles.some((role) => {
    const employeeRole = isSupportedSuamRole(role) ? RoleMapping[role] : 'other';
    return roleMatrix[client].featurePermissions[feature].permissions[employeeRole]?.includes(type);
  });
  const profileSuamRoles = getSuamRoles(profile, currentStoreId);
  const hasEditPermission = !profileSuamRoles.some((suamRoleCode) => {
    return employeeRoles.every((role) => {
      const { roleManagement: roleManagementMapping } = roleMatrix.box;
      const userPermissions =
        roleManagementMapping[isSupportedSuamRole(suamRoleCode) ? RoleMapping[suamRoleCode] : 'other'];
      if (!userPermissions) return false;
      const employeeRole = userPermissions[isSupportedSuamRole(role) ? RoleMapping[role] : 'other'];
      return employeeRole?.includes('U');
    });
  });
  return isDefaultPermission || hasEditPermission;
};
