export const mockPermissions = {
  transactionSearchPage: 'R',
  employeesAndRolesPage: 'R',
  avatarManagementPage: 'R',
  kpiTargetsPage: 'R',
  chatBoxPage: 'R',
  newMembersWidget: 'R',
  appCustomers: 'R',
  shareOfReceiptWidget: 'R',
  topSellingGarmentsWidget: 'R',
  salesPerformanceWidget: 'R',
  conversionRateWidget: 'R',
  registeredReceiptsWidget: 'R',
  tenderOverviewWidget: 'R',
  assetManagementWidget: 'R',
  avgPiecesPerReceiptWidget: 'R',
  avgPurchasePerReceiptWidget: 'R',
  effectiveHoursWidget: 'R',
  takt1Widget: 'R',
  sptWidget: 'R',
  personalSettings: 'CRUD',
  kpiTargets: 'CRUD',
  areaCaps: 'CRUD',
  lockTargets: 'CRUD',
  highlightWidget: 'CRUD',
  financialPlanned: 'R',
  financialBudget: 'R',
  employeePermissions: 'RU',
};
