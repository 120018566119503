import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../theme';
import { Typography, Icon, Icons } from '../../../stories/atoms';
import { useThemeContext } from '../../../global-state/themeContext';
import { homeTranslations } from '../../../translations/home';
import { Spacing, formatAmountWithCurrency } from '../../../utils';
import { useStoreInformation } from '../../../hooks';
import { Forecast } from '../../../constants';
import { StyledContainer } from './ProgressIndicator.styles';

type Statuses = 'SAD' | 'CONFUSED' | 'HAPPY';

type StatusProps = {
  [key in Statuses]: {
    backgroundColor: Colors;
    icon: Icons;
    iconLabel: string;
    borderColor: Colors;
  };
};

const STATUS: StatusProps = {
  SAD: {
    backgroundColor: 'backgroundMenuActive',
    icon: Icons.SAD,
    iconLabel: 'sad',
    borderColor: 'error',
  },
  CONFUSED: {
    backgroundColor: 'backgroundSelected',
    icon: Icons.CONFUSED,
    iconLabel: 'confused',
    borderColor: 'warningDark',
  },
  HAPPY: {
    backgroundColor: 'successLight',
    icon: Icons.HAPPY,
    iconLabel: 'happy',
    borderColor: 'successLight',
  },
};

export type ProgressIndicatorProps = {
  valueType: Forecast;
  value: number;
  progressPercentage: number;
  valueFormatter?: (value: number) => string;
  color?: Colors;
  margin?: Spacing;
  padding?: Spacing;
};

export const ProgressIndicator: FC<ProgressIndicatorProps> = ({
  valueType,
  value,
  progressPercentage,
  valueFormatter,
  margin = [1],
  padding = [1],
  ...props
}) => {
  const { t, i18n } = useTranslation();
  const { mode } = useThemeContext();
  const { common } = homeTranslations;
  const { currencyCode } = useStoreInformation();
  let formattedValue = value.toString();

  if (valueType === Forecast.CURRENCY) {
    formattedValue = formatAmountWithCurrency(parseInt(value.toFixed(2)), i18n.language, currencyCode);
  } else if (valueType === Forecast.PERCENTAGE) {
    formattedValue = `${value.toFixed(2)}%`;
  } else {
    formattedValue = valueFormatter ? valueFormatter(value) : value.toFixed(2).toString();
  }

  const getStatusProperties = () => {
    if (progressPercentage < 90) return STATUS.SAD;
    else if (progressPercentage < 99) return STATUS.CONFUSED;
    else return STATUS.HAPPY;
  };

  const { backgroundColor, icon, iconLabel, borderColor } = getStatusProperties();

  return (
    <StyledContainer
      data-testid="progress-indicator"
      direction="horizontal"
      wrap="nowrap"
      padding={padding}
      margin={margin}
      space="between"
      backgroundColor={mode === 'light' ? backgroundColor : 'inherit'}
      borderColor={borderColor}
      {...props}
    >
      <Typography type="body3">{t(common.forecast.key, common.forecast.defaultValue)}</Typography>
      <Typography type="body3" color="textGray">
        {formattedValue}
      </Typography>
      <Icon data-testid={`smiley-icon-${iconLabel}`} icon={icon} size="small" margin={[0, 1]} />
    </StyledContainer>
  );
};
