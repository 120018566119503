type ValueOptions = {
  locale?: string;
  options?: Intl.NumberFormatOptions;
};

export const formatValue = (value: number, { locale = 'default', options }: ValueOptions = {}): string => {
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  }).format(value);
};

export const formatAmountWithSeparator = (value: number) => {
  return parseFloat(value.toFixed(2)).toLocaleString();
};

export const formatAmountWithCurrency = (value: number, locale: string, currencyCode: string) => {
  const notation = value >= 10000 ? 'compact' : 'standard';

  return `${formatValue(value, {
    locale,
    options: { style: 'decimal', notation, maximumFractionDigits: 2 },
  })} ${currencyCode}`;
};

export const generateTemporaryPasscode = (): string => {
  const newPasscode = Math.floor(Math.random() * 900000 + 100000).toString();
  const sequencialRegex = /012|123|234|345|456|567|678|789|890|098|987|876|765|654|543|432|321|210/;
  const duplicateRegex = /^(?=.{6}$)(([0-9])\2?(?!\2))+$/gm;
  if (newPasscode.match(sequencialRegex) !== null || newPasscode.match(duplicateRegex) === null) {
    return generateTemporaryPasscode();
  } else {
    return newPasscode;
  }
};

export const percentToHex = (colour: string, value: number) => {
  const hex = Math.round((value / 100) * 255).toString(16);
  const opacity = hex.length > 1 ? hex : 0 + hex;
  return colour + opacity;
};

export const convertToCamelCase = (input: string) =>
  input.replace(/^([A-Z])|[\s-_](\w)/g, (_, a, b) => {
    if (b) return b.toUpperCase();
    return a.toLowerCase();
  });

export const convertCamelCaseToTitleCase = (input: string) => {
  return input
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Add space when an uppercase letter is followed by another uppercase and then a lowercase letter
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between lowercase and uppercase letters (standard camelCase)
    .trim()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Uppercase the first character of each word
    .join(' ');
};
export const removeDuplicateCharacters = (input: string) => [...new Set(input)].join('');

export const convertToKebabCase = (input: string) => {
  return input.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
};
