import React from 'react';
import { TrendEnum } from '../../../constants/budgetPlanned';
import { HourlyTurnoverPerformanceItem, useUser } from '../../../hooks';
import {
  BudgetPlannedContent,
  getBudgetAndPlannedPermissions,
} from '../budgetPlannedContent/BudgetPlannedContent';

type BudgetSliderProps = {
  budgetData: {
    clippedHourlyTurnoverItems: HourlyTurnoverPerformanceItem[];
    netSaleBudget: number;
    netSalePlanned: number;
    isNoHourlyTurnoverData: boolean;
  };
  children?: React.ReactNode;
};

const BudgetSlider: React.FC<BudgetSliderProps> = ({ budgetData }) => {
  const {
    get: { userPermissions },
  } = useUser();
  const { netSaleBudget, netSalePlanned, isNoHourlyTurnoverData } = budgetData;
  const { canViewBudget, canViewPlanned } = getBudgetAndPlannedPermissions(userPermissions);

  // Calculate totalNetSale
  const totalNetSale = budgetData.clippedHourlyTurnoverItems.reduce((acc, { amount }) => acc + amount, 0);

  const budgetCalc = Math.abs(100 - (totalNetSale / netSaleBudget) * 100).toFixed(1);
  const plannedCalc = Math.abs(100 - (totalNetSale / netSalePlanned) * 100).toFixed(1);
  const budgetChartData = {
    trend: parseInt(budgetCalc) < 100 ? TrendEnum.DOWN : TrendEnum.UP,
    data: netSaleBudget.toLocaleString(),
    percentage: parseFloat(budgetCalc),
  };
  const plannedChartData = {
    trend: parseInt(plannedCalc) < 100 ? TrendEnum.DOWN : TrendEnum.UP,
    data: netSalePlanned.toLocaleString(),
    percentage: parseFloat(plannedCalc),
  };

  return (
    <BudgetPlannedContent
      canViewBudget={canViewBudget}
      canViewPlanned={canViewPlanned}
      budgetNoData={isNoHourlyTurnoverData || netSaleBudget === 0}
      plannedNoData={isNoHourlyTurnoverData || netSalePlanned === 0}
      budget={budgetChartData}
      planned={plannedChartData}
    />
  );
};

export default BudgetSlider;
