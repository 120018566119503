import { rest } from 'msw';

export const optimizelyRequest = rest.get('https://cdn.optimizely.com/datafiles/:file', (_req, res, ctx) => {
  return res(
    ctx.status(200),
    ctx.json({
      groups: [],
      environmentKey: 'development',
      rollouts: [
        {
          experiments: [
            {
              status: 'Running',
              audienceConditions: [],
              audienceIds: [],
              variations: [
                {
                  variables: [],
                  id: '64454',
                  key: 'on',
                  featureEnabled: true,
                },
              ],
              forcedVariations: {},
              key: 'default-rollout-23076-20570596338',
              layerId: 'rollout-23076-20570596338',
              trafficAllocation: [
                {
                  entityId: '64454',
                  endOfRange: 10000,
                },
              ],
              id: 'default-rollout-23076-20570596338',
            },
          ],
          id: 'rollout-23076-20570596338',
        },
        {
          experiments: [
            {
              status: 'Running',
              audienceConditions: [],
              audienceIds: [],
              variations: [
                {
                  variables: [],
                  id: '64455',
                  key: 'off',
                  featureEnabled: false,
                },
              ],
              forcedVariations: {},
              key: 'default-rollout-23077-20570596338',
              layerId: 'rollout-23077-20570596338',
              trafficAllocation: [
                {
                  entityId: '64455',
                  endOfRange: 10000,
                },
              ],
              id: 'default-rollout-23077-20570596338',
            },
          ],
          id: 'rollout-23077-20570596338',
        },
        {
          experiments: [
            {
              status: 'Running',
              audienceConditions: [],
              audienceIds: [],
              variations: [
                {
                  variables: [],
                  id: '50617',
                  key: 'off',
                  featureEnabled: false,
                },
              ],
              forcedVariations: {},
              key: 'default-rollout-17100-20570596338',
              layerId: 'rollout-17100-20570596338',
              trafficAllocation: [
                {
                  entityId: '50617',
                  endOfRange: 10000,
                },
              ],
              id: 'default-rollout-17100-20570596338',
            },
          ],
          id: 'rollout-17100-20570596338',
        },
        {
          experiments: [
            {
              status: 'Running',
              audienceConditions: [],
              audienceIds: [],
              variations: [
                {
                  variables: [
                    {
                      id: '3054',
                      value: '',
                    },
                  ],
                  id: '13278',
                  key: 'on',
                  featureEnabled: true,
                },
              ],
              forcedVariations: {},
              key: 'transaction_search_targeted_delivery',
              layerId: '9300000014573',
              trafficAllocation: [
                {
                  entityId: '13278',
                  endOfRange: 10000,
                },
              ],
              id: '9300000014587',
            },
            {
              status: 'Running',
              audienceConditions: [],
              audienceIds: [],
              variations: [
                {
                  variables: [
                    {
                      id: '3054',
                      value: '',
                    },
                  ],
                  id: '13278',
                  key: 'on',
                  featureEnabled: true,
                },
              ],
              forcedVariations: {},
              key: 'default-rollout-4925-20570596338',
              layerId: 'rollout-4925-20570596338',
              trafficAllocation: [
                {
                  entityId: '13278',
                  endOfRange: 10000,
                },
              ],
              id: 'default-rollout-4925-20570596338',
            },
          ],
          id: 'rollout-4925-20570596338',
        },
      ],
      typedAudiences: [],
      projectId: '20607246881',
      variables: [],
      featureFlags: [
        {
          experimentIds: [],
          rolloutId: 'rollout-17100-20570596338',
          variables: [],
          id: '17100',
          key: 'role_management',
        },
        {
          experimentIds: [],
          rolloutId: 'rollout-44513-20570596338',
          variables: [],
          id: '44513',
          key: 'backoffice_ai',
        },
        {
          experimentIds: [],
          rolloutId: 'rollout-133936-20570596338',
          variables: [],
          id: '133936',
          key: 'top_selling_garments_page',
        },
        {
          experimentIds: [],
          rolloutId: 'rollout-255642-20570596338',
          variables: [],
          id: '255642',
          key: 'new_app_customers_widget',
        },
        {
          experimentIds: [],
          rolloutId: 'rollout-239786-20570596338',
          variables: [],
          id: '239786',
          key: 'product_category_page',
        },
        {
          experimentIds: [],
          rolloutId: 'rollout-245366-20570596338',
          variables: [],
          id: '245366',
          key: 'profit_and_loss_page',
        },
        {
          experimentIds: [],
          rolloutId: 'rollout-201371-20570596338',
          variables: [],
          id: '201371',
          key: 'alert_banners',
        },
        {
          experimentIds: [],
          rolloutId: 'rollout-255547-20607246881',
          variables: [],
          id: '255547',
          key: 'select_role',
        },
        {
          experimentIds: [],
          rolloutId: 'rollout-52131-20570596338',
          variables: [],
          id: '52131',
          key: 'backoffice',
        },
      ],
      experiments: [],
      version: '4',
      audiences: [
        {
          conditions:
            '["or", {"match: "exact", "name: "$opt_dummy_attribute", "type: "custom_attribute", "value: "$opt_dummy_value"}]',
          id: '$opt_dummy_audience',
          name: 'Optimizely-Generated Audience for Backwards Compatibility',
        },
      ],
      anonymizeIP: true,
      sdkKey: '24D9ugi9SdZKTg8bYE9To',
      attributes: [],
      botFiltering: false,
      accountId: '2125530039',
      events: [],
      revision: '75',
    }),
    ctx.set('Content-Type', 'application/json'),
  );
});
