import { rest } from 'msw';
import { authorizationEndpoint, baseUrl, permissionsEndpoint } from '../../constants';
import { mockPermissions } from '../mocks';

export const fetchPermissions = (addDelay?: boolean) =>
  rest.get(`${baseUrl}${authorizationEndpoint}${permissionsEndpoint}/:upn`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(mockPermissions),
      ctx.set('Content-Type', 'application/json'),
      ctx.delay(addDelay ? 500 : 0),
    );
  });
