import { Fade } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Delay } from '../../../../constants';
import { commonTranslations } from '../../../../translations';
import { Icon, Icons } from '../../../atoms';
import { Button } from '../../button/Button';
import { IconWithText } from '../../icon-with-text/IconWithText';
import { StyledContainer } from './BannerItem.styles';

export enum ActionButton {
  Close = 'close',
  TryAgain = 'tryAgain',
}

export type BannerItemType = 'success' | 'error' | 'warning' | 'info';

type BannerItemWithoutActionButton = {
  id: string;
  type?: BannerItemType;
  label: string;
  actionButton?: never;
  delay?: number;
  onClose: (id: string) => void;
};

type BannerItemPropsWithClose = Omit<BannerItemWithoutActionButton, 'actionButton'> & {
  actionButton: ActionButton.Close;
  onTryAgain?: never;
  hasNoMargin?: boolean;
};

type BannerItemPropsWithTryAgain = Omit<BannerItemWithoutActionButton, 'actionButton'> & {
  onTryAgain: () => void;
  actionButton: ActionButton.TryAgain;
};

type BannerItemProps = BannerItemWithoutActionButton | BannerItemPropsWithClose | BannerItemPropsWithTryAgain;

export type BannerItemsType =
  | Omit<BannerItemWithoutActionButton, 'onClose'>
  | Omit<BannerItemPropsWithClose, 'onClose'>
  | Omit<BannerItemPropsWithTryAgain, 'onClose'>;

export const BannerItem: FC<BannerItemProps> = (props) => {
  let icon: Icons;
  let actionButtonElement: JSX.Element;

  const { type, delay, id, hasNoMargin } = { type: 'success', delay: Delay.Large, hasNoMargin: false, ...props }; // defaultProps should be added here.

  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (!props.actionButton && delay) {
      const timeoutId = setTimeout(() => setIsVisible(false), delay);
      return () => clearTimeout(timeoutId);
    }
  }, []);

  switch (type) {
    case 'success':
      icon = Icons.CHECK_MARK_CIRCLE;
      break;
    case 'error':
      icon = Icons.WARNING;
      break;
    case 'info':
    case 'warning':
    default:
      icon = Icons.INFO;
      break;
  }

  switch (props.actionButton) {
    case ActionButton.Close:
      actionButtonElement = <Icon icon={Icons.CLOSE} size="small" onClick={() => setIsVisible(false)} />;
      break;
    case ActionButton.TryAgain:
      actionButtonElement = (
        <Button
          label={t(commonTranslations.tryAgain.key, commonTranslations.tryAgain.defaultValue)}
          onClick={() => {
            setIsVisible(false);
            props.onTryAgain();
          }}
          variant="text"
        />
      );
      break;
    default:
      actionButtonElement = <></>;
  }

  return (
    /**
     * @description "isVisible" is used here to start the fade-out animation, when it is false fade-out animation starts.
     * "appear" is false so we can always show the banner item without any fade-in animation.
     * "timeout" is used to determine the duration of fade-out animation here.
     * "onExited" will always call the onClose callback, This way we avoid timing issue and make sure animation is completed properly.
     **/
    <Fade in={isVisible} appear={false} timeout={delay} onExited={() => props.onClose(id)} unmountOnExit>
      {/* div is needed otherwise MUI animation throws an error. */}
      <div>
        <StyledContainer
          direction="horizontal"
          type={props.type as BannerItemType}
          space={'between'}
          margin={hasNoMargin ? [0] : [0, 0, 2]}
        >
          <IconWithText label={props.label} iconOptions={{ icon, size: 'small' }}></IconWithText>
          {actionButtonElement}
        </StyledContainer>
      </div>
    </Fade>
  );
};
