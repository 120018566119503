import { Typography } from '../../atoms';
import { StyledWrapper, TagButton } from './TagsList.styles';

export type TagsListProps<T> = {
  items: T[];
  activeItemIndex: number;
  itemTextRenderer: (item: T, index: number) => string;
  onTagSelect: (item: T, index: number) => void;
};

export function TagsList<T>(props: TagsListProps<T>) {
  const { items, activeItemIndex, itemTextRenderer, onTagSelect } = props;

  return (
    <StyledWrapper data-testid="tags-list-wrapper">
      {items.map((item, index) => {
        return (
          <TagButton isActive={index === activeItemIndex} onClick={() => onTagSelect(item, index)}>
            <Typography type="legal">{itemTextRenderer(item, index)}</Typography>
          </TagButton>
        );
      })}
    </StyledWrapper>
  );
}
