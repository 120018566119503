import styled from 'styled-components';
import { Property } from 'csstype';
import { Icon } from '../../atoms';
import { Container } from '../../atoms/container/Container';

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme: { spacing } }) => spacing(5)};
  justify-content: space-between;
`;

export const StyledHeaderText = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledHeaderSide = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledFooter = styled(Container)`
  border-top: ${({ theme: { color } }) => `1px solid ${color.borderGray}`};
`;

export const StyledContent = styled.div<{ overflow?: Property.Overflow }>`
  overflow-y: ${({ overflow }) => overflow && overflow}; //TODO: Verify if this works on test env
  height: 100%;
`;

export const StyledIcon = styled(Icon)`
  width: ${({ theme: { spacing } }) => spacing(8)};
  height: ${({ theme: { spacing } }) => spacing(8)};
  padding: ${({ theme: { spacing } }) => spacing(2)};
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundSelectedHover : color.borderGray};
  justify-content: center;
  cursor: pointer;
`;
