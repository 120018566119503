import styled from 'styled-components';
import { cardShadow } from '../../../theme';

export const SliderWrapper = styled.div<{ height: string }>`
  height: ${({ height }) => height};
  border: 1px solid
    ${({ theme: { color, palette } }) => (palette.mode === 'dark' ? color.backgroundDark : color.borderGray)};
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundPaperDark : color.white};
  display: flex;
  flex-direction: column;
  width: ${({
    theme: {
      sizing: { receiptWidth },
      breakpoints,
    },
  }) => (breakpoints.down('tabletLandscape') ? `auto` : receiptWidth)};
`;

export const StyledDetailsView = styled.div`
  height: 100%;
  padding: ${({ theme }) => theme.spacing(1)};
  text-align: center;
  overflow-y: auto;
`;

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme: { spacing } }) => spacing(5, 5, 0, 5)};
`;

export const StyledSubheaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledProductImage = styled.img`
  width: 80%;
  margin: ${({ theme: { spacing } }) => spacing(3, 0, 3, 0)};
  border-radius: ${({ theme: { spacing } }) => spacing(3)};
  overflow: hidden;
`;

export const StyledDetailsCard = styled.div`
  margin: ${({ theme: { spacing } }) => spacing(5, 5)};
  padding: ${({ theme: { spacing } }) => spacing(3)};
  border-radius: ${({ theme: { spacing } }) => spacing(3)};
  ${cardShadow()};
`;
export const StyledDetailsProperty = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme: { spacing } }) => spacing(3)};

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const StyledAboutInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme: { spacing } }) => spacing(5, 5, 0, 5)};
  align-items: flex-start;

  & > * {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;
