import styled from 'styled-components';
import { Divider } from '@mui/material';
import { Container } from '../../../stories/atoms';

export const StyledAveragePurchaseWrapper = styled(Container)`
  border-top: 1px solid ${({ theme: { color } }) => color.borderGray};
`;
export const StyledDivider = styled(Divider)`
  background-color: ${({ theme: { color, palette } }) => palette.mode === 'dark' && color.hoverGray};
`;
