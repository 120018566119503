import { createRoot } from 'react-dom/client';
import './index.css';
import { setupWorker } from 'msw';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorkerRegistration';
import { browserHandlers } from './msw';
import { AppWrapper } from './AppWrapper';

import './i18n';

// Setup requests interception using the given handlers.
const browser = setupWorker(...browserHandlers);

if (process.env.NODE_ENV === 'development') {
  browser.start();
}

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(<AppWrapper />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

const handleFirstTab = (event: KeyboardEvent) => {
  if (event.key === 'Tab') {
    // The "I am a keyboard user (Tab)" key. Adds focus outline to elements
    document.body.classList.add('user-is-tabbing');
    window.removeEventListener('keydown', handleFirstTab);
  }
};

window.addEventListener('keydown', handleFirstTab);
