import styled from 'styled-components';
import { SelectedFilters } from '../employees/role-management/Employees.styles';

const getGridColumns = (isSliderVisible: boolean) => {
  const sliderWidth = '350px';
  const tableWidth = 'calc(100% - 350px)';

  return isSliderVisible ? `${tableWidth} ${sliderWidth}` : '100%';
};

export const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: ${({ theme }) => theme.spacing(5)};
`;

export const HeaderFiltersWrapper = styled(FiltersWrapper)`
  height: 100%;
  padding: ${({ theme }) => theme.spacing(0, 8)};
`;

export const SubHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacing(2, 8)};
`;

export const TableWrapper = styled.div<{ isSliderVisible: boolean }>`
  display: grid;
  grid-template-columns: ${({ isSliderVisible }) => getGridColumns(isSliderVisible)};
  grid-template-rows: 100%;
  padding: ${({ theme }) => theme.spacing(0, 8)};
`;

export const SelectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SelectedFiltersArea = styled(SelectedFilters)`
  align-items: center;
  row-gap: ${({ theme }) => theme.spacing(2)};
`;
