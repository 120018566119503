import { FC, ComponentType, isValidElement } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { commonTranslations, errors } from '../../../translations';
import { Icons } from '../../atoms';
import { Animation, Animations } from '../../molecules';
import { BorderType } from '../header';
import { Container, NavBar, Body } from './Page.styles';

export type PageProps = {
  body: JSX.Element;
  header?: JSX.Element;
  headerBorderType?: BorderType;
  fallbackComponent?: ComponentType<FallbackProps>;
};

export const ErrorFallback = () => {
  const { t } = useTranslation();
  return (
    <Animation
      title={t(errors.sorry.key, errors.sorry.defaultValue)}
      subtitle={t(errors.nothingWrong.key, errors.nothingWrong.defaultValue)}
      animation={Animations.SAD}
      button={{
        label: t(commonTranslations.tryAgain.key, commonTranslations.tryAgain.defaultValue),
        onClick: () => window.location.reload(),
        buttonContentProps: {
          iconOptions: {
            icon: Icons.REFRESH,
            color: 'white',
          },
        },
      }}
    />
  );
};

export const Page: FC<React.PropsWithChildren<PageProps>> = ({
  header,
  body,
  fallbackComponent = ErrorFallback,
  headerBorderType = 'solid',
}) => {
  return (
    <ErrorBoundary FallbackComponent={fallbackComponent}>
      <Container data-testid="page" hasHeader={isValidElement(header)}>
        {header && (
          <NavBar borderType={headerBorderType} data-testid="page-nav-bar">
            {header}
          </NavBar>
        )}
        <Body data-testid="page-body">{body}</Body>
      </Container>
    </ErrorBoundary>
  );
};
