import { styled } from '@mui/material';
import { Container } from '../../../stories/atoms';

export const StyledWidgetWrapper = styled(Container)`
  border-top: 1px solid ${({ theme: { color } }) => color.borderGray};
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const StyledSummaryContainer = styled(Container)`
  height: ${({ theme: { spacing } }) => spacing(18)};
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundSelectedHover : color.backgroundTableHeader};
`;

export const StyledSummaryItem = styled(Container)`
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
