import { CSSProperties, FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Card } from '@mui/material';
import useScreenSize from '../../../hooks/useScreenSize';
import { Breakpoints, breakpoints } from '../../../theme';

const colMaxWidths: { [key: number]: number } = {
  1: 600,
  2: 1200,
};

type ContainerProps = {
  col: number;
};

const Container = styled(Card)<ContainerProps>`
  height: 400px;
  overflow-y: hidden;
  border-radius: ${({ theme: { spacing } }) => spacing(4)};

  &:last-child {
    max-width: ${({ col }) => colMaxWidths[col] || 1200}px;
  }
`;

type ColumnSpec = {
  [key: number]: number;
};

const defaultSpec: ColumnSpec = {
  1: 500,
  1.5: 600,
  2: 900,
};

const screenSpecs: { [key in Breakpoints]?: ColumnSpec } = {
  tabletLandscape: {
    1: 0,
    1.5: 0,
    2: 0,
  },
  desktop: {
    1: 300,
    1.5: 400,
    2: 450,
  },
  desktopLarge: {
    1: 300,
    1.5: 400,
    2: 660,
  },
};

type WidgetCardProps = {
  col: number;
  order: number;
  style?: CSSProperties;
};

export const WidgetCard: FC<PropsWithChildren<WidgetCardProps>> = ({ children, order, col, ...props }) => {
  const { width: screenWidth } = useScreenSize();

  const { values: breakpointValues } = breakpoints;

  const { style, ...otherPorps } = props;

  const getMinWidth = (currentScreenwidth: number, column: number): number => {
    let minWidth = defaultSpec[column];

    let breakpoint: Breakpoints | null = null;

    if (currentScreenwidth < breakpointValues.desktopLarge) {
      breakpoint = 'desktopLarge';
    }

    if (currentScreenwidth < breakpointValues.desktop) {
      breakpoint = 'desktop';
    }

    if (currentScreenwidth < breakpointValues.tabletLandscape) {
      breakpoint = 'tabletLandscape';
    }

    const screenWidthSpec = breakpoint ? screenSpecs[breakpoint] : undefined;

    if (screenWidthSpec) {
      minWidth = screenWidthSpec[column] || screenWidthSpec[1];
    }

    return minWidth;
  };

  const minWidth = getMinWidth(screenWidth, col);

  return (
    <Container
      {...otherPorps}
      col={col}
      style={{
        ...style,
        flex: `${col}`,
        minWidth: minWidth === 0 ? '100%' : `${minWidth}px`,
        order,
      }}
    >
      {children}
    </Container>
  );
};
