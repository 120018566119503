import { format, intlFormat } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StorePerformanceInterval } from '../../../constants';
import { useThemeContext } from '../../../global-state/themeContext';
import { SortDirection } from '../../../global-state/types';
import { Status, Tills, useStoreInformation, useStores, useUser } from '../../../hooks';
import { Container, Icon, Icons, Tooltip, Typography } from '../../../stories/atoms';
import { Badge } from '../../../stories/atoms/badge/Badge';
import { Card, DynamicTab, IconPositionType, Row, Table } from '../../../stories/molecules';
import { Colors } from '../../../theme';
import { homeTranslations } from '../../../translations';
import { Spacing, formatAmountWithCurrency, isFailureResponse } from '../../../utils';
import { WidgetCommonState } from '../widgetCommonState/WidgetCommonState';
import { StyledTillView } from './AssetManagement.styles';

const statusMapping: { [key in Status]: { key: string; defaultValue: string } } = {
  OK: homeTranslations.assetManagement.active,
  WARNING: homeTranslations.assetManagement.active,
  UNKNOWN: homeTranslations.assetManagement.offline,
  CLOSED: homeTranslations.assetManagement.closed,
};

type BackgroundColor = { [key in Status]: Colors };

const backgroundColorMapping: BackgroundColor = {
  OK: 'validationGreen',
  WARNING: 'validationGreen',
  UNKNOWN: 'red',
  CLOSED: 'primary',
};

type AssetManagementWidgetProps = {
  currentDate: Date;
  interval?: StorePerformanceInterval;
};

export const AssetManagementWidget: FC<AssetManagementWidgetProps> = observer(({ currentDate, interval }) => {
  const { t, i18n } = useTranslation();
  const {
    get: { currentStoreId },
  } = useUser();

  const { currencyCode } = useStoreInformation();

  const { assetManagement } = homeTranslations;

  const [allRows, setAllRows] = useState<Row[]>([]);
  const [activeRows, setActiveRows] = useState<Row[]>([]);
  const [closedRows, setClosedRows] = useState<Row[]>([]);
  const [offlineRows, setOfflineRows] = useState<Row[]>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [assetManagementResponse, setAssetManagementResponse] = useState<{
    data: Tills[];
    isLoading: boolean;
    isError: boolean;
  }>({
    data: [],
    isLoading: false,
    isError: false,
  });

  const { fetchAssetManagementWidgetData } = useStores();

  useEffect(() => {
    if (!currentStoreId) return;

    if (interval === StorePerformanceInterval.WEEKLY) {
      setAssetManagementResponse({ data: [], isError: false, isLoading: false });
      return;
    }

    const fetchTillsData = async () => {
      setAssetManagementResponse({ data: [], isLoading: true, isError: false });

      const dateToFetch = format(currentDate, 'yyyy-MM-dd');

      const response = await fetchAssetManagementWidgetData(dateToFetch);

      if (isFailureResponse(response)) {
        setAssetManagementResponse({ data: [], isLoading: false, isError: true });
        return;
      }

      const { data } = response;
      setAssetManagementResponse({ data: data.tills, isLoading: false, isError: false });
    };

    fetchTillsData();
  }, [currentStoreId, currentDate]);

  const allWorkStations = assetManagementResponse.data;
  const activeWorksStations = assetManagementResponse.data.filter(
    (row) => row.status === 'OK' || row.status === 'WARNING',
  );
  const closedWorkStations = assetManagementResponse.data.filter((row) => row.status === 'CLOSED');
  const offlineWorkStations = assetManagementResponse.data.filter((row) => row.status === 'UNKNOWN');

  const { mode } = useThemeContext();

  const sortAssetManagementData = (property: keyof Tills, order: SortDirection) => {
    if (property === 'totalAmount') {
      return (a: Tills, b: Tills) => {
        if (a[property] < b[property]) return order === 'asc' ? -1 : 1;
        if (a[property] > b[property]) return order === 'asc' ? 1 : -1;
        return 0;
      };
    } else if (property === 'latestTransaction') {
      return (a: Tills, b: Tills) => {
        if (a.latestTransaction && b.latestTransaction) {
          if (Date.parse(a.latestTransaction.timestamp) < Date.parse(b.latestTransaction.timestamp))
            return order === 'asc' ? -1 : 1;
          if (Date.parse(a.latestTransaction.timestamp) > Date.parse(b.latestTransaction.timestamp))
            return order === 'asc' ? 1 : -1;
          return 0;
        }
        return 0;
      };
    }
  };

  const getUpdatedRows = (rowData: Tills[]) => {
    return rowData.map(({ name, latestTransaction, status, amounts }, index) => {
      return {
        id: index,
        name: <Typography type="body3">{name}</Typography>,
        latestTransaction:
          !latestTransaction || latestTransaction.timestamp === '' ? (
            <>-</>
          ) : (
            <>
              <Typography type="body3" margin={[0, 0, 1]}>
                {intlFormat(
                  new Date(latestTransaction.timestamp),
                  { hour: 'numeric', minute: 'numeric' },
                  { locale: i18n.language },
                )}
              </Typography>
              <Typography type="legal" color="secondaryTextGray">
                {latestTransaction.type}
              </Typography>
            </>
          ),
        totalAmount: (
          <Typography type="body3">
            {amounts.map(({ amount }, idx) => {
              const formattedValue = formatAmountWithCurrency(amount, i18n.language, currencyCode);

              if (idx !== amounts.length - 1) {
                return formattedValue + ', ';
              }

              return formattedValue;
            })}
          </Typography>
        ),

        status: (
          <Badge width="fit-content" backgroundColor={backgroundColorMapping[status]}>
            <Typography color="white" type="body3">
              {t(statusMapping[status].key, statusMapping[status].defaultValue)}
            </Typography>
            {status === 'WARNING' && (
              <Tooltip
                position="top"
                text={
                  <Container padding={[2]}>
                    <Typography color="white">
                      {t(assetManagement.warningTooltip.key, assetManagement.warningTooltip.defaultValue)}
                    </Typography>
                  </Container>
                }
              >
                <Icon data-testid="infoIcon" icon={Icons.INFO} size="small" margin={[0, 1]} color="white" />
              </Tooltip>
            )}
          </Badge>
        ),
      };
    });
  };

  useEffect(() => {
    if (!assetManagementResponse.data.length) return;

    setAllRows(getUpdatedRows(allWorkStations));
    setActiveRows(getUpdatedRows(activeWorksStations));
    setClosedRows(getUpdatedRows(closedWorkStations));
    setOfflineRows(getUpdatedRows(offlineWorkStations));
  }, [assetManagementResponse.data]);

  const hasNoData =
    !assetManagementResponse.data.length && !assetManagementResponse.isError && !assetManagementResponse.isLoading;

  const getTemplate = () => {
    const getTabs = () => {
      return [
        {
          tabName: t(assetManagement.workstations.key, assetManagement.workstations.defaultValue),
          isTabVisible: true,
          icon: (
            <Badge>
              <Typography type="body3">{allRows.length}</Typography>
            </Badge>
          ),
          iconPosition: 'start' as IconPositionType,
          labelMargin: [0, 0, 0, 2] as Spacing,
        },
        {
          tabName: t(assetManagement.active.key, assetManagement.active.defaultValue),
          isTabDisabled: activeWorksStations.length === 0,
          isTabVisible: true,
          icon: (
            <Badge backgroundColor="validationGreen">
              <Typography type="body3" color="white">
                {activeWorksStations.length}
              </Typography>
            </Badge>
          ),
          iconPosition: 'start' as IconPositionType,
          labelMargin: [0, 0, 0, 2] as Spacing,
        },
        {
          tabName: t(assetManagement.closed.key, assetManagement.closed.defaultValue),
          isTabDisabled: closedWorkStations.length === 0,
          isTabVisible: true,
          icon: (
            <Badge backgroundColor="primary">
              <Typography type="body3" color="white">
                {closedWorkStations.length}
              </Typography>
            </Badge>
          ),
          iconPosition: 'start' as IconPositionType,
          labelMargin: [0, 0, 0, 2] as Spacing,
        },
        {
          tabName: t(assetManagement.offline.key, assetManagement.offline.defaultValue),
          isTabDisabled: offlineWorkStations.length === 0,
          isTabVisible: true,
          icon: (
            <Badge backgroundColor="red">
              <Typography type="body3" color="white">
                {offlineWorkStations.length}
              </Typography>
            </Badge>
          ),
          iconPosition: 'start' as IconPositionType,
          labelMargin: [0, 0, 0, 2] as Spacing,
        },
      ];
    };

    const getColoumns = () => {
      return [
        {
          id: 'name',
          name: t(assetManagement.name.key, assetManagement.name.defaultValue),
          width: '25%',
        },
        {
          id: 'latestTransaction',
          name: t(assetManagement.latestTransaction.key, assetManagement.latestTransaction.defaultValue),
          width: '25%',
          isSortable: true,
        },
        {
          id: 'totalAmount',
          name: t(assetManagement.totalAmount.key, assetManagement.totalAmount.defaultValue),
          width: '25%',
          isSortable: true,
        },

        {
          id: 'status',
          name: t(assetManagement.status.key, assetManagement.status.defaultValue),
          width: '25%',
        },
      ];
    };

    const handleTabChange = (event: React.SyntheticEvent, newTabIndex: number) => {
      setTabIndex(newTabIndex);
    };

    const dataTable = (filteredRows: Row[], workStations: Tills[]) => {
      const sortData = (columnId: string, direction: SortDirection) => {
        if (!columnId) return filteredRows;

        const newWorStations = workStations.map((station) => {
          if (!station.latestTransaction) {
            station.latestTransaction = {
              timestamp: '',
            };
          }
          return station;
        });

        if (columnId === 'latestTransaction' || columnId === 'totalAmount') {
          const sortedData = newWorStations.sort(sortAssetManagementData(columnId, direction));
          return getUpdatedRows(sortedData);
        }

        return filteredRows;
      };
      return (
        <Table
          data-testid="till-view-table"
          stickyHeader
          noBorder
          rows={filteredRows}
          columns={getColoumns()}
          isLoading={assetManagementResponse.isLoading}
          skeletonLoaderOptions={{ numberOfRows: 4 }}
          handleSorting={(columnId, direction) => sortData(columnId, direction)}
          fixedHeight={'270px'}
        ></Table>
      );
    };

    const defaultView = (
      <Container wrap="nowrap" data-testid="asset-management-overview" height="100%">
        <StyledTillView data-testid="till-view">
          <DynamicTab
            tabLabelArray={getTabs()}
            handleTabChange={handleTabChange}
            tabValue={tabIndex}
            variant="scrollable"
            tabBgColor={mode === 'light' ? 'white' : 'inherit'}
            content={[
              { tabContent: dataTable(allRows, allWorkStations), visible: true },
              { tabContent: dataTable(activeRows, activeWorksStations), visible: true },
              { tabContent: dataTable(closedRows, closedWorkStations), visible: true },
              { tabContent: dataTable(offlineRows, offlineWorkStations), visible: true },
            ]}
          />
        </StyledTillView>
      </Container>
    );

    if (interval === StorePerformanceInterval.WEEKLY) return <WidgetCommonState isNoData isNoWeeklyData />;
    if (hasNoData) return <WidgetCommonState isNoData />;
    if (assetManagementResponse.isError)
      return (
        <WidgetCommonState isError errorText={t(assetManagement.error.key, assetManagement.error.defaultValue)} />
      );

    return defaultView;
  };

  return (
    <Card
      headerIcon={Icons.TRANSACTIONS}
      headerText={t(assetManagement.title.key, assetManagement.title.defaultValue)}
      subHeaderText={t(assetManagement.subTitle.key, assetManagement.subTitle.defaultValue)}
      content={getTemplate()}
      toolTipText={t(assetManagement.toolTip.key, assetManagement.toolTip.defaultValue)}
    />
  );
});
