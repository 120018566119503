import { PermissionItemsMap, PermissionType } from '../hooks/useUserPermissions';
import { isKey } from './types';

type PermissionSpec = {
  canCreate: boolean;
  canRead: boolean;
  canUpdate: boolean;
  canDelete: boolean;
};

export type UserPermissions = {
  [key in PermissionType]: PermissionSpec;
};

const getPermissionSpec = (permissionValue: string): PermissionSpec => {
  const canCreate = permissionValue.indexOf('C') !== -1;
  const canRead = permissionValue.indexOf('R') !== -1;
  const canUpdate = permissionValue.indexOf('U') !== -1;
  const canDelete = permissionValue.indexOf('D') !== -1;

  return {
    canCreate,
    canRead,
    canUpdate,
    canDelete,
  };
};

export const getUserPermissions = (permissionItemsMap: PermissionItemsMap): UserPermissions => {
  const permissionsSpecsMap = {} as UserPermissions;

  for (const key in permissionItemsMap) {
    if (!isKey(permissionItemsMap, key)) continue;

    const permissionValue = permissionItemsMap[key];

    const permissionSpec = getPermissionSpec(permissionValue);

    permissionsSpecsMap[key] = permissionSpec;
  }

  return permissionsSpecsMap;
};
