import { getHours } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { StorePerformanceInterval } from '../constants';
import { KpiDistribution, StorePerformanceItem } from '../hooks';
import { getIsWithinOperationHours } from './storeUtils';

export const getXAxisLabel = (interval: StorePerformanceInterval, item: StorePerformanceItem) => {
  if (interval === StorePerformanceInterval.WEEKLY) {
    return item.timeUnit;
  }

  const { timeUnit } = item;
  const hour = parseInt(timeUnit);
  return `${hour}`;
};

export const getXAxisLabels = (
  interval: StorePerformanceInterval,
  storePerformanceItems: StorePerformanceItem[],
) => {
  if (interval === StorePerformanceInterval.WEEKLY) {
    return storePerformanceItems.map((item) => item.timeUnit);
  }

  return storePerformanceItems.map((item) => {
    const { timeUnit } = item;
    const hour = parseInt(timeUnit);
    return `${hour}`;
  });
};

export const getClippedDailyPerformanceItems = <T extends StorePerformanceItem>(params: {
  interval: StorePerformanceInterval;
  storeOpeningHour: number;
  storeClosingHour: number;
  storePerformanceItems: T[];
}) => {
  const { interval, storeOpeningHour, storeClosingHour, storePerformanceItems } = params;
  if (interval === StorePerformanceInterval.WEEKLY) return storePerformanceItems as T[];

  return storePerformanceItems.filter((item) => {
    return getIsWithinOperationHours(item.timeUnit, storeOpeningHour, storeClosingHour);
  }) as T[];
};

export const getTodayPerformanceItems = <T extends StorePerformanceItem>(params: {
  interval: StorePerformanceInterval;
  currentUTCTime: Date;
  storeTimezone: string;
  storePerformanceItems: T[];
  valueResolver: (storePerformanceItem: T) => number;
}): (T | null)[] => {
  const { interval, currentUTCTime, storeTimezone, storePerformanceItems, valueResolver } = params;

  if (interval === StorePerformanceInterval.WEEKLY) return storePerformanceItems;

  const zonedDate = utcToZonedTime(currentUTCTime, storeTimezone);

  const zonedHour = getHours(zonedDate);

  return storePerformanceItems.map((item) => {
    const { timeUnit } = item;

    // prevent filtering out performace items if they have any value at a given timeUnit
    if (valueResolver(item) !== 0) return item;

    if (parseInt(timeUnit) > zonedHour) {
      return null;
    }

    return item;
  });
};

export const getForecastPerformanceItems = <T extends StorePerformanceItem>(params: {
  interval: StorePerformanceInterval;
  performanceItems: T[];
  filteredPerformanceItems: (T | null)[];
  forecastItems: T[];
}): (T | null)[] => {
  const { interval, performanceItems, filteredPerformanceItems, forecastItems } = params;

  if (interval === StorePerformanceInterval.WEEKLY) return [];

  const forecastMap = new Map(forecastItems.map((item) => [item.timeUnit, item]));

  const filteredForecastItems = filteredPerformanceItems.map((item, index) => {
    if (item != null) return null;

    const performanceItem = performanceItems[index];

    const forecastItem = forecastMap.get(performanceItem.timeUnit);

    return forecastItem ?? null;
  });

  return filteredForecastItems;
};

export const getClippedTargetItems = (params: {
  performanceItems: StorePerformanceItem[];
  kpiDistribution: KpiDistribution[];
}) => {
  const { performanceItems, kpiDistribution } = params;

  const kpiDistributionMap = new Map(kpiDistribution.map((item) => [item.hour, item]));

  return performanceItems.map((item) => {
    const kpiDistributionItem = kpiDistributionMap.get(parseInt(item.timeUnit));

    return kpiDistributionItem ? kpiDistributionItem.value : null;
  });
};
