import { useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { DetailedReportItem, ReportItem } from '../../../hooks';
import { Container, Icons } from '../../../stories/atoms';
import { muiTheme } from '../../../theme';
import { Spacing } from '../../../utils';
import { PLCardContent } from '../plCardContent/PLCardContent';
import { StyledDivider, StyledIcon, StyledPLCardContainer } from '../ProfitAndLoss.styles';

type PLExpandCardProps = {
  cardKey?: string;
  cardContent: DetailedReportItem | ReportItem;
  isParent: boolean;
  isExpanded?: boolean;
  onExpand?: (value: { [key: string]: boolean }) => void;
  onViewDetailsClick: (details: ReportItem) => void;
};

export const PLExpandCard: FC<PLExpandCardProps> = ({
  cardKey,
  cardContent,
  isParent,
  isExpanded = false,
  onExpand,
  onViewDetailsClick,
}) => {
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

  const handleExpand = () => {
    if (onExpand && cardKey) {
      onExpand({
        [cardKey]: !isExpanded,
      });
    }
  };

  const getPadding = (): Spacing => {
    if (isMobile) {
      return isParent ? [3, 2] : [2, 0];
    }
    return isParent ? [4, 3] : [2];
  };

  const isDetailedReportItem = (content: ReportItem | DetailedReportItem): content is DetailedReportItem => {
    return (content as DetailedReportItem).reports !== undefined;
  };

  return (
    <StyledPLCardContainer
      data-testid="profit-loss-expand-card"
      position={!isParent ? 'center' : undefined}
      direction="horizontal"
      padding={getPadding()}
      width="100%"
      wrap="nowrap"
      margin={isParent ? [0, 0, 3, 0] : [0]}
      isParent={isParent}
    >
      {isParent && (
        <StyledIcon
          data-testid="expand-icon"
          icon={Icons.DROPDOWN}
          size={isMobile ? 'small' : 'medium'}
          margin={isMobile ? [1] : [1, 2]}
          isExpanded={isExpanded}
          onClick={handleExpand}
        />
      )}
      <Container wrap="nowrap" width="100%" position="bottom">
        <PLCardContent reportDetails={cardContent} isParent={isParent} onViewDetailsClick={onViewDetailsClick} />
        {isExpanded && isDetailedReportItem(cardContent) && (
          <>
            <StyledDivider />
            {cardContent.reports.map((item) => (
              <PLExpandCard
                key={item.costName}
                cardContent={item}
                isParent={false}
                onViewDetailsClick={onViewDetailsClick}
              />
            ))}
          </>
        )}
      </Container>
    </StyledPLCardContainer>
  );
};
