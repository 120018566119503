import { useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PLReportProperties } from '../../../global-state/types';
import { ReportItem, useDynamicTranslation, useUser } from '../../../hooks';
import { Container, Typography } from '../../../stories/atoms';
import { muiTheme } from '../../../theme';
import { profitAndLossTransalations } from '../../../translations';
import { PLStyledValueContainer, StyledDetailsButton } from '../ProfitAndLoss.styles';
import { generateCardTitle, profitLossPropertyToValueMap } from '../util';

type PLCardProps = {
  reportDetails: ReportItem;
  onViewDetailsClick: (details: ReportItem) => void;
  isParent: boolean;
};

type ValueContainerProps = {
  label: string;
  value: string;
};

const isValueBelowThreshold = (threshold: number, value: string) => parseInt(value) < threshold;

const getColor = (label: string, value: string) => {
  if (label === 'indexVsLY' || label === 'indexVsBudget') {
    return isValueBelowThreshold(100, value) ? 'red' : 'validationGreen';
  }
  return 'primary';
};

export const ValueContainer: FC<ValueContainerProps> = ({ label, value }) => {
  const { t } = useTranslation();
  const { reportProperties } = profitAndLossTransalations;
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

  return (
    <PLStyledValueContainer
      data-testid="pl-value-container"
      position="center"
      padding={isMobile ? [2, 0] : [0]}
      isMobile={isMobile}
      space="between"
    >
      <Typography color={getColor(label, value)}>{value}</Typography>
      <Typography color="textGray">
        {t(
          reportProperties[label as PLReportProperties].key,
          reportProperties[label as PLReportProperties].defaultValue,
        )}
      </Typography>
    </PLStyledValueContainer>
  );
};

export const PLCardContent: FC<PLCardProps> = ({ reportDetails, onViewDetailsClick, isParent }) => {
  const { t } = useTranslation();
  const { departmentID, costName, actuals, indexVsBudget } = reportDetails;
  const { reportProperties, viewDetailsButton } = profitAndLossTransalations;
  const { getTranslations } = useDynamicTranslation();
  const {
    get: { profile },
  } = useUser();

  const language = profile?.settings?.defaultLanguage ?? 'en-GB';
  const typographyType = isParent ? 'button' : 'body3';
  const cardName = getTranslations(costName, language);
  const cardTitle = generateCardTitle(departmentID, cardName, isParent);

  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

  const profitLossValues = reportDetails && profitLossPropertyToValueMap(reportDetails);

  return (
    <>
      {!isMobile && (
        <Container data-testid="pl-card-container" width="100%">
          <Typography margin={isParent ? [0, 0, 3] : [0, 0, 2]} type={typographyType}>
            {cardTitle}
          </Typography>
          <Container direction="horizontal" space="between">
            {profitLossValues &&
              profitLossValues.map((item) => (
                <ValueContainer key={item.key} label={item.key} value={item.value?.toString()} />
              ))}
          </Container>
        </Container>
      )}
      {isMobile && (
        <Container data-testid="pl-card-container-mobile" width="100%">
          <Container direction="horizontal" space="between" wrap="nowrap">
            <Typography type={typographyType}>{cardTitle}</Typography>
            <StyledDetailsButton
              textOptions={{ type: 'caption' }}
              label={t(viewDetailsButton.key, viewDetailsButton.defaultValue)}
              onClick={() => onViewDetailsClick(reportDetails)}
            />
          </Container>
          <Container padding={[1, 0, 0]}>
            <Typography padding={[0, 0, 1]} type="caption">
              {t(reportProperties.actuals.key, reportProperties.actuals.defaultValue)}: {actuals.toLocaleString()}
            </Typography>
            {isParent && (
              <Typography
                padding={[1, 0]}
                type="caption"
                color={getColor('indexVsBudget', indexVsBudget.toString())}
              >
                {t(reportProperties.indexVsBudget.key, reportProperties.indexVsBudget.defaultValue)}:{' '}
                {indexVsBudget}
              </Typography>
            )}
          </Container>
        </Container>
      )}
    </>
  );
};
