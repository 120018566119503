import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import articlePlaceholder from '../../../assets/articlePlaceholder.png';
import { StorePerformanceInterval } from '../../../constants';
import { useEventContext } from '../../../global-state/eventContext';
import { useThemeContext } from '../../../global-state/themeContext';
import { TopReturningGarmentResponse, useTransaction } from '../../../hooks';
import i18n from '../../../i18n';
import { Icons, Typography } from '../../../stories/atoms';
import { Card, DynamicTab, Row, TabContentProps, TabLabelProps, Table } from '../../../stories/molecules';
import { homeTranslations } from '../../../translations';
import { isFailureResponse } from '../../../utils';
import { StyledArticles, StyledItem, StyledView } from '../topSellingGarment/TopSellingGarmentWidget.styles';
import { WidgetCommonState } from '../widgetCommonState/WidgetCommonState';

type TopReturningGarmentWidgetProps = {
  currentDate: Date;
  interval?: StorePerformanceInterval;
};

export const TopReturningGarmentWidget: FC<TopReturningGarmentWidgetProps> = observer(
  ({ currentDate, interval }) => {
    const { fetchTopReturningGarmentData } = useTransaction();
    const { transactionEventsCount } = useEventContext();

    // TODO: Uncomment while integrating ORIS data
    // const [view, setView] = useState('view1');
    const [tabIndex, setTabIndex] = useState(0);
    const [topReturnedGarmentTabs, setTopReturnedGarmentTabs] = useState<TabLabelProps[]>([]);
    const [topReturnedGarmentContent, setTopReturnedGarmentContent] = useState<TabContentProps[]>([]);
    const [topReturnedGarments, setTopReturnedgGarments] = useState<{
      data: TopReturningGarmentResponse[];
      isLoading: boolean;
      isError: boolean;
    }>({
      data: [],
      isLoading: false,
      isError: false,
    });

    // TODO: Uncomment while integrating ORIS data
    // const ORISdata = {
    //   conversionRate: 70,
    //   totalCustomers: 203,
    //   convertedCustomers: 46,
    // };

    const { mode } = useThemeContext();
    const { t } = useTranslation();
    const { topReturns, common } = homeTranslations;

    useEffect(() => {
      if (interval === StorePerformanceInterval.WEEKLY) {
        setTopReturnedgGarments({ data: [], isError: false, isLoading: false });
        return;
      }

      const fetchResponse = async () => {
        setTopReturnedgGarments({ data: [], isLoading: true, isError: false });

        const response = await fetchTopReturningGarmentData(i18n.language, format(currentDate, 'yyyy-MM-dd'));

        if (isFailureResponse(response)) {
          setTopReturnedgGarments({ data: [], isLoading: false, isError: true });
          return;
        }

        const { data } = response;
        const sortedData = data.sort((a, b) => b.returnedPieces - a.returnedPieces);
        setTopReturnedgGarments({ data: sortedData, isLoading: false, isError: false });
      };

      fetchResponse();
    }, [currentDate, i18n.language, transactionEventsCount]);

    const getUpdatedRows = (categoryGroup: TopReturningGarmentResponse[]) =>
      categoryGroup.map(({ title, returnedPieces, whitePrice, imageUrl }, index) => ({
        id: index,
        item: (
          <StyledArticles>
            <StyledItem
              data-testid="article-image"
              src={imageUrl}
              alt=""
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.src = articlePlaceholder;
              }}
              width="100"
              height="100"
            />
            <div>
              <Typography>{title}</Typography>
              <Typography>{whitePrice}</Typography>
            </div>
          </StyledArticles>
        ),
        count: <Typography color="textGray">{returnedPieces} pc</Typography>,
      }));

    useEffect(() => {
      if (!topReturnedGarments.data.length) return;

      // Filtering category data from main response into categoryGroups
      const categoryGroups = topReturnedGarments.data.reduce((map, item) => {
        const { receiptSubType } = item;
        const group = map.get(receiptSubType) || [];
        group.push(item);
        map.set(receiptSubType, group);
        return map;
      }, new Map());

      // Setting two useStates for tabs and content dynamically taking top 10 items
      const tabLabelArray = [
        { tabName: t(common.allTabName.key, common.allTabName.defaultValue), isTabVisible: true },
      ];
      const tabContentArray = [
        { tabContent: getTabContent(getUpdatedRows(topReturnedGarments.data.slice(0, 10))), visible: true },
      ];

      categoryGroups.forEach((value, key) => {
        const newTab = { tabName: key, isTabVisible: true };
        const newTabcontent = { tabContent: getTabContent(getUpdatedRows(value.slice(0, 10))), visible: true };
        tabLabelArray.push(newTab);
        tabContentArray.push(newTabcontent);
      });

      setTopReturnedGarmentTabs(tabLabelArray);
      setTopReturnedGarmentContent(tabContentArray);
    }, [topReturnedGarments.data]);

    const handleTabChange = (event: React.SyntheticEvent, newTabIndex: number) => {
      setTabIndex(newTabIndex);
    };

    // TODO: Uncomment while integrating ORIS data
    // const handleChangeView = (event: React.MouseEvent<HTMLElement>, view2: string) => {
    //   if (view2 !== null) {
    //     setView(view2);
    //   }
    // };

    const getTabContent = (tabRows: Row[]) => {
      return (
        <Table
          stickyHeader
          noBorder
          fixedHeight={'270px'}
          rows={tabRows}
          noHeader
          columns={[
            {
              id: 'item',
              name: t(common.type.key, common.type.defaultValue),
              width: '75%',
            },
            {
              id: 'count',
              name: t(common.count.key, common.count.defaultValue),
              width: '25%',
            },
          ]}
          isLoading={topReturnedGarments.isLoading}
          skeletonLoaderOptions={{ numberOfRows: 4 }}
        ></Table>
      );
    };

    const CardContent = () => {
      return (
        <StyledView wrap="nowrap" data-testid="top-returning-garment-overview" height="100%" hasBorder={true}>
          <StyledView data-testid="top-returning-garment-table" hasBorder={false}>
            <DynamicTab
              tabLabelArray={topReturnedGarmentTabs}
              handleTabChange={handleTabChange}
              tabValue={tabIndex}
              variant="scrollable"
              tabBgColor={mode === 'light' ? 'baseGray' : 'backgroundTableHeaderDark'}
              content={topReturnedGarmentContent}
            />
          </StyledView>
          {/* TODO: Uncomment while integrating ORIS data */}
          {/* {view === 'view2' && <OrisConversionRate ORISdata={ORISdata} />} */}
        </StyledView>
      );
    };

    // TODO: Uncomment while integrating ORIS data
    // const rightContainerElements = () => {
    //   return (
    //     <ToggleButton
    //       value={view}
    //       onChange={handleChangeView}
    //       leftButton={Icons.RETURNS}
    //       rightButton={Icons.CONVERSION}
    //     />
    //   );
    // };

    const getTemplate = () => {
      if (interval === StorePerformanceInterval.WEEKLY) return <WidgetCommonState isNoData isNoWeeklyData />;
      if (topReturnedGarments.isError)
        return <WidgetCommonState isError errorText={t(topReturns.error.key, topReturns.error.defaultValue)} />;
      if (topReturnedGarments.isLoading) return <WidgetCommonState isLoading />;
      if (!topReturnedGarments.data.length) return <WidgetCommonState isNoData />;

      return <CardContent />;
    };

    return (
      <Card
        headerIcon={Icons.CUSTOMERVALUE}
        headerText={t(topReturns.title.key, topReturns.title.defaultValue)}
        subHeaderText={t(topReturns.subTitle.key, topReturns.subTitle.defaultValue)}
        content={getTemplate()}
        toolTipText={t(topReturns.toolTip.key, topReturns.toolTip.defaultValue)}
      />
    );
  },
);
