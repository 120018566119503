export enum WidgetType {
  NewMembers = 'NewMembers',
  ReceiptSales = 'ReceiptSales',
  PiecesPerReceipt = 'PiecesPerReceipt',
  ConversionRate = 'ConversionRate',
  HourlyTurnover = 'HourlyTurnover',
  TopSellingGarment = 'TopSellingGarment',
  ShareOfReceipts = 'ShareOfReceipts',
  TenderOverview = 'TenderOverview',
  TopReturningGarment = 'TopReturningGarment',
  RegisteredReceipts = 'RegisteredReceipts',
  AssetManagement = 'AssetManagement',
  AveragePurchaseWidget = 'AveragePurchaseWidget',
  EffectiveHoursWidget = 'EffectiveHoursWidget',
  QueueLengthWidget = 'QueueLengthWidget',
  AppCustomersWidget = 'AppCustomersWidget',
}

export type CategoryWidgetOrderMap = {
  [key in WidgetType]?: number;
};
