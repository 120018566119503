import { useMediaQuery } from '@mui/material';
import {
  BaseSingleInputFieldProps,
  DateValidationError,
  DateView,
  FieldSection,
  PickersLayoutContentWrapper,
  PickersLayoutProps,
  PickersLayoutRoot,
  pickersLayoutClasses,
  usePickerLayout,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { UseDateFieldProps } from '@mui/x-date-pickers/DateField';
import { DatePickerProps, DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format, getWeek, sub } from 'date-fns';
import React, { FC, memo, useCallback, useState } from 'react';
import i18n from '../../../i18n';
import { muiTheme } from '../../../theme';
import { isInSameWeek } from '../../../utils';
import { localeMap } from '../../../utils/language';
import { Icon, Icons } from '../../atoms';
import { Button } from '../button/Button';
import { CustomPickersDay, StyledButtonWrapper } from './DatePicker.styles';

interface ButtonFieldProps
  extends UseDateFieldProps<Date, false>,
    BaseSingleInputFieldProps<Date | null, Date, FieldSection, false, DateValidationError> {
  label: string;
  isDropdown?: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showCalendarIcon?: boolean;
}

const ButtonField: FC<ButtonFieldProps> = memo((props) => {
  const {
    id,
    label,
    isDropdown = false,
    setOpen,
    InputProps: { ref } = {},
    inputProps: { 'aria-label': ariaLabel } = {},
    showCalendarIcon = false,
  } = props;

  return (
    <StyledButtonWrapper data-testid="date-picker-button" isDropdown={isDropdown}>
      {showCalendarIcon && <Icon icon={Icons.CALENDAR} size="small" />}
      <Button
        label={label}
        onClick={() => setOpen?.((prev) => !prev)}
        buttonContentProps={{
          labelPosition: 'left',
          iconOptions: {
            icon: Icons.DROPDOWN,
            size: 'small',
          },
        }}
        variant="text"
        ref={ref}
        aria-label={ariaLabel}
        id={id}
      />
    </StyledButtonWrapper>
  );
});

const CustomLayout: FC<PickersLayoutProps<Date | null, Date, DateView>> = memo((props) => {
  const { content, actionBar } = usePickerLayout(props);
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletLandscape'));
  const commonColor = muiTheme.palette.mode === 'dark' ? muiTheme.color.borderGray : muiTheme.color.textGray;
  const disabledColor =
    muiTheme.palette.mode === 'dark' ? muiTheme.color.borderGrayDark : muiTheme.color.inActiveGray;

  return (
    <PickersLayoutRoot
      ownerState={props}
      sx={{
        '.MuiDayCalendar-weekNumberLabel, .MuiDayCalendar-weekDayLabel, .MuiDayCalendar-weekNumber': {
          color: commonColor,
        },
        '.MuiDayCalendar-weekContainer .Mui-disabled': {
          color: disabledColor,
        },
        ...(isMobile && {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }),
      }}
    >
      {actionBar}
      {content}
      <PickersLayoutContentWrapper className={pickersLayoutClasses.contentWrapper}></PickersLayoutContentWrapper>
    </PickersLayoutRoot>
  );
});

const ButtonDatePicker = (
  props: Omit<DatePickerProps<Date>, 'open' | 'onOpen' | 'onClose' | 'onChange' | 'label'> & {
    isDropdown?: boolean;
    label: string;
    onLabelChange: (label: string) => void;
    pickerType: 'date' | 'week';
    onChange: (date: Date | null, label: string) => void;
    showCalendarIcon?: boolean;
    actionBar?: JSX.Element;
  },
) => {
  const [open, setOpen] = useState(false);
  const [hovered, setHovered] = useState<Date | null>(null);
  const [selected, setSelected] = useState<Date | null>(null);

  const { isDropdown, label, onLabelChange, pickerType, onChange, showCalendarIcon, actionBar } = props;

  const handleDateChange = useCallback(
    (date: Date | null) => {
      let newLabel = '';

      if (pickerType === 'week' && date) {
        const weekNumber = getWeek(date);
        newLabel = `Week ${weekNumber}`;
      } else if (date) {
        newLabel = format(date, 'dd-MMM-yyyy');
      }

      setSelected(date);
      onLabelChange(newLabel);

      if (onChange) {
        onChange(date, newLabel);
      }
      setOpen(false);
    },
    [pickerType, onLabelChange, onChange],
  );

  return (
    <MuiDatePicker
      slots={{
        field: ButtonField,
        layout: CustomLayout,
        actionBar: () => actionBar,
        day:
          pickerType === 'week'
            ? (other) => (
                <CustomPickersDay
                  {...other}
                  sx={{ px: 2.5 }}
                  disableMargin
                  isSelected={isInSameWeek(other.day, selected)}
                  isHovered={isInSameWeek(other.day, hovered)}
                  onPointerEnter={() => setHovered(other.day)}
                  onPointerLeave={() => setHovered(null)}
                />
              )
            : undefined,
        ...props.slots,
      }}
      slotProps={{
        ...props.slotProps,
        field: { setOpen, isDropdown, label, showCalendarIcon } as never,
        day:
          props.pickerType === 'week'
            ? (ownerState) => ({
                isSelected: selected,
                isHovered: hovered,
                onPointerEnter: () => setHovered(ownerState.day),
                onPointerLeave: () => setHovered(null),
              })
            : undefined,
      }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      onChange={handleDateChange}
    />
  );
};

export type PickerProps = {
  inputSelectedDate: Date;
  label: string;
  disableFutureDates?: boolean;
  displayWeekNumber?: boolean;
  minDate?: Date;
  maxDate?: Date;
  views?: Array<'day' | 'month' | 'year'>;
  isDropdown?: boolean;
  onChange?: (dates: Date, label: string) => void;
  pickerType?: 'date' | 'week';
  showCalendarIcon?: boolean;
  actionBar?: JSX.Element;
};

export const DatePicker: FC<React.PropsWithChildren<PickerProps>> = ({
  inputSelectedDate,
  disableFutureDates = true,
  displayWeekNumber = true,
  isDropdown,
  views = ['day'], // default show only day view
  minDate = sub(new Date(), { months: 18 }), // set minDate to 18 months ago
  maxDate,
  onChange,
  pickerType = 'date',
  showCalendarIcon = false,
  actionBar,
  label,
}) => {
  const currentLocale = localeMap[i18n.language];

  const [selectedLabel, setSelectedLabel] = useState(label);

  const handleDateChange = useCallback(
    (date: Date | null, labelT: string) => {
      if (date) {
        setSelectedLabel(selectedLabel);
        if (onChange) {
          onChange(date, labelT);
        }
      }
    },
    [onChange],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={currentLocale}>
      <ButtonDatePicker
        value={inputSelectedDate}
        onChange={handleDateChange}
        disableFuture={disableFutureDates}
        displayWeekNumber={displayWeekNumber}
        views={views}
        minDate={minDate}
        maxDate={maxDate}
        label={label}
        isDropdown={isDropdown}
        onLabelChange={setSelectedLabel}
        pickerType={pickerType}
        showCalendarIcon={showCalendarIcon}
        actionBar={actionBar}
      />
    </LocalizationProvider>
  );
};
