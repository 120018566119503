import { KpiAreaValueType, KpiType } from '../../constants';
import { AreaTargetResponse, KpiAreaDetails } from '../../hooks';

const generateRandomInteger = (min: number, max: number) => {
  return Math.floor(min + Math.random() * (max - min + 1));
};

export const widgetNames: KpiType[] = [
  'dailyConversionRate',
  'dailyNewMembers',
  'dailyTurnOver',
  'dailyShareOfReceipts',
  'dailyQueueLength',
];

export const MockWidgetKpi: KpiAreaDetails[] = widgetNames.map((widgetName) => {
  let min = null;
  let max = null;
  if (
    widgetName === 'dailyConversionRate' ||
    widgetName === 'dailyNewMembers' ||
    widgetName === 'dailyShareOfReceipts' ||
    widgetName === 'dailyQueueLength'
  ) {
    min = 3;
    max = 40;
  } else {
    min = 200000;
    max = 700000;
  }
  return {
    kpiName: widgetName,
    kpiValues: [
      {
        kpiProperty: KpiAreaValueType.RECOMMENDED_TARGET,
        kpiValue: widgetName === 'dailyNewMembers' ? null : generateRandomInteger(min, max),
      },
      {
        kpiProperty: KpiAreaValueType.AREA_AVERAGE,
        kpiValue: generateRandomInteger(min, max),
      },
      {
        kpiProperty: KpiAreaValueType.AREA_MIN,
        kpiValue: generateRandomInteger(min, max),
      },
      {
        kpiProperty: KpiAreaValueType.AREA_MAX,
        kpiValue: generateRandomInteger(min, max),
      },
      {
        kpiProperty: KpiAreaValueType.AREA_CAP,
        kpiValue: widgetName === 'dailyTurnOver' ? null : generateRandomInteger(min, max),
      },
    ],
  };
});

export const mockAreaTargets: AreaTargetResponse[] = [
  {
    storeId: 'SE0151',
    currentTarget: 20,
    recommendedTarget: 12,
    storeName: 'H&M - Väsby Centrum - Upplands Väsby',
  },
  {
    storeId: 'SE0110',
    currentTarget: 3,
    recommendedTarget: null,
    storeName: 'H&M - Täby Centrum - Täby',
  },
  {
    storeId: 'SE0421',
    currentTarget: null,
    recommendedTarget: 3,
    storeName: 'H&M - Tuna Park - Eskilstuna',
  },
];

export const mockKpiTargets = () => {
  const distributions = Array.from({ length: 24 }, (_, hour) => {
    const value = Math.random() * 50; // generates a random number between 0 and 50
    return { hour, value };
  });

  return {
    dailyNewMembers: {
      updated: '2024-04-18',
      target: 12,
      isLocked: false,
      distributions: distributions,
    },
    dailyTurnOver: {
      updated: '2024-04-18',
      target: 60,
      isLocked: false,
      distributions: distributions,
    },
    dailyShareOfReceipts: {
      updated: '2024-04-15',
      target: 15,
      isLocked: true,
      distributions: distributions,
    },
    dailyConversionRate: {
      updated: '2024-04-18',
      target: 30,
      isLocked: false,
      distributions: distributions,
    },
    dailyQueueLength: {
      updated: '2024-04-18',
      target: 20,
      isLocked: false,
      distributions: distributions,
    },
  };
};

export const MockResponseAreaKpis = () => {
  const areaKpiData = [
    {
      kpiName: 'dailyConversionRate',
      recommendedTarget: 26.83,
      areaAverage: 22.27,
      areaMin: 0,
      areaMax: 48.56943475226797,
      areaCap: 10,
    },
    {
      kpiName: 'dailyTurnOver',
      recommendedTarget: 14309.35,
      areaAverage: 11003.95,
      areaMin: 0,
      areaMax: 34641.00950000001,
      areaCap: 20,
    },
    {
      kpiName: 'dailyNewMembers',
      recommendedTarget: null,
      areaAverage: 0,
      areaMin: 0,
      areaMax: 0,
      areaCap: null,
    },
    {
      kpiName: 'dailyShareOfReceipts',
      recommendedTarget: 53.23,
      areaAverage: 33.49,
      areaMin: 0,
      areaMax: 63.76811594202898,
      areaCap: 40,
    },
    {
      kpiName: 'dailyQueueLength',
      recommendedTarget: 10.5,
      areaAverage: 9.5,
      areaMin: 0,
      areaMax: 20,
      areaCap: 10,
    },
  ];

  const refactoredKpiTargets: KpiAreaDetails[] = areaKpiData.map((kpi) => ({
    kpiName: kpi.kpiName as KpiType,
    kpiValues: Object.entries(kpi)
      .filter(([key]) => key !== 'kpiName')
      .map(([kpiProperty, kpiValue]) => ({
        kpiProperty: kpiProperty as KpiAreaValueType,
        kpiValue: kpiValue as number | null,
      })),
  }));

  return refactoredKpiTargets;
};
