import { useMediaQuery } from '@mui/material';
import { useDecision } from '@optimizely/react-sdk';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { baseUrlCDN } from '../../../constants';
import { corporateBrandIcons } from '../../../constants/brandLogos';
import { useThemeContext } from '../../../global-state/themeContext';
import { StoreDetails } from '../../../global-state/types';
import { useProfile, useUser } from '../../../hooks';
import { LogoutModal } from '../../../pages/logout-modal/LogoutModal';
import { SelectRoleModal } from '../../../pages/select-role/SelectRoleModal';
import { NavigateToSwitchStoreModal } from '../../../pages/select-store/navigate-to-switch-store-modal/NavigateToSwitchStoreModal';
import { SwitchStoreModal } from '../../../pages/select-store/switch-store-modal/SwitchStoreModal';
import { muiTheme } from '../../../theme';
import { headerTranslations, multiStoreMenuItemTranslation } from '../../../translations';
import { Translation, isFailureResponse } from '../../../utils';
import { Icon, Icons, Typography } from '../../atoms';
import { DropdownMenu, IconWithText } from '../../molecules';
import {
  LanguageItem,
  StyledCheckedIcon,
  StyledContainer,
  StyledDivider,
  StyledHeaderContainer,
  StyledHeaderMenu,
  StyledIcon,
  StyledMenuItem,
  StyledWrapper,
} from './Header.styles';
import { MessageDropdown } from './header-menus';

const MAX_STORES_IN_DROPDOWN = 5;

export type BorderType = 'solid' | 'dashed' | 'dotted';

export type Props = {
  onOpenNav?: () => void;
  currentPathNameTranslation: Translation;
  borderType?: BorderType;
  isNonSignPage?: boolean;
  onRoleChangeSuccess?: () => void;
};

const { currentlyWorkingOn, switchLanguage, switchStore } = multiStoreMenuItemTranslation;
const { signin } = headerTranslations;

export const Header: FC<React.PropsWithChildren<Props>> = observer(
  ({
    onOpenNav,
    currentPathNameTranslation,
    borderType = 'solid',
    isNonSignPage = false,
    onRoleChangeSuccess,
  }) => {
    const { key, defaultValue } = currentPathNameTranslation;

    const [storeIdToSwitchTo, setStoreIdToSwitchTo] = useState<string>('');
    const [redirectUrl, setRedirectUrl] = useState<string>('');
    const [isNavigateModalOpen, setIsNavigateModalOpen] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [isSelectRoleModalOpen, setIsSelectRoleModalOpen] = useState(false);

    const { mode, toggleTheme } = useThemeContext();
    const { t, i18n } = useTranslation();
    const {
      get: { currentStoreId, storesDetails, profile, stores, userId },
      set,
    } = useUser();
    const { updateProfile } = useProfile();
    const navigate = useNavigate();
    const [selectRoleDropdown] = useDecision('select_role', {}, { overrideUserId: userId || undefined });

    const defaultLanguage = profile.settings?.defaultLanguage || i18n.language;

    const isDesktop = useMediaQuery(muiTheme.breakpoints.up('desktopLarge'));
    const isTablet = useMediaQuery(muiTheme.breakpoints.down('tabletLandscape'));
    const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

    const countryFlag = new Intl.DisplayNames('en', { type: 'region' });
    const displayName = new Intl.DisplayNames(i18n.language, { type: 'language' });

    useEffect(() => {
      // Initialize the language based on the user's profile settings
      i18n.changeLanguage(defaultLanguage);
    }, [profile, i18n]);

    const getFlagUrl = (language: string) => {
      let countryName = countryFlag.of(language.slice(-2))?.toLowerCase().replaceAll(' ', '_');

      // Handle Turkish
      if (language === 'tr-TR') {
        countryName = 'turkey';
      }

      // Handle Arabic
      if (language === 'ar') {
        countryName = 'saudiarabia';
      }

      return `${baseUrlCDN}/flags/${countryName}.svg${process.env.REACT_APP_CDN_TOKEN}`;
    };

    const handleSettingsChange = async (settings: { defaultLanguage?: string; darkMode?: boolean }) => {
      const response = await updateProfile(profile.upn, [
        {
          op: 'add',
          path: '/settings',
          value: {
            ...profile.settings,
            ...settings,
          },
        },
      ]);

      if (isFailureResponse(response)) {
        return set({
          profile: {
            ...profile,
            settings: {
              ...settings,
            },
          },
        });
      }

      set({ profile: response.data });
      if (settings.defaultLanguage) {
        i18n.changeLanguage(settings.defaultLanguage);
      }
    };

    const getLanguages = () => {
      const supportedLanguages = i18n.options.supportedLngs;
      if (supportedLanguages) {
        return supportedLanguages
          .filter((language) => language !== 'cimode')
          .sort((a, b) => {
            a.localeCompare(b);
            const aLanguage = displayName.of(a);
            const bLanguage = displayName.of(b);

            if (!aLanguage || !bLanguage) return 0;

            return aLanguage < bLanguage ? -1 : 1;
          })
          .map((language) => {
            return {
              key: language,
              item: (
                <LanguageItem>
                  <img width={28} height={28} src={getFlagUrl(language)} alt={language} />
                  <Typography>{displayName.of(language)}</Typography>
                </LanguageItem>
              ),
            };
          });
      }
      return [];
    };

    const showNavigateModal = () => {
      setIsNavigateModalOpen(true);
    };

    // Get the corporateBrandId from the current store details
    const corporateId = stores.find(({ storeId }) => storeId === currentStoreId)?.corporateBrandId;
    const brandIcon = corporateId ? corporateBrandIcons[corporateId] : Icons.HM_LOGO;

    return (
      <StyledHeaderContainer data-testid="header" borderType={borderType}>
        <StyledWrapper isMobile={isTablet}>
          {isNonSignPage ? (
            <Icon data-testid="homeIcon" onClick={() => null} icon={brandIcon} margin={[0, 2]} color="red" />
          ) : (
            !isDesktop && (
              <>
                <Icon data-testid="menuIcon" onClick={onOpenNav} icon={Icons.MENU} size="small" margin={[0, 1]} />
                <StyledContainer>
                  <StyledIcon data-testid="homeIcon" onClick={() => navigate(`/home`)} icon={brandIcon} />
                  {!isMobile && (
                    <>
                      <StyledDivider orientation="vertical" />
                      <Typography type="h4" padding={isTablet ? [1, 4] : [1]}>
                        {t(key, defaultValue)}
                      </Typography>
                    </>
                  )}
                </StyledContainer>
              </>
            )
          )}
          {isDesktop && <Typography type="h4">{t(key, defaultValue)}</Typography>}
          <StyledHeaderMenu data-testid="header-menu">
            <Icon
              data-testid={mode === 'dark' ? 'theme-icon-sun' : 'theme-icon-moon'}
              icon={mode === 'dark' ? Icons.SUN : Icons.MOON}
              onClick={() => {
                const newThemeMode = mode === 'dark' ? 'light' : 'dark';
                toggleTheme();
                handleSettingsChange({ darkMode: newThemeMode === 'dark' });
              }}
            />
            <DropdownMenu
              title={t(switchLanguage.key, switchLanguage.defaultValue)}
              dropdownLabel={''}
              menuItems={getLanguages()}
              initialSelected={[defaultLanguage]}
              onSelect={(selectedItemKey) => handleSettingsChange({ defaultLanguage: selectedItemKey[0] })}
              menuPosition="left"
              backgroundColor={{ hover: 'baseGray', selected: 'primary', selectedHover: 'secondaryTextGray' }}
              buttonContentProps={{
                iconOptions: {
                  customIcon: (
                    <img width={28} height={28} src={getFlagUrl(defaultLanguage)} alt={defaultLanguage} />
                  ),
                },
              }}
              isOnlyIcon
            />

            {selectRoleDropdown.enabled && (
              <Icon
                data-testid="select-role"
                icon={Icons.PROFILE_SETTINGS}
                onClick={() => {
                  setIsSelectRoleModalOpen(true);
                }}
              />
            )}

            {storesDetails.length > 1 && currentStoreId && (
              <DropdownMenu
                dropdownLabel={' '}
                buttonContentProps={{
                  iconOptions: { customIcon: <Icon icon={Icons.STORE} /> },
                }}
                title={t(switchStore.key, switchStore.defaultValue)}
                menuItems={[
                  storesDetails.find(({ storeId }) => storeId === currentStoreId) as StoreDetails,
                  ...storesDetails.filter(({ storeId }) => storeId !== currentStoreId),
                ].map(({ storeId, name }) => ({
                  key: storeId,
                  item: (
                    <StyledMenuItem isSelected={currentStoreId === storeId}>
                      {currentStoreId === storeId && (
                        <StyledCheckedIcon icon={Icons.CHECK_MARK_CIRCLE} size="small" color="white" />
                      )}
                      <div>
                        <div>
                          <Typography color={currentStoreId === storeId ? 'white' : 'primary'}>
                            {name + ' - ' + storeId}
                          </Typography>
                        </div>
                        {currentStoreId === storeId && (
                          <Typography color="white">
                            {t(currentlyWorkingOn.key, currentlyWorkingOn.defaultValue)}
                          </Typography>
                        )}
                      </div>
                    </StyledMenuItem>
                  ),
                }))}
                initialSelected={[currentStoreId]}
                onSelect={(selectedItemKeys) => {
                  if (selectedItemKeys[0] !== currentStoreId) setStoreIdToSwitchTo(selectedItemKeys[0]);
                  setRedirectUrl('/home');
                }}
                menuPosition={'left'}
                backgroundColor={{
                  hover: 'baseGray',
                  selected: 'primary',
                  selectedHover: 'secondaryTextGray',
                }}
                isOnlyIcon
                overrideAction={storesDetails.length > MAX_STORES_IN_DROPDOWN ? showNavigateModal : undefined}
              />
            )}

            {!isNonSignPage && (
              <MessageDropdown
                onStoreChange={(id, url) => {
                  setStoreIdToSwitchTo(id);
                  setRedirectUrl(url);
                }}
              />
            )}

            {!isNonSignPage ? (
              <Icon
                data-testid="sign-out"
                icon={Icons.SIGNOUT}
                onClick={() => setShowLogoutModal(true)}
                margin={[0, 4]}
              />
            ) : (
              <IconWithText
                data-testid="signIn"
                label={<Typography type="body3">{t(signin.key, signin.defaultValue)}</Typography>}
                onClick={() => {
                  window.location.replace('/home'); // Set the URL & reload the page taking to microsoft login
                }}
                padding={[0, 2]}
              />
            )}
          </StyledHeaderMenu>
          {storeIdToSwitchTo && (
            <SwitchStoreModal
              isOpen={true}
              changedStoreId={storeIdToSwitchTo}
              redirectUrl={redirectUrl}
              onClose={() => setStoreIdToSwitchTo('')}
            />
          )}

          {isNavigateModalOpen && (
            <NavigateToSwitchStoreModal
              isOpen={true}
              numberOfStores={MAX_STORES_IN_DROPDOWN}
              onClose={() => setIsNavigateModalOpen(false)}
            />
          )}

          {isSelectRoleModalOpen && (
            <SelectRoleModal
              isOpen={true}
              onClose={() => setIsSelectRoleModalOpen(false)}
              onRoleChangeSuccess={onRoleChangeSuccess}
            />
          )}

          {showLogoutModal && <LogoutModal isOpen={true} onClose={() => setShowLogoutModal(false)} />}
        </StyledWrapper>
      </StyledHeaderContainer>
    );
  },
);
