import styled from 'styled-components';

export const StyledItem = styled.img`
  max-height: min(6vh, 6vw);
  padding-right: ${({ theme: { spacing } }) => spacing(4)};
  max-width: fit-content;
`;

export const StyledAnimationWrapper = styled.div`
  margin-top: 20vh;
`;

export const BadgeWrapper = styled.div<{ isMobileView: boolean }>`
  display: flex;
  justify-content: flex-start;
  flex-direction: ${({ isMobileView }) => (isMobileView ? 'column' : 'row')};
  align-items: ${({ isMobileView }) => (isMobileView ? 'flex-start' : 'center')};
`;
