import { VendorMapping } from '../../../constants/vendorTypes';
import { ReactComponent as AllWidgets } from './assets/AllWidgets.svg';
import { ReactComponent as Arrow } from './assets/Arrow.svg';
import { ReactComponent as AssetManagement } from './assets/AssetManagement.svg';
import { ReactComponent as Bag } from './assets/Bag.svg';
import { ReactComponent as Bell } from './assets/Bell.svg';
import { ReactComponent as Box } from './assets/Box.svg';
import { ReactComponent as Calendar } from './assets/Calendar.svg';
import { ReactComponent as Chat } from './assets/Chat.svg';
import { ReactComponent as ChatBot } from './assets/ChatBot.svg';
import { ReactComponent as ChatBubble } from './assets/ChatBubble.svg';
import { ReactComponent as CheckMark } from './assets/Check-mark.svg';
import { ReactComponent as CheckMarkCircle } from './assets/CheckMarkCircle.svg';
import { ReactComponent as CheckboxIndeterminate } from './assets/Checkbox-indeterminate.svg';
import { ReactComponent as Clock } from './assets/Clock.svg';
import { ReactComponent as Close } from './assets/Close.svg';
import { ReactComponent as Conversion } from './assets/Conversion.svg';
import { ReactComponent as CustomerBaseValue } from './assets/CustomerBaseValue.svg';
import { ReactComponent as Delivery } from './assets/Delivery.svg';
import { ReactComponent as DeviceManagement } from './assets/DeviceManagement.svg';
import { ReactComponent as Dollar } from './assets/Dollar.svg';
import { ReactComponent as Download } from './assets/Download.svg';
import { ReactComponent as Dropdown } from './assets/Dropdown.svg';
import { ReactComponent as Edit } from './assets/Edit.svg';
import { ReactComponent as Feedback } from './assets/Feedback.svg';
import { ReactComponent as Filter } from './assets/Filter.svg';
import { ReactComponent as Growth } from './assets/Growth.svg';
import { ReactComponent as MemberTransaction } from './assets/HM-MemberTransactionLogo.svg';
import { ReactComponent as NewMember } from './assets/HM-NewMemberLogo.svg';
import { ReactComponent as HM } from './assets/HM-logo.svg';
import { ReactComponent as Heart } from './assets/Heart.svg';
import { ReactComponent as Home } from './assets/Home.svg';
import { ReactComponent as Info } from './assets/Info.svg';
import { ReactComponent as List } from './assets/List.svg';
import { ReactComponent as Lock } from './assets/Lock.svg';
import { ReactComponent as Menu } from './assets/Menu.svg';
import { ReactComponent as Mobile } from './assets/Mobile.svg';
import { ReactComponent as Moon } from './assets/Moon.svg';
import { ReactComponent as More } from './assets/More.svg';
import { ReactComponent as Plus } from './assets/Plus.svg';
import { ReactComponent as ProductCategory } from './assets/ProductCategory.svg';
import { ReactComponent as Profile } from './assets/Profile.svg';
import { ReactComponent as ProfileSettings } from './assets/ProfileSettings.svg';
import { ReactComponent as ProfitAndLoss } from './assets/ProfitAndLoss.svg';
import { ReactComponent as RadiobuttonActive } from './assets/Radiobutton-active.svg';
import { ReactComponent as Radiobutton } from './assets/Radiobutton.svg';
import { ReactComponent as Refresh } from './assets/Refresh.svg';
import { ReactComponent as RegisteredReceipt } from './assets/RegisteredReceipt.svg';
import { ReactComponent as Returns } from './assets/Returns.svg';
import { ReactComponent as SalesEfficiency } from './assets/SalesEfficiency.svg';
import { ReactComponent as Signout } from './assets/Signout.svg';
import { ReactComponent as Star } from './assets/Star.svg';
import { ReactComponent as StarFilled } from './assets/StarFilled.svg';
import { ReactComponent as Store } from './assets/Store.svg';
import { ReactComponent as Sun } from './assets/Sun.svg';
import { ReactComponent as Switch } from './assets/Switch.svg';
import { ReactComponent as Tag } from './assets/Tag.svg';
import { ReactComponent as Target } from './assets/Target.svg';
import { ReactComponent as Tender } from './assets/Tender.svg';
import { ReactComponent as TransactionSearch } from './assets/TransactionSearch.svg';
import { ReactComponent as Transactions } from './assets/Transactions.svg';
import { ReactComponent as TrashBin } from './assets/Trash.svg';
import { ReactComponent as Unlock } from './assets/Unlock.svg';
import { ReactComponent as ValidAction } from './assets/Validaction.svg';
import { ReactComponent as Warning } from './assets/Warning.svg';
import { ReactComponent as Afound } from './assets/brandIcons/Afound.svg';
import { ReactComponent as Arket } from './assets/brandIcons/Arket.svg';
import { ReactComponent as Cos } from './assets/brandIcons/COS.svg';
import { ReactComponent as CheapMonday } from './assets/brandIcons/CheapMonday.svg';
import { ReactComponent as Monki } from './assets/brandIcons/Monki.svg';
import { ReactComponent as OtherStories } from './assets/brandIcons/OtherStories.svg';
import { ReactComponent as Pleat } from './assets/brandIcons/Pleat.svg';
import { ReactComponent as Weekday } from './assets/brandIcons/Weekday.svg';
import { ReactComponent as Confused } from './assets/faces/Confused.svg';
import { ReactComponent as Happy } from './assets/faces/Happy.svg';
import { ReactComponent as Sad } from './assets/faces/Sad.svg';
import { ReactComponent as Settings } from './assets/settings.svg';
import { ReactComponent as Alipay } from './assets/vendors/AliPay.svg';
import { ReactComponent as AmericanExpress } from './assets/vendors/AmericanExpress.svg';
import { ReactComponent as ApplePay } from './assets/vendors/ApplePay.svg';
import { ReactComponent as Diners } from './assets/vendors/Diners.svg';
import { ReactComponent as EuroCard } from './assets/vendors/EuroCard.svg';
import { ReactComponent as GiftCard } from './assets/vendors/GiftCard.svg';
import { ReactComponent as Klarna } from './assets/vendors/Klarna.svg';
import { ReactComponent as Maestro } from './assets/vendors/Maestro.svg';
import { ReactComponent as Mastercard } from './assets/vendors/Mastercard.svg';
import { ReactComponent as Paypal } from './assets/vendors/Paypal.svg';
import { ReactComponent as Rupay } from './assets/vendors/Rupay.svg';
import { ReactComponent as Swish } from './assets/vendors/Swish.svg';
import { ReactComponent as UPI } from './assets/vendors/UPI.svg';
import { ReactComponent as Visa } from './assets/vendors/Visa.svg';

export enum Icons {
  ARROW = 'Arrow',
  BELL = 'Bell',
  CHECK_MARK = 'CheckMark',
  CHECKBOX_INDETERMINATE = 'CheckboxIndeterminate',
  CONVERSION = 'Conversion',
  CLOSE = 'Close',
  DEVICE_MANAGEMENT = 'DeviceManagement',
  DROPDOWN = 'Dropdown',
  EDIT = 'Edit',
  FILTER = 'Filter',
  HEART = 'Heart',
  HM_LOGO = 'HM',
  ARKET = 'Arket',
  COS = 'Cos',
  WEEKDAY = 'Weekday',
  MONKI = 'Monki',
  OTHERSTORIES = 'OtherStories',
  CHEAPMONDAY = 'CheapMonday',
  PLEAT = 'Pleat',
  AFOUND = 'Afound',
  HOME = 'Home',
  INFO = 'Info',
  PLUS = 'Plus',
  SETTINGS = 'Settings',
  REFRESH = 'Refresh',
  RADIOBUTTON = 'RadioButton',
  RADIOBUTTON_ACTIVE = 'RadioButtonActive',
  CHECK_MARK_CIRCLE = 'CheckMarkCircle',
  PROFILE = 'Profile',
  STAR = 'Star',
  STAR_FILLED = 'StarFilled',
  SWITCH = 'Switch',
  TENDER = 'Tender',
  TRANSACTION_SEARCH = 'TransactionSearch',
  TRASHBIN = 'TrashBin',
  WARNING = 'Warning',
  BAG = 'Bag',
  REGISTERED_RECEIPT = 'RegisteredReceipt',
  ASSET_MANAGEMENT = 'AssetManagement',
  RETURNS = 'Returns',
  CLOCK = 'Clock',
  MENU = 'Menu',
  STORE = 'Store',
  SIGNOUT = 'Signout',
  CHATBOT = 'ChatBot',
  CHATBUBBLE = 'ChatBubble',
  CHAT = 'Chat',
  DELIVERY = 'Delivery',
  MEMBERTRANSACTION = 'MemberTransaction',
  NEWMEMBER = 'NewMember',
  ALLWIDGETS = 'AllWidgets',
  CUSTOMERVALUE = 'CustomerBaseValue',
  SALESEFFICIENCY = 'SalesEfficiency',
  TRANSACTIONS = 'Transactions',
  DOWNLOAD = 'Download',
  VALIDACTION = 'ValidAction',
  GROWTH = 'Growth',
  DOLLAR = 'Dollar',
  MORE = 'More',
  TARGET = 'Target',
  MOON = 'Moon',
  SUN = 'Sun',
  LOCK = 'Lock',
  UNLOCK = 'Unlock',
  HAPPY = 'Happy',
  SAD = 'Sad',
  CONFUSED = 'Confused',
  LIST = 'List',
  FEEDBACK = 'Feedback',
  PRODUCTCATEGORY = 'ProductCategory',
  BOX = 'Box',
  PROFITANDLOSS = 'ProfitAndLoss',
  TAG = 'Tag',
  CALENDAR = 'Calendar',
  MOBILE = 'Mobile',
  PROFILE_SETTINGS = 'ProfileSettings',
}

export default {
  [Icons.PLUS]: <Plus />,
  [Icons.SETTINGS]: <Settings />,
  [Icons.REFRESH]: <Refresh />,
  [Icons.INFO]: <Info />,
  [Icons.RADIOBUTTON]: <Radiobutton />,
  [Icons.RADIOBUTTON_ACTIVE]: <RadiobuttonActive />,
  [Icons.BELL]: <Bell />,
  [Icons.HM_LOGO]: <HM />,
  [Icons.ARKET]: <Arket />,
  [Icons.COS]: <Cos />,
  [Icons.WEEKDAY]: <Weekday />,
  [Icons.MONKI]: <Monki />,
  [Icons.OTHERSTORIES]: <OtherStories />,
  [Icons.CHEAPMONDAY]: <CheapMonday />,
  [Icons.PLEAT]: <Pleat />,
  [Icons.AFOUND]: <Afound />,
  [Icons.DROPDOWN]: <Dropdown />,
  [Icons.CLOSE]: <Close />,
  [Icons.HEART]: <Heart />,
  [Icons.CHECK_MARK_CIRCLE]: <CheckMarkCircle />,
  [Icons.WARNING]: <Warning />,
  [Icons.CHECK_MARK]: <CheckMark />,
  [Icons.EDIT]: <Edit />,
  [Icons.CHECKBOX_INDETERMINATE]: <CheckboxIndeterminate />,
  [Icons.TRASHBIN]: <TrashBin />,
  [Icons.PROFILE]: <Profile />,
  [Icons.DEVICE_MANAGEMENT]: <DeviceManagement />,
  [Icons.TRANSACTION_SEARCH]: <TransactionSearch />,
  [Icons.FILTER]: <Filter />,
  [Icons.ARROW]: <Arrow />,
  [Icons.SWITCH]: <Switch />,
  [Icons.HOME]: <Home />,
  [Icons.TENDER]: <Tender />,
  [Icons.STAR]: <Star />,
  [Icons.STAR_FILLED]: <StarFilled />,
  [Icons.CONVERSION]: <Conversion />,
  [Icons.BAG]: <Bag />,
  [Icons.ASSET_MANAGEMENT]: <AssetManagement />,
  [Icons.REGISTERED_RECEIPT]: <RegisteredReceipt />,
  [Icons.RETURNS]: <Returns />,
  [Icons.CLOCK]: <Clock />,
  [Icons.MENU]: <Menu />,
  [Icons.STORE]: <Store />,
  [Icons.SIGNOUT]: <Signout />,
  [Icons.CHATBOT]: <ChatBot />,
  [Icons.CHATBUBBLE]: <ChatBubble />,
  [Icons.CHAT]: <Chat />,
  [Icons.DELIVERY]: <Delivery />,
  [Icons.MEMBERTRANSACTION]: <MemberTransaction />,
  [Icons.NEWMEMBER]: <NewMember />,
  [Icons.ALLWIDGETS]: <AllWidgets />,
  [Icons.CUSTOMERVALUE]: <CustomerBaseValue />,
  [Icons.SALESEFFICIENCY]: <SalesEfficiency />,
  [Icons.TRANSACTIONS]: <Transactions />,
  [Icons.DOWNLOAD]: <Download />,
  [Icons.VALIDACTION]: <ValidAction />,
  [Icons.GROWTH]: <Growth />,
  [Icons.DOLLAR]: <Dollar />,
  [Icons.MORE]: <More />,
  [Icons.TARGET]: <Target />,
  [Icons.MOON]: <Moon />,
  [Icons.SUN]: <Sun />,
  [Icons.LOCK]: <Lock />,
  [Icons.UNLOCK]: <Unlock />,
  [Icons.HAPPY]: <Happy />,
  [Icons.SAD]: <Sad />,
  [Icons.LIST]: <List />,
  [Icons.CONFUSED]: <Confused />,
  [Icons.FEEDBACK]: <Feedback />,
  [Icons.PRODUCTCATEGORY]: <ProductCategory />,
  [Icons.BOX]: <Box />,
  [Icons.PROFITANDLOSS]: <ProfitAndLoss />,
  [Icons.TAG]: <Tag />,
  [Icons.CALENDAR]: <Calendar />,
  [Icons.MOBILE]: <Mobile />,
  [Icons.PROFILE_SETTINGS]: <ProfileSettings />,
  [VendorMapping.Maestro]: <Maestro />,
  [VendorMapping.Paypal]: <Paypal />,
  [VendorMapping.Mc]: <Mastercard />,
  [VendorMapping.MASTERCARD]: <Mastercard />,
  [VendorMapping.Klarna]: <Klarna />,
  [VendorMapping.VISA]: <Visa />,
  [VendorMapping.Visa]: <Visa />,
  [VendorMapping.UPI]: <UPI />,
  [VendorMapping.RUPAY]: <Rupay />,
  [VendorMapping.Alipay]: <Alipay />,
  [VendorMapping.Applepay]: <ApplePay />,
  [VendorMapping.Eurocard]: <EuroCard />,
  [VendorMapping.Amex]: <AmericanExpress />,
  [VendorMapping.DINERS]: <Diners />,
  [VendorMapping.Swish]: <Swish />, // check correct icon for swish
  [VendorMapping.CA]: <TransactionSearch />, // check correct icon for cash
  [VendorMapping.GiftCard]: <GiftCard />, // check correct icon for cash
};
