/* istanbul ignore file */
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { LicenseInfo } from '@mui/x-license';
import { createInstance, enums, OptimizelyProvider } from '@optimizely/react-sdk';
import React, { FC, StrictMode, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import styled from 'styled-components';
import { App } from './App';
import { AppNonSignin } from './AppNonSignin';
import { msalConfig, msalScopes } from './constants/msalConfig';
import { GlobalProvider } from './global-state/context';
import { EventProvider } from './global-state/eventContext';
import { ThemeProvider } from './global-state/themeContext';
import { TranslationProvider } from './global-state/translationContext';
import { GlobalStyle } from './globalStyles';
import { Icons } from './stories/atoms';
import { Animation, Animations } from './stories/molecules';
import { commonTranslations, errors } from './translations';

const msalInstance = new PublicClientApplication(msalConfig);

const licensekey: string = process.env.REACT_APP_MUIX_LICENSE_KEY ?? '';
LicenseInfo.setLicenseKey(licensekey);

export const optimizely = createInstance({
  sdkKey: process.env.REACT_APP_OPTIMIZELY_SDK_KEY,
  datafileOptions: {
    updateInterval: process.env.NODE_ENV === 'production' ? 600000 : 10000, // 10min for prod, 10s for dev
    autoUpdate: true,
  },
  eventBatchSize: 10,
  eventFlushInterval: 1000,
  logLevel: process.env.NODE_ENV === 'production' ? enums.LOG_LEVEL.ERROR : enums.LOG_LEVEL.WARNING,
});

export const AppWrapper: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation();

  const Loading = () => (
    <AnimationWrapper>
      <Animation
        title={t(commonTranslations.loading.key, commonTranslations.loading.defaultValue)}
        animation={Animations.LOADING}
      />
    </AnimationWrapper>
  );

  const Error = () => (
    <AnimationWrapper>
      <Animation
        title={t(errors.applicationUnavailable.key, errors.applicationUnavailable.defaultValue)}
        subtitle={t(errors.tryAgain.key, errors.tryAgain.defaultValue)}
        animation={Animations.SAD}
        button={{
          label: t(commonTranslations.tryAgain.key, commonTranslations.tryAgain.defaultValue),
          onClick: () => window.location.reload(),
          buttonContentProps: {
            iconOptions: {
              icon: Icons.REFRESH,
              color: 'white',
            },
          },
        }}
      />
    </AnimationWrapper>
  );
  const storeIdParam = new URLSearchParams(window.location.search).get('storeId');
  const key = new URLSearchParams(window.location.search).get('key');
  const showNonSignInView = storeIdParam && key && window.location.pathname.includes('/home');

  return (
    <StrictMode>
      <GlobalProvider>
        <ThemeProvider>
          <EventProvider>
            <TranslationProvider>
              {showNonSignInView ? (
                <OptimizelyProvider optimizely={optimizely} user={{ id: 'default_user' }}>
                  <Suspense fallback={<Loading />}>
                    <GlobalStyle />
                    <Router>
                      <AppNonSignin />
                    </Router>
                  </Suspense>
                </OptimizelyProvider>
              ) : (
                <MsalProvider instance={msalInstance}>
                  <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={msalScopes}
                    errorComponent={() => <Error />}
                    loadingComponent={() => <Loading />}
                  >
                    <OptimizelyProvider optimizely={optimizely} user={{ id: 'default_user' }}>
                      <Suspense fallback={<Loading />}>
                        <GlobalStyle />
                        <Router>
                          <App />
                        </Router>
                      </Suspense>
                    </OptimizelyProvider>
                  </MsalAuthenticationTemplate>
                </MsalProvider>
              )}
            </TranslationProvider>
          </EventProvider>
        </ThemeProvider>
      </GlobalProvider>
    </StrictMode>
  );
};

const AnimationWrapper = styled.div`
  height: 100vh;
`;
