import { useMediaQuery } from '@mui/material';
import { format, subMonths } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ProfitLossResponse,
  ReportItem,
  useAsyncAction,
  useDynamicTranslation,
  useStores,
  useUser,
} from '../../hooks';
import { Container, Drawer, Icon, Icons, Typography } from '../../stories/atoms';
import {
  ActionButton,
  Animation,
  Animations,
  Banner,
  BannerItemsType,
  DropdownMenu,
} from '../../stories/molecules';
import { Page } from '../../stories/organisms';
import { muiTheme } from '../../theme';
import { commonTranslations, homeTranslations, profitAndLossTransalations } from '../../translations';
import { getUniqueId, isFailureResponse } from '../../utils';
import { StyledButton, StyledDivider, StyledExpandButton, StyledPLCardContainer } from './ProfitAndLoss.styles';
import { PLCardContent, ValueContainer } from './plCardContent/PLCardContent';
import { PLExpandCard } from './plExpandCard/PLExpandCard';
import PLFilter from './plFilter/PLFilter';
import { generateCardTitle, profitLossPropertyToValueMap } from './util';

export const ProfitAndLoss: FC<unknown> = observer(() => {
  const { t } = useTranslation();
  const { common } = homeTranslations;
  const { close } = commonTranslations;

  const {
    get: { profile },
  } = useUser();

  const {
    error,
    headerText,
    loading: loadingTranslation,
    monthDropdown,
    mobileBannerText,
  } = profitAndLossTransalations;

  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));
  const language = profile?.settings?.defaultLanguage ?? 'en-GB';

  const { fetchProfitLossData } = useStores();
  const { fetchDataTranslations } = useDynamicTranslation();

  const {
    result: profitLossDetails,
    isLoading: profitLossLoading,
    isError: profitLossError,
    triggerAction: triggerFetchProfitLossDetails,
  } = useAsyncAction<ProfitLossResponse>();

  const [bannerItems, setBannerItems] = useState<BannerItemsType[]>([]);
  const [expandData, setExpandData] = useState<Record<string, boolean>>({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerDetails, setDrawerDetails] = useState<ReportItem | null>(null);
  const [selectedMonths, setSelectedMonths] = useState<string[]>([]);
  const [profitLossResponse, setProfitLossResponse] = useState<ProfitLossResponse | null>(profitLossDetails);

  const isAnyExpanded = Object.values(expandData).some(Boolean);
  const profitLossValues = drawerDetails && profitLossPropertyToValueMap(drawerDetails);

  // Fetch profit and loss data
  const fetchProfitLoss = async () => {
    const response = await fetchProfitLossData();

    if (isFailureResponse(response)) {
      throw new Error();
    }
    setProfitLossResponse(response.data);
    return response.data;
  };

  // Handle accordion expand
  const handleAccordionExpand = (value: { [key in string]: boolean }) => {
    setExpandData((prev) => ({
      ...prev,
      ...value,
    }));
  };

  const getIsExpanded = (key: string) => {
    return expandData[key] ?? false;
  };

  // Handle expand all button
  const handleAllExpanded = () => {
    if (!profitLossDetails) return;

    const { detailData } = profitLossDetails;
    const updatedExpandData: Record<string, boolean> = {};

    detailData.forEach((item) => {
      updatedExpandData[item.costName] = true;
    });

    setExpandData(updatedExpandData);
  };

  const handleAllCollapse = () => {
    setExpandData({});
  };

  // Handle details drawer
  const onViewDetailsClick = (details: ReportItem) => {
    setIsDrawerOpen(true);
    setDrawerDetails(details);
  };

  // Get financial year months
  const getFinancialYearMonths = () => {
    const currentDate = new Date();
    const monthArray = [];

    // Determine the financial year
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const financialYear =
      currentMonth === 11 ? `${currentYear}-${currentYear + 1}` : `${currentYear - 1}-${currentYear}`;

    // Start from last year's December
    for (let i = 1; i <= 12; i++) {
      const date = subMonths(currentDate, i);
      const monthName = format(date, 'MMMM');
      const year = format(date, 'yyyy');
      const capitalizedMonth = monthName.charAt(0).toUpperCase() + monthName.slice(1);

      monthArray.push(`${capitalizedMonth} ${year}`);

      // Break the loop when we reach last year's December
      if (format(date, 'M') === '12') break;
    }

    return { monthItems: monthArray, financialYear: financialYear }; // Reverse to get the months in chronological order
  };

  const handleDropdownSelect = (selectedItems: string[]) => {
    // TODO: call API on select
    setSelectedMonths(selectedItems);
  };

  const fetchTranslatedData = async () => {
    if (!profitLossDetails) return;
    const paths = ['summaryData[*].costName', 'detailData[*].costName', 'detailData[*].reports[*].costName'];
    fetchDataTranslations(profitLossDetails, paths, language);
  };

  // Trigger initial profit and loss data fetch and set banner items
  useEffect(() => {
    triggerFetchProfitLossDetails(fetchProfitLoss);
    if (isMobile) {
      setBannerItems([
        {
          id: getUniqueId(),
          label: t(mobileBannerText.key, mobileBannerText.defaultValue),
          type: 'info',
          actionButton: ActionButton.Close,
        },
      ]);
    }
  }, []);

  useEffect(() => {
    fetchTranslatedData();
  }, [profitLossDetails, language]);

  return (
    <Page
      header={
        <Container direction="horizontal" space="between" padding={isMobile ? [2, 4] : [2, 8]}>
          {isMobile && <Banner items={bannerItems} onChange={() => null} />}
          <DropdownMenu
            data-testid="select-month-dropdown"
            dropdownLabel={t(monthDropdown.key, monthDropdown.defaultValue)}
            menuItems={getFinancialYearMonths().monthItems.map((item) => ({
              key: item,
              item: <Typography margin={[0, 2, 0]}>{item}</Typography>,
            }))}
            minWidth={'0px'}
            multiSelect
            checkbox
            isCompact
            initialSelected={selectedMonths}
            onSelect={(selectedItems) => {
              handleDropdownSelect(selectedItems);
            }}
            isOnlyIcon
            allowTooltip
          />

          <StyledExpandButton
            data-testid="expand-button"
            label={
              isAnyExpanded
                ? t(common.collapseAll.key, common.collapseAll.defaultValue)
                : t(common.expandAll.key, common.expandAll.defaultValue)
            }
            shouldRotate={isAnyExpanded ? false : true}
            iconOptions={{
              icon: isAnyExpanded ? Icons.CHECKBOX_INDETERMINATE : Icons.CLOSE,
              size: 'small',
              rotate: 'up',
            }}
            textOptions={{
              type: 'button',
            }}
            onClick={isAnyExpanded ? handleAllCollapse : handleAllExpanded}
            padding={[0, 0, 0, 2]}
          />
        </Container>
      }
      body={
        <div>
          {isMobile && drawerDetails && (
            <Drawer
              variant="temporary"
              position="bottom"
              isOpen={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}
              hasBorderRadius
            >
              <Typography type="h3" padding={[6, 8, 0, 6]}>
                {generateCardTitle(drawerDetails.departmentID, drawerDetails.costName, false)}
              </Typography>

              <Container wrap="nowrap" padding={[2, 8, 4]}>
                {profitLossValues &&
                  profitLossValues.map((item, index) => (
                    <React.Fragment key={item.key}>
                      <ValueContainer label={item.key} value={item.value?.toString()} />
                      {index < profitLossValues.length - 1 && <StyledDivider margin={[1, 0]} />}
                    </React.Fragment>
                  ))}
                <StyledButton
                  label={t(close.key, close.defaultValue)}
                  textOptions={{
                    type: 'button',
                    color: 'white',
                  }}
                  margin={[3, 0, 0]}
                  hasRadius
                  onClick={() => setIsDrawerOpen(false)}
                />
              </Container>
            </Drawer>
          )}
          {profitLossLoading && (
            <Animation
              title={t(loadingTranslation.key, loadingTranslation.defaultValue)}
              animation={Animations.LOADING}
            />
          )}

          {!profitLossLoading && profitLossError && (
            <Animation
              title={t(error.errorTitle.key, error.errorTitle.defaultValue)}
              subtitle={t(error.errorSubtitle.key, error.errorSubtitle.defaultValue)}
              animation={Animations.SAD}
            />
          )}

          {!profitLossLoading && !profitLossError && profitLossResponse && (
            <Container
              wrap="nowrap"
              padding={isMobile ? [3, 4] : [4, 8]}
              data-testid="profit-and-loss-page-wrapper"
            >
              <Container wrap="nowrap" space="between" margin={[0, 0, 3, 0]}>
                <Typography gutterBottom>
                  {t(headerText.key, {
                    defaultValue: headerText.defaultValue,
                    reportPeriod: getFinancialYearMonths().financialYear,
                  })}
                </Typography>
                {selectedMonths.length > 0 && (
                  <PLFilter
                    selectedMonths={selectedMonths}
                    onClose={(selectedMonth) => {
                      setSelectedMonths(selectedMonths.filter((filterMonth) => filterMonth !== selectedMonth));
                    }}
                    onReset={() => setSelectedMonths([])}
                  />
                )}
              </Container>

              <StyledPLCardContainer
                padding={isMobile ? [3, 2] : [4, 3]}
                margin={[0, 0, 4, 0]}
                isParent
                position="bottom"
              >
                <Container position="center" wrap="nowrap" direction="horizontal" width="100%">
                  <Icon
                    icon={Icons.TAG}
                    size={isMobile ? 'small' : 'medium'}
                    margin={isMobile ? [0, 2] : [1, 2]}
                  />
                  <PLCardContent
                    reportDetails={profitLossResponse.summaryData[0]}
                    isParent
                    onViewDetailsClick={onViewDetailsClick}
                  />
                </Container>
                <StyledDivider />
                <Container position="center" wrap="nowrap" direction="horizontal" width="100%">
                  <Icon icon={Icons.TAG} size={isMobile ? 'small' : 'medium'} margin={[1, 2]} />
                  <PLCardContent
                    reportDetails={profitLossResponse.summaryData[1]}
                    isParent
                    onViewDetailsClick={onViewDetailsClick}
                  />
                </Container>
              </StyledPLCardContainer>

              {profitLossResponse.detailData.map((item) => (
                <PLExpandCard
                  key={item.costName}
                  cardKey={item.costName}
                  cardContent={item}
                  isParent={true}
                  isExpanded={getIsExpanded(item.costName)}
                  onExpand={handleAccordionExpand}
                  onViewDetailsClick={onViewDetailsClick}
                />
              ))}
            </Container>
          )}
        </div>
      }
    />
  );
});
