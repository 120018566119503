import { useEffect, useState } from 'react';
import { Kpi, Store, StoreDetails } from '../global-state/types';
import { useUser } from './useController';

const defaultStoreInformation: StoreInformation = {
  currencyCode: '',
  areaCode: '',
  timezone: '',
  kpis: [],
  storeName: '',
};

type StoreInformation = {
  currencyCode: string;
  areaCode: string;
  timezone: string;
  kpis: Kpi[];
  storeName: string;
};

export const useStoreInformation = (): StoreInformation => {
  const {
    get: { currentStoreId, stores, storesDetails },
  } = useUser();

  const getStoreInformation = (newStores: Store[], newStoresDetails: StoreDetails[]): StoreInformation => {
    const currentStore = newStores.find((store) => store.storeId === currentStoreId);
    const currentStoreDetails = newStoresDetails.find((storeDetails) => storeDetails.storeId === currentStoreId);

    if (!currentStore) return defaultStoreInformation;

    return {
      currencyCode: currentStore.currency || '',
      areaCode: currentStore.hmRegion || '',
      timezone: currentStoreDetails ? currentStoreDetails.timezone : '',
      kpis: currentStore.kpis || [],
      storeName: currentStore.popularName || '',
    };
  };

  const [storeInformation, setStoreInformation] = useState<StoreInformation>(
    getStoreInformation(stores, storesDetails),
  );

  useEffect(() => {
    setStoreInformation(getStoreInformation(stores, storesDetails));
  }, [currentStoreId, stores]);

  return storeInformation;
};
