import { useState } from 'react';

type State<T> = {
  isLoading: boolean;
  isError: boolean;
  result: T | null;
};

type AsyncAction<T> = () => Promise<T>;

export const useAsyncAction = <T>() => {
  const [state, setState] = useState<State<T>>({
    isLoading: false,
    isError: false,
    result: null,
  });

  const triggerAction = async (action: AsyncAction<T>) => {
    try {
      setState({ isLoading: true, isError: false, result: null });
      const result = await action();
      setState({ isLoading: false, isError: false, result });
    } catch (err) {
      setState({ isLoading: false, isError: true, result: null });
    }
  };

  return { ...state, triggerAction };
};
