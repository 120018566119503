// TODO: Remove this when fixing the unit tests - BTCOBUI-1952
/* istanbul ignore file */
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Fade, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GoogleMap, MarkerF, useLoadScript } from '@react-google-maps/api';
import { useDecision } from '@optimizely/react-sdk';
import LocationIcon from '../../stories/atoms/icon/assets/Location.svg';
import LocationActiveIcon from '../../stories/atoms/icon/assets/LocationActive.svg';
import LocationActiveHM from '../../stories/atoms/icon/assets/LocationActive_HM.svg';
import LocationHM from '../../stories/atoms/icon/assets/Location_HM.svg';
import { Container, Icon, Icons, SkeletonLoader, Typography } from '../../stories/atoms';
import { Button, Animation } from '../../stories/molecules';
import { useLocalStorage, useUser } from '../../hooks';
import { LocalStorageKey } from '../../constants/localStorageKey';
import { commonTranslations, selectStoreTranslation } from '../../translations';
import { Search } from '../../stories/molecules/search/Search';
import { StoreDetails } from '../../global-state/types';
import { muiTheme } from '../../theme';
import {
  StyledScreenContainer,
  StyledLogoContainer,
  StyledStoreCard,
  StyledAnimationContainer,
  StyledContainer,
  StyledMapContainer,
  StyledStoreDetails,
  StyledSearchContainer,
  StyledWrapper,
} from './SelectStore.styles';

export const SelectStore: FC = observer(() => {
  const [selectedStoreId, setSelectedStoreId] = useState<string>();
  const [selectedStoreInfo, setSelectedStoreInfo] = useState<{
    name: string;
    address: string;
    storeId: string;
    status: string;
  } | null>(null);
  const [mapReference, setMapReference] = useState<google.maps.Map | undefined>();
  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);

  const {
    get: { storesDetails, userId, previousStoreId },
    set,
  } = useUser();
  const { t } = useTranslation();
  const [storeSelectionScreen] = useDecision('store_selection_screen', {}, { overrideUserId: userId });
  const { setItem } = useLocalStorage();
  const navigate = useNavigate();
  const isMobileView = useMediaQuery(muiTheme.breakpoints.down('tabletLandscape'));
  const ZOOM_LEVEL = 12;

  const {
    header,
    subHeader,
    storeIdText,
    findStore,
    storeSelectionText,
    selectStore,
    searchStoreLabel,
    storeStatus,
  } = selectStoreTranslation;

  const { confirm } = commonTranslations;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  });

  let storeMarkers: { lat: number; lng: number; storeId: string; corporateBrandId: number }[] = [];
  if (storesDetails) {
    storeMarkers = storesDetails.map(({ longitude, latitude, storeId, corporateBrandId }) => ({
      lat: Number(latitude),
      lng: Number(longitude),
      storeId,
      corporateBrandId,
    }));
  }

  const center = useMemo(() => {
    if (storeMarkers.length === 0) {
      return { lat: 0, lng: 0 };
    }

    const totalLatitude = storeMarkers.reduce((sum, marker) => sum + marker.lat, 0);
    const totalLongitude = storeMarkers.reduce((sum, marker) => sum + marker.lng, 0);

    const avgLatitude = totalLatitude / storeMarkers.length;
    const avgLongitude = totalLongitude / storeMarkers.length;

    return { lat: avgLatitude, lng: avgLongitude };
  }, [storeMarkers]);

  const fitBounds = () => {
    if (mapInstance && storeMarkers.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      storeMarkers.forEach(({ lat, lng }) => bounds.extend(new google.maps.LatLng(lat, lng)));
      mapInstance.fitBounds(bounds);
      setMapReference(mapInstance);
    }
  };

  const handleMapLoad = (map: google.maps.Map) => {
    setMapInstance(map);
  };

  useEffect(() => {
    fitBounds();
  }, [storeMarkers, mapInstance]);

  const setSelectedMapStore = (store: StoreDetails) => {
    if (store) {
      setSelectedStoreInfo({
        name: store.name,
        address: store.address,
        storeId: store.storeId,
        status: store.status,
      });
      setSelectedStoreId(store.storeId);
    }
  };

  const handleMarkerClick = async (storeId: string, latitude: number, longitude: number) => {
    const selectedStore = storesDetails?.find((storeDetails) => storeDetails.storeId === storeId);
    if (!selectedStore) return;
    await setSelectedMapStore(selectedStore);
    mapReference?.setZoom(ZOOM_LEVEL);
    mapReference?.panTo({ lat: latitude, lng: longitude });
  };

  const handleSearch = (selectedValue: StoreDetails | null) => {
    if (selectedValue) {
      setSelectedMapStore(selectedValue);
    } else {
      setSelectedStoreInfo(null);
    }
  };

  const onStoreSelect = () => {
    setItem(LocalStorageKey.SelectedStore, selectedStoreId as string);
    set({ currentStoreId: selectedStoreId });
    navigate('/home');
  };

  const customFilterFunction = (options: StoreDetails[], inputValue: string) => {
    return options.filter(
      (option) =>
        option.storeId.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.name.toLowerCase().includes(inputValue.toLowerCase()),
    );
  };

  const getLocationMarkerIcon = (brandId: number, storeId: string) => {
    const isActive = selectedStoreId === storeId;
    return brandId === 0
      ? isActive
        ? LocationActiveHM
        : LocationHM
      : isActive
      ? LocationActiveIcon
      : LocationIcon;
  };

  const StoreInformation = () => {
    return (
      selectedStoreInfo && (
        <StyledStoreDetails
          data-testid={`selected-store-details-${isMobileView ? 'mobile' : 'desktop'}`}
          isMobile={isMobileView}
        >
          <div>
            <Typography type="body3">{selectedStoreInfo?.name}</Typography>
            <Typography>{selectedStoreInfo?.address}</Typography>
            <Typography>
              {t(storeIdText.key, storeIdText.defaultValue)} - {selectedStoreInfo?.storeId}
            </Typography>
            {selectedStoreInfo?.status !== '' && (
              <Container direction="horizontal">
                <Typography>{t(storeStatus.key, storeStatus.defaultValue)} - </Typography>
                <Typography gutterBottom color={selectedStoreInfo?.status === 'OPEN' ? 'primary' : 'red'}>
                  {selectedStoreInfo?.status}
                </Typography>
              </Container>
            )}
          </div>
          <Button
            label={t(selectStore.key, selectStore.defaultValue)}
            disabled={!selectedStoreId}
            onClick={onStoreSelect}
          />
        </StyledStoreDetails>
      )
    );
  };

  const renderMobileView = () => (
    <StyledWrapper>
      <StyledSearchContainer data-testid="search-container-mobile">
        <Typography type="h4"> {t(findStore.key, findStore.defaultValue)}</Typography>
        <Typography gutterBottom>
          {t(storeSelectionText.key, storeSelectionText.defaultValue, {
            numberOfStores: storeMarkers?.length,
          })}
        </Typography>
        <Search
          fieldLabel={t(searchStoreLabel.key, searchStoreLabel.defaultValue)}
          options={storesDetails || []}
          getOptionLabel={(store) => store.name}
          onSelect={handleSearch}
          filterFunction={customFilterFunction}
        />
      </StyledSearchContainer>

      <StyledMapContainer data-testid="map-container-mobile" isMobile={isMobileView}>
        {!isLoaded || storeMarkers.length === 0 ? (
          <SkeletonLoader variant="rect" height="100%" width="100%" />
        ) : (
          <GoogleMap
            data-testid="google-map-mobile"
            center={center}
            zoom={2}
            mapContainerStyle={{ height: '100%', width: '100%' }}
          >
            {storeMarkers.map(({ lat, lng, storeId, corporateBrandId }, index) => {
              if (isNaN(lat) || isNaN(lng)) return null;

              return (
                <MarkerF
                  key={index}
                  position={{ lat, lng }}
                  onClick={() => handleMarkerClick(storesDetails?.[index].storeId, lat, lng)}
                  options={{ icon: { url: getLocationMarkerIcon(corporateBrandId, storeId) } }}
                />
              );
            })}
          </GoogleMap>
        )}
      </StyledMapContainer>
      <StoreInformation />
    </StyledWrapper>
  );

  const renderDesktopView = () => (
    <StyledContainer>
      <StyledMapContainer data-testid="map-container-desktop">
        {!isLoaded || storeMarkers.length === 0 ? (
          <SkeletonLoader variant="rect" height="100%" width="100%" />
        ) : (
          <GoogleMap
            data-testid="google-map-desktop"
            center={center}
            mapContainerStyle={{ height: '100%', width: '100%' }}
            onLoad={(map) => handleMapLoad(map)}
          >
            {storeMarkers.map(({ lat, lng, storeId, corporateBrandId }, index) => {
              if (isNaN(lat) || isNaN(lng)) return null;

              return (
                <MarkerF
                  key={index}
                  position={{ lat, lng }}
                  onClick={() => handleMarkerClick(storesDetails?.[index].storeId, lat, lng)}
                  options={{
                    icon: {
                      url: getLocationMarkerIcon(corporateBrandId, storeId),
                    },
                  }}
                />
              );
            })}
          </GoogleMap>
        )}
      </StyledMapContainer>

      <StyledSearchContainer data-testid="search-container-desktop">
        <Typography type="h4"> {t(findStore.key, findStore.defaultValue)}</Typography>
        <Typography gutterBottom>
          {t(storeSelectionText.key, storeSelectionText.defaultValue, {
            numberOfStores: storeMarkers?.length,
          })}
        </Typography>
        <Search
          fieldLabel={t(searchStoreLabel.key, searchStoreLabel.defaultValue)}
          options={storesDetails || []}
          getOptionLabel={(store) => `${store.storeId} - ${store.name}`}
          onSelect={handleSearch}
          filterFunction={customFilterFunction}
          markerOption={storesDetails?.find((storeDetails) => storeDetails.storeId === selectedStoreId)}
        />

        <StoreInformation />
      </StyledSearchContainer>
    </StyledContainer>
  );

  const navigateToPreviousSelectedStore = () => {
    if (previousStoreId) {
      setItem(LocalStorageKey.SelectedStore, previousStoreId as string);
      set({ currentStoreId: previousStoreId });

      navigate('/home');
    }
  };

  return (
    <StyledScreenContainer direction="horizontal" position="right">
      <StyledLogoContainer
        direction="horizontal"
        position="right"
        isMobile={storeSelectionScreen.enabled && isMobileView}
      >
        <Icon icon={Icons.HM_LOGO} size="large" onClick={navigateToPreviousSelectedStore} />
      </StyledLogoContainer>
      {storeSelectionScreen.enabled ? (
        isMobileView ? (
          renderMobileView()
        ) : (
          renderDesktopView()
        )
      ) : (
        <StyledWrapper>
          <StyledAnimationContainer position="center">
            <Animation
              title={t(header.key, header.defaultValue)}
              subtitle={t(subHeader.key, subHeader.defaultValue)}
            />
          </StyledAnimationContainer>
          <Container padding={[10, 0]} direction="horizontal" position="center">
            {storesDetails?.map(({ name, storeId }) => (
              <Fade in={true} timeout={{ appear: 300, enter: 300, exit: 0 }} key={storeId}>
                <StyledStoreCard
                  data-testid="store-card"
                  isSelected={selectedStoreId === storeId}
                  onClick={() => setSelectedStoreId(storeId)}
                >
                  <Typography data-testid="popular-name" type="body4" textAlign="left">
                    {name}
                  </Typography>
                  <Typography data-testid="store-id-label" type="body4" textAlign="left">
                    {t(storeIdText.key, storeIdText.defaultValue)}
                  </Typography>
                  <Typography data-testid="store-number" type="body4" textAlign="left">
                    {storeId.slice(2)}
                  </Typography>
                </StyledStoreCard>
              </Fade>
            ))}
          </Container>
          <Container position="center">
            <Button
              label={t(confirm.key, confirm.defaultValue)}
              disabled={!selectedStoreId}
              onClick={onStoreSelect}
            />
          </Container>
        </StyledWrapper>
      )}
    </StyledScreenContainer>
  );
});
