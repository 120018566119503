import { Divider } from '@mui/material';
import styled from 'styled-components';
import { Container, Icon } from '../../stories/atoms';
import { IconWithText } from '../../stories/molecules';
import { cardShadow } from '../../theme';
import { getSpacing, Spacing } from '../../utils';

export const StyledPLCardContainer = styled(Container)<{ isParent?: boolean }>`
  border-radius: ${({ theme: { spacing }, isParent }) => isParent && spacing(3)};
  ${({ isParent }) => isParent && cardShadow()}
  background-color: ${({ theme: { palette, color } }) =>
    palette.mode === 'dark' ? color.backgroundPaperDark : color.white};
`;

export const StyledDivider = styled(Divider)<{ margin?: Spacing }>`
  width: 100%;
  margin: ${({ theme: { spacing }, margin }) => (margin ? getSpacing(margin, spacing) : spacing(2, 0))};
  background-color: ${({ theme: { palette, color } }) =>
    palette.mode === 'dark' ? color.borderGrayDark : color.baseGray};
`;

export const PLStyledValueContainer = styled(Container)<{
  isMobile?: boolean;
}>`
  flex-direction: ${({ isMobile }) => (isMobile ? 'row-reverse' : 'column')};
`;

export const StyledIcon = styled(Icon)<{ isExpanded: boolean }>`
  transform: ${({ isExpanded }) => (isExpanded ? 'rotate(-0deg)' : 'rotate(-90deg)')};
`;

export const StyledExpandButton = styled(IconWithText)<{ shouldRotate: boolean }>`
  & > :nth-child(1) {
    transform: ${({ shouldRotate }) => shouldRotate && 'rotate(45deg)'};
  }
`;

export const StyledDetailsButton = styled(IconWithText)`
  height: ${({ theme: { spacing } }) => spacing(6)};
  width: ${({ theme: { spacing } }) => spacing(25)};
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
  border: 1px solid
    ${({ theme: { palette, color } }) => (palette.mode === 'dark' ? color.borderGrayDark : color.borderGray)};
`;

export const StyledButton = styled(IconWithText)<{ hasRadius?: boolean; margin?: Spacing }>`
  background-color: ${({ theme: { palette, color } }) => (palette.mode === 'dark' ? color.white : color.primary)};
  border-radius: ${({ theme: { spacing }, hasRadius }) => hasRadius && spacing(3)};
  margin: ${({ theme: { spacing }, margin }) => margin && getSpacing(margin, spacing)};
  height: ${({ theme: { spacing } }) => spacing(10)};
  & > div {
    color: ${({ theme: { palette, color } }) => (palette.mode === 'dark' ? color.primary : color.white)};
  }
`;
