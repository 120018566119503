export const getUniqueListItems = <T>(items: T[], valueResolver: (item: T) => string): T[] => {
  const uniqueItems: T[] = [];
  const encounteredItemsMap: { [key in string]: boolean } = {};

  items.forEach((item) => {
    if (encounteredItemsMap[valueResolver(item)]) {
      return;
    }

    uniqueItems.push(item);
    encounteredItemsMap[valueResolver(item)] = true;
  });

  return uniqueItems;
};

export type ObjectWithStringKeys = {
  [key in string]: unknown;
};

export function groupByKey<T extends ObjectWithStringKeys>(items: T[], groupingKey: string): T[][] {
  const grouped: {
    [key in string]: T[];
  } = {};

  for (let i = 0; i < items.length; i++) {
    const p = items[i][groupingKey] as string;

    if (!grouped[p]) {
      grouped[p] = [];
    }
    grouped[p].push(items[i]);
  }

  return Object.keys(grouped).map((groupedKey) => {
    return grouped[groupedKey];
  });
}
