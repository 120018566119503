import { ToggleButton as MuiToggleButton } from '@mui/material';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import articlePlaceholder from '../../../assets/articlePlaceholder.png';
import { StorePerformanceInterval } from '../../../constants';
import { useEventContext } from '../../../global-state/eventContext';
import { useThemeContext } from '../../../global-state/themeContext';
import { TopSellingGarmentResponse, useTransaction, useUser } from '../../../hooks';
import i18n from '../../../i18n';
import { Container, Icon, Icons, Typography } from '../../../stories/atoms';
import { Card, DynamicTab, Row, TabContentProps, TabLabelProps, Table } from '../../../stories/molecules';
import { ToggleButton } from '../../../stories/molecules/toggleButton/ToggleButton';
import { homeTranslations } from '../../../translations';
import { isFailureResponse } from '../../../utils';
import { WidgetCommonState } from '../widgetCommonState/WidgetCommonState';
import { StyledArticles, StyledItem, StyledView } from './TopSellingGarmentWidget.styles';

export type TopSellingGarmentWidgetProps = {
  currentDate: Date;
  isNonSigninUser?: boolean;
  interval?: StorePerformanceInterval;
};

export const TopSellingGarmentWidget: FC<TopSellingGarmentWidgetProps> = observer(
  ({ currentDate, isNonSigninUser, interval }) => {
    const {
      get: { currentStoreId, stores },
    } = useUser();
    const { fetchTopSellingGarmentData } = useTransaction();
    const { transactionEventsCount } = useEventContext();

    const [tabIndex, setTabIndex] = useState(0);
    const [topSellingGarmentTabs, setTopSellingGarmentTabs] = useState<TabLabelProps[]>([]);
    const [topSellingGarmentContent, setTopSellingGarmentContent] = useState<TabContentProps[]>([]);
    const [topSellingGarments, setTopSellingGarments] = useState<{
      data: TopSellingGarmentResponse[];
      isLoading: boolean;
      isError: boolean;
    }>({
      data: [],
      isLoading: false,
      isError: false,
    });
    const [view, setView] = useState('view1');
    const [currentStoreCurrency, setCurrentStoreCurrency] = useState<string>('');

    const { mode } = useThemeContext();

    const { t } = useTranslation();
    const { topSelling, common } = homeTranslations;

    useEffect(() => {
      if (!currentStoreId) return;
      if (interval === StorePerformanceInterval.WEEKLY) {
        setTopSellingGarments({ data: [], isError: false, isLoading: false });
        return;
      }

      const fetchResponse = async () => {
        setTopSellingGarments({ data: [], isLoading: true, isError: false });

        const response = await fetchTopSellingGarmentData(i18n.language, format(currentDate, 'yyyy-MM-dd'));

        if (isFailureResponse(response)) {
          setTopSellingGarments({ data: [], isLoading: false, isError: true });
          return;
        }

        const { data } = response;
        const sortedData = data.sort((a, b) => b.soldPieces - a.soldPieces);
        setTopSellingGarments({ data: sortedData, isLoading: false, isError: false });
      };

      fetchResponse();

      const getStoreData = stores.find((store) => store.storeId === currentStoreId);
      if (getStoreData) {
        setCurrentStoreCurrency(getStoreData.currency || '');
      }
    }, [currentDate, currentStoreId, i18n.language, isNonSigninUser, transactionEventsCount]);

    const getUpdatedRows = (categoryGroup: TopSellingGarmentResponse[]) =>
      categoryGroup.map(({ title, soldPieces, whitePrice, imageUrl, moneyGenerated }, index) => ({
        id: index,
        item: (
          <StyledArticles>
            <StyledItem
              data-testid="article-image"
              src={imageUrl}
              alt=""
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.src = articlePlaceholder;
              }}
              width="100"
              height="100"
            />
            <div>
              <Typography>{title}</Typography>
              <Typography>{whitePrice}</Typography>
            </div>
          </StyledArticles>
        ),
        count: (
          <Typography color="textGray">
            {view === 'view1' ? soldPieces + ' pc' : moneyGenerated + ' ' + currentStoreCurrency}
          </Typography>
        ),
      }));

    useEffect(() => {
      if (!topSellingGarments.data.length) return;

      // Filtering category data from main response into categoryGroups
      const categoryGroups = topSellingGarments.data.reduce((map, item) => {
        const { category } = item;
        const group = map.get(category) || [];
        group.push(item);
        map.set(category, group);
        return map;
      }, new Map());

      // Setting two useStates for tabs and content dynamically taking top 10 items
      const tabLabelArray = [
        { tabName: t(common.allTabName.key, common.allTabName.defaultValue), isTabVisible: true },
      ];
      const tabContentArray = [
        { tabContent: getTabContent(getUpdatedRows(topSellingGarments.data.slice(0, 10))), visible: true },
      ];

      categoryGroups.forEach((value, key) => {
        const newTab = { tabName: key, isTabVisible: true };
        const newTabcontent = { tabContent: getTabContent(getUpdatedRows(value.slice(0, 10))), visible: true };
        tabLabelArray.push(newTab);
        tabContentArray.push(newTabcontent);
      });

      setTopSellingGarmentTabs(tabLabelArray);
      setTopSellingGarmentContent(tabContentArray);
    }, [view, topSellingGarments.data]);

    const handleTabChange = (event: React.SyntheticEvent, newTabIndex: number) => {
      setTabIndex(newTabIndex);
    };

    const getTabContent = (tabRows: Row[]) => {
      return (
        <Table
          stickyHeader
          noBorder
          fixedHeight={'270px'}
          rows={tabRows}
          noHeader
          columns={[
            {
              id: 'item',
              name: t(common.type.key, common.type.defaultValue),
              width: '70%',
            },
            {
              id: 'count',
              name: t(common.count.key, common.count.defaultValue),
              width: '30%',
            },
          ]}
          isLoading={topSellingGarments.isLoading}
          skeletonLoaderOptions={{ numberOfRows: 4 }}
        />
      );
    };

    const CardContent = () => {
      return (
        <Container wrap="nowrap" data-testid="top-selling-garment-overview" height="100%">
          <StyledView wrap="nowrap" data-testid="top-selling-garment-table" hasBorder>
            <DynamicTab
              tabLabelArray={topSellingGarmentTabs}
              handleTabChange={handleTabChange}
              tabValue={tabIndex}
              variant="scrollable"
              tabBgColor={mode === 'light' ? 'baseGray' : 'backgroundTableHeaderDark'}
              content={topSellingGarmentContent}
            />
          </StyledView>
        </Container>
      );
    };

    const getTemplate = () => {
      if (interval === StorePerformanceInterval.WEEKLY) return <WidgetCommonState isNoData isNoWeeklyData />;
      if (topSellingGarments.isError)
        return <WidgetCommonState isError errorText={t(topSelling.error.key, topSelling.error.defaultValue)} />;
      if (topSellingGarments.isLoading) return <WidgetCommonState isLoading />;
      if (!topSellingGarments.data.length) return <WidgetCommonState isNoData />;

      return <CardContent />;
    };

    const handleChangeView = (event: React.MouseEvent<HTMLElement>, view2: string) => {
      if (view2 !== null) {
        setView(view2);
      }
    };

    const rightContainerElements = () => {
      return (
        <ToggleButton
          value={view}
          onChange={handleChangeView}
          leftButton={
            <MuiToggleButton value="view1" aria-label="left button">
              <Icon data-testid="left-icon" icon={Icons.DELIVERY} size="small" />
            </MuiToggleButton>
          }
          rightButton={Icons.DOLLAR}
        />
      );
    };

    return (
      <Card
        headerIcon={Icons.CUSTOMERVALUE}
        headerText={t(topSelling.title.key, topSelling.title.defaultValue)}
        subHeaderText={t(topSelling.subTitle.key, topSelling.subTitle.defaultValue)}
        headerRightElement={rightContainerElements()}
        content={getTemplate()}
        toolTipText={t(topSelling.toolTip.key, topSelling.toolTip.defaultValue)}
      />
    );
  },
);
