import { FC } from 'react';
import { Property } from 'csstype';
import { Container, Icons, Tooltip } from '../../atoms';
import { Typography } from '../../atoms/typography/Typography';
import {
  StyledHeader,
  StyledHeaderText,
  StyledIcon,
  StyledContent,
  StyledFooter,
  StyledHeaderSide,
} from './Card.styles';

export type GridCardItemProps = {
  headerIcon?: Icons;
  headerText?: string;
  subHeaderText?: string;
  headerRightElement?: JSX.Element;
  content: JSX.Element;
  footer?: JSX.Element;
  overflow?: Property.Overflow;
  toolTipText?: string;
};

export const Card: FC<GridCardItemProps> = ({
  headerText,
  subHeaderText,
  headerIcon,
  headerRightElement,
  content,
  footer,
  overflow,
  toolTipText,
}) => {
  return (
    <Container data-testid="card-wrapper" height="100%" wrap="nowrap" width="100%">
      <StyledHeader>
        <StyledHeaderSide>
          {headerIcon && (
            <Tooltip position="bottom-start" text={<Typography color="white">{toolTipText}</Typography>}>
              <StyledIcon data-testid="header-icon" icon={headerIcon} size="small" margin={[0, 4, 0, 0]} />
            </Tooltip>
          )}
          <StyledHeaderText>
            <Typography data-testid="title" type="body3" margin={[0, 0, 1, 0]}>
              {headerText}
            </Typography>
            <Typography data-testid="sub-title" type="legal" color="secondaryTextGray">
              {subHeaderText}
            </Typography>
          </StyledHeaderText>
        </StyledHeaderSide>
        <div data-testid="header-right-element">{headerRightElement}</div>
      </StyledHeader>
      <StyledContent data-testid="content" overflow={overflow}>
        {content}
      </StyledContent>
      {footer && (
        <StyledFooter padding={[6]} direction="horizontal" position="right" data-testid="footer">
          {footer}
        </StyledFooter>
      )}
    </Container>
  );
};
