import { useAccount, useMsal } from '@azure/msal-react';
import { AxiosRequestConfig } from 'axios';
import { msalScopes } from '../constants/msalConfig';
import { axiosRequest } from '../utils';

export const useAuthAxiosRequest = (keyToken?: string) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const getAccessToken = async (): Promise<string | null> => {
    if (!account) return null;

    try {
      const { accessToken } = await instance.acquireTokenSilent({
        ...msalScopes,
        account: account,
      });

      return accessToken;
    } catch (err) {
      // TODO: Add proper client side logging - BTCOBUI-1142
      console.log(err);
    }

    return null;
  };

  const authAxiosRequest = async <T>(
    endpoint: string,
    config?: AxiosRequestConfig,
  ): ReturnType<typeof axiosRequest<T>> => {
    const accessToken = await getAccessToken();
    let authorizationHeaderVal = '';

    if (accessToken) {
      authorizationHeaderVal = `Bearer ${accessToken}`;
    } else if (keyToken) {
      authorizationHeaderVal = `Basic ${keyToken}`;
    }

    const updatedConfig: AxiosRequestConfig = {
      ...config,
      headers: { ...config?.headers },
    };

    if (authorizationHeaderVal) {
      updatedConfig.headers = {
        ...updatedConfig.headers,
        Authorization: authorizationHeaderVal,
      };
    }

    return axiosRequest<T>(endpoint, updatedConfig);
  };

  return { authAxiosRequest };
};
