import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StorePerformanceInterval, createStorePerformanceRequest } from '../../../constants';
import { useThemeContext } from '../../../global-state/themeContext';
import { QueueLengthItem, QueueLengthResponse, TillType, useTransaction } from '../../../hooks';
import { useAsyncAction } from '../../../hooks/useAsyncAction';
import { Container, Icons, Typography } from '../../../stories/atoms';
import { Animation, Animations, Card, DynamicTab, TagsList } from '../../../stories/molecules';
import { Charts } from '../../../stories/molecules/charts/Charts';
import { Typographies, muiTheme } from '../../../theme';
import { homeTranslations } from '../../../translations';
import { Spacing, isFailureResponse } from '../../../utils';
import { WidgetCommonState } from '../widgetCommonState/WidgetCommonState';
import { StyledSummaryContainer, StyledSummaryItem, StyledWidgetWrapper } from './QueueLengthWidget.styles';

const TILLS_PER_PAGE = 10;

export type QueueLengthProps = {
  interval: StorePerformanceInterval;
  currentDate: Date;
  fromDate?: Date;
  toDate?: Date;
};

export const QueueLengthWidget: FC<QueueLengthProps> = observer(({ interval, currentDate, fromDate, toDate }) => {
  const { t } = useTranslation();
  const { fetchQueueLengthData } = useTransaction();
  const { queueLength: queueLengthTranslations } = homeTranslations;
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));
  const { mode } = useThemeContext();
  const [tabIndex, setTabIndex] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);

  const {
    result: queueLengthData,
    isLoading: queueLengthDataLoading,
    isError: queueLengthDataError,
    triggerAction: triggerFetchQueueLength,
  } = useAsyncAction<QueueLengthResponse>();

  const fetchQueueLength = useCallback(async () => {
    const request = createStorePerformanceRequest({
      interval,
      ...(interval === StorePerformanceInterval.DAILY ? { currentDate } : { fromDate, toDate }),
    });

    const response = await fetchQueueLengthData(request);

    if (isFailureResponse(response)) {
      throw new Error();
    }

    return response.data;
  }, [interval, currentDate]);

  const handleTabChange = (event: React.SyntheticEvent, newTabIndex: number) => {
    setPageIndex(0);
    setTabIndex(newTabIndex);
  };

  const handlePageChange = (newPageIndex: number) => {
    setPageIndex(newPageIndex);
  };

  const getFilteredQueueLengthItems = (
    currentQueueLengthData: QueueLengthResponse | null,
    currentTabIndex: number,
  ) => {
    if (!currentQueueLengthData) return [];

    const tillTypes: TillType[] = ['DEFAULT', 'SELFCHECKOUT'];

    let filteredQueueLengthItems: QueueLengthItem[];

    if (currentTabIndex === 0) {
      filteredQueueLengthItems = currentQueueLengthData.queueLength.filter(
        (item) => item.tillType === tillTypes[currentTabIndex] || item.tillType === null,
      );
    } else {
      filteredQueueLengthItems = currentQueueLengthData.queueLength.filter(
        (item) => item.tillType === tillTypes[currentTabIndex],
      );
    }

    filteredQueueLengthItems.sort((a, b) => b.queueCount - a.queueCount);
    return filteredQueueLengthItems;
  };

  const getCurrentPageQueueLengthItems = (
    currentQueueLengthItems: QueueLengthItem[],
    currentPageIndex: number,
  ) => {
    const startIndex = currentPageIndex * TILLS_PER_PAGE;
    const endIndex = startIndex + TILLS_PER_PAGE;

    return currentQueueLengthItems.slice(startIndex, endIndex);
  };

  const calculatePercentage = (part: number, total: number) => (total === 0 ? 0 : part / total) * 100;

  const getOverAllQueueInformation = () => {
    if (!queueLengthData) {
      return {
        overallQueueLengthPercentage: 0,
        overallExperiencePercentage: 0,
      };
    }

    const { queueLength, totalTransactions, checkoutExperience } = queueLengthData;
    const { happyCheckoutExperienceCount, totalCheckoutExperienceCount } = checkoutExperience;

    const totalQueueLength = queueLength.reduce((acc, item) => acc + item.queueCount, 0);

    const overallQueueLengthPercentage = calculatePercentage(totalQueueLength, totalTransactions);
    const overallExperiencePercentage = calculatePercentage(
      happyCheckoutExperienceCount,
      totalCheckoutExperienceCount,
    );

    return {
      overallQueueLengthPercentage,
      overallExperiencePercentage,
    };
  };

  useEffect(() => {
    triggerFetchQueueLength(fetchQueueLength);
  }, [interval, currentDate]);

  const filteredQueueLengthItems = getFilteredQueueLengthItems(queueLengthData, tabIndex);

  const filteredDefaultQueueLengthItems = getFilteredQueueLengthItems(queueLengthData, 0);

  const filteredSelfCheckoutQueueLengthItems = getFilteredQueueLengthItems(queueLengthData, 1);

  const currentPageQueueLengthItems = getCurrentPageQueueLengthItems(filteredQueueLengthItems, pageIndex);

  const maxYAxisValue = Math.max(...filteredQueueLengthItems.map((item) => item.queueCount));

  const isNoData = queueLengthData && queueLengthData.queueLength.length === 0;

  const numberOfPages = Math.ceil(filteredQueueLengthItems.length / TILLS_PER_PAGE);

  const { overallQueueLengthPercentage, overallExperiencePercentage } = getOverAllQueueInformation();

  return (
    <Card
      headerIcon={Icons.PROFILE}
      headerText={t(queueLengthTranslations.title.key, queueLengthTranslations.title.defaultValue)}
      subHeaderText={t(queueLengthTranslations.subTitle.key, queueLengthTranslations.subTitle.defaultValue)}
      content={
        <Container height="100%">
          {queueLengthDataLoading && <WidgetCommonState isLoading />}

          {queueLengthDataError && (
            <WidgetCommonState
              isError
              errorText={t(queueLengthTranslations.error.key, queueLengthTranslations.error.defaultValue)}
            />
          )}

          {!queueLengthDataLoading && !queueLengthDataError && isNoData && (
            <Animation
              size="small"
              title=""
              subtitle={t(queueLengthTranslations.noData.key, queueLengthTranslations.noData.defaultValue)}
              animation={Animations.COOL}
            />
          )}

          {!queueLengthDataLoading && !queueLengthDataError && !isNoData && (
            <StyledWidgetWrapper data-testid="queue-length-wrapper">
              <DynamicTab
                tabLabelArray={[
                  {
                    tabName: t(
                      queueLengthTranslations.tabDefault.key,
                      queueLengthTranslations.tabDefault.defaultValue,
                    ),
                    isTabVisible: true,
                    isTabDisabled: filteredDefaultQueueLengthItems.length === 0,
                    labelType: 'body3' as Typographies,
                  },
                  {
                    tabName: t(
                      queueLengthTranslations.tabSelfCheckout.key,
                      queueLengthTranslations.tabSelfCheckout.defaultValue,
                    ),
                    isTabVisible: filteredSelfCheckoutQueueLengthItems.length != 0,
                    labelType: 'body3' as Typographies,
                    labelMargin: [0] as Spacing,
                  },
                ]}
                handleTabChange={handleTabChange}
                tabValue={tabIndex}
                variant="scrollable"
                tabBgColor={mode === 'light' ? 'baseGray' : 'backgroundTableHeaderDark'}
                content={[]}
              />
              {numberOfPages > 1 && (
                <Container margin={[4, 4, 0, 4]}>
                  <TagsList
                    items={Array.from(Array(numberOfPages).keys())}
                    activeItemIndex={pageIndex}
                    itemTextRenderer={(_, index) =>
                      `${index * TILLS_PER_PAGE + 1}-${(index + 1) * TILLS_PER_PAGE}`
                    }
                    onTagSelect={(_, index) => handlePageChange(index)}
                  />
                </Container>
              )}

              <Container wrap="nowrap" data-testid="chart" style={{ flex: 1 }}>
                <Charts
                  chartType="bar"
                  isStacked={true}
                  xAxisCategories={currentPageQueueLengthItems.map((item) => item.tillId)}
                  yAxis={{ max: maxYAxisValue, forceNiceScale: true }}
                  chartSeries={[
                    {
                      name: t(
                        queueLengthTranslations.chartToolTip.key,
                        queueLengthTranslations.chartToolTip.defaultValue,
                      ),
                      data: currentPageQueueLengthItems.map((item) => item.queueCount),
                    },
                  ]}
                  toolTip={{
                    enabled: true,
                    shared: true,
                    intersect: false,
                    marker: { show: true },
                    x: {
                      show: true,
                      formatter: (value) => {
                        return `${t(
                          queueLengthTranslations.tillToolTip.key,
                          queueLengthTranslations.tillToolTip.defaultValue,
                        )}: ${value}`;
                      },
                    },
                  }}
                  areaChartGradient={{ opacity: 1 }}
                  legend={{
                    markers: {
                      radius: 12,
                      offsetY: 1,
                    },
                  }}
                  xAxis={{
                    labels: {
                      rotate: -45,
                      rotateAlways: isMobile,
                    },
                  }}
                  isAnimationEnabled={false}
                />
              </Container>

              <StyledSummaryContainer direction="horizontal">
                <StyledSummaryItem>
                  <Typography type="button" margin={[0, 0, 1, 0]}>
                    {overallQueueLengthPercentage.toFixed(2)}%
                  </Typography>
                  <Typography>
                    {t(
                      queueLengthTranslations.overallQueueLength.key,
                      queueLengthTranslations.overallQueueLength.defaultValue,
                    )}
                  </Typography>
                </StyledSummaryItem>

                {overallExperiencePercentage !== 0 && (
                  <StyledSummaryItem>
                    <Typography type="button" margin={[0, 0, 1, 0]}>
                      {overallExperiencePercentage.toFixed(2)}%
                    </Typography>
                    <Typography>
                      {t(
                        queueLengthTranslations.overallExperience.key,
                        queueLengthTranslations.overallExperience.defaultValue,
                      )}
                    </Typography>
                  </StyledSummaryItem>
                )}
              </StyledSummaryContainer>
            </StyledWidgetWrapper>
          )}
        </Container>
      }
      toolTipText={t(queueLengthTranslations.toolTip.key, queueLengthTranslations.toolTip.defaultValue)}
    />
  );
});
