export const profitAndLossTransalations = {
  headerText: {
    key: 'profitLoss.headerText',
    defaultValue: 'P & L report based on financial year {{reportPeriod}}.',
  },
  error: {
    errorTitle: {
      key: 'profitLoss.error.errorTitle',
      defaultValue: 'Something went wrong!',
    },
    errorSubtitle: {
      key: 'profitLoss.error.errorSubtitle',
      defaultValue: 'There was an error while fetching the report. Please try again!',
    },
  },
  loading: {
    key: 'profitLoss.loading',
    defaultValue: 'Fetching Profit and Loss data...',
  },
  viewDetailsButton: {
    key: 'profitLoss.viewDetailsButton',
    defaultValue: 'View Details',
  },
  monthDropdown: {
    key: 'profitLoss.monthDropdown',
    defaultValue: 'Month',
  },
  reportProperties: {
    actuals: {
      key: 'profitLoss.reportProperties.actuals',
      defaultValue: 'Actuals',
    },
    indexVsLY: {
      key: 'profitLoss.reportProperties.indexVsLY',
      defaultValue: 'Index vs LY',
    },
    indexVsBudget: {
      key: 'profitLoss.reportProperties.indexVsBudget',
      defaultValue: 'Index vs Budget',
    },
    sob: {
      key: 'profitLoss.reportProperties.sob',
      defaultValue: 'SoB',
    },
    sobLY: {
      key: 'profitLoss.reportProperties.sobLY',
      defaultValue: 'SoB LY',
    },
    sobBudget: {
      key: 'profitLoss.reportProperties.sobBudget',
      defaultValue: 'SoB Budget',
    },
  },
  mobileBannerText: {
    key: 'profitLoss.mobileBannerText',
    defaultValue: 'Tilt your phone to landscape mode for better view',
  },
};
