import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StorePerformanceInterval, VendorMapping, VendorType, isSupportedVendorType } from '../../../constants';
import { useEventContext } from '../../../global-state/eventContext';
import { SortDirection } from '../../../global-state/types';
import { Tender, useStoreInformation, useTransaction } from '../../../hooks';
import { Container, Icons } from '../../../stories/atoms';
import { Card, IconWithText, Row, Table } from '../../../stories/molecules';
import { homeTranslations, transactionSearchTranslations } from '../../../translations';
import { formatAmountWithCurrency, formatValue, isFailureResponse } from '../../../utils';
import { WidgetCommonState } from '../widgetCommonState/WidgetCommonState';
import { StyledIcon, StyledTableWrapper, StyledTenderContainer } from './TenderOverviewWidget.styles';

type TenderOverviewWidgetProps = {
  currentDate: Date;
  interval?: StorePerformanceInterval;
};

export const TenderOverviewWidget: FC<TenderOverviewWidgetProps> = observer(({ currentDate, interval }) => {
  const { t, i18n } = useTranslation();
  const { tenderOverview, common } = homeTranslations;

  const { currencyCode } = useStoreInformation();

  const { fetchTenderData } = useTransaction();
  const { transactionEventsCount } = useEventContext();

  const [rows, setRows] = useState<Row[]>([]);

  const [tenderWidgetResponse, setTenderWidgetResponse] = useState<{
    data: Tender[];
    isLoading: boolean;
    isError: boolean;
  }>({
    data: [],
    isLoading: false,
    isError: false,
  });

  useEffect(() => {
    if (interval === StorePerformanceInterval.WEEKLY) {
      setTenderWidgetResponse({ data: [], isError: false, isLoading: false });
      return;
    }
    const fetchData = async () => {
      setTenderWidgetResponse({ data: [], isLoading: true, isError: false });

      const response = await fetchTenderData(format(currentDate, 'yyyy-MM-dd'));

      if (isFailureResponse(response)) {
        setTenderWidgetResponse({ data: [], isLoading: false, isError: true });
        return;
      }
      const { data } = response;
      setTenderWidgetResponse({ data, isLoading: false, isError: false });
    };

    fetchData();
  }, [currentDate, transactionEventsCount]);

  const sortTenderData = (property: keyof Tender, order: SortDirection) => {
    return (a: Tender, b: Tender) => {
      if (a[property] < b[property]) return order === 'asc' ? -1 : 1;
      if (a[property] > b[property]) return order === 'asc' ? 1 : -1;
      return 0;
    };
  };

  const getUpdatedRows = (data: Tender[]) => {
    return data.map(({ type, count, amount }, index) => ({
      id: index,
      tender: (
        <StyledTenderContainer direction="horizontal">
          <StyledIcon
            icon={
              isSupportedVendorType(type)
                ? (VendorMapping[type] as VendorType)
                : type === '' // When empty subTender type it equals to cash
                ? Icons.TRANSACTION_SEARCH
                : Icons.TENDER
            }
            size="large"
          />
          <IconWithText
            label={
              isSupportedVendorType(type)
                ? VendorMapping[type]
                : type === ''
                ? VendorMapping.CA // When empty subTender type it equals to cash
                : type
            }
            textOptions={{ padding: [0, 0, 0, 4] }}
          />
        </StyledTenderContainer>
      ),
      count: formatValue(count, { options: { minimumFractionDigits: 0 } }),
      amount: formatAmountWithCurrency(amount, i18n.language, currencyCode),
    }));
  };

  useEffect(() => {
    if (!tenderWidgetResponse.data.length) return;
    const sortedData = tenderWidgetResponse.data.sort(sortTenderData('amount', SortDirection.Descending));
    const updatedRows = getUpdatedRows(sortedData);
    setRows(updatedRows);
  }, [tenderWidgetResponse.data]);

  const hasNoData =
    !tenderWidgetResponse.data.length && !tenderWidgetResponse.isError && !tenderWidgetResponse.isLoading;

  const sortData = (columnId: string, direction: SortDirection) => {
    const sortedData = tenderWidgetResponse.data.sort(sortTenderData(columnId as keyof Tender, direction));
    const updatedRows = getUpdatedRows(sortedData);
    return updatedRows;
  };

  const getTemplate = () => {
    const defaultView = (
      <Container wrap="nowrap" data-testid="tender-overview-wrapper" height="100%">
        <StyledTableWrapper data-testid="tender-data-table">
          <Table
            stickyHeader
            noBorder
            fixedHeight="100%"
            rows={rows}
            columns={[
              {
                id: 'tender',
                name: t(tenderOverview.tender.key, tenderOverview.tender.defaultValue),
                width: '40%',
              },
              {
                id: 'count',
                name: t(common.count.key, common.count.defaultValue),
                width: '40%',
                isSortable: true,
              },
              {
                id: 'amount',
                name: t(
                  transactionSearchTranslations.amount.key,
                  transactionSearchTranslations.amount.defaultValue,
                ),
                width: '20%',
                isSortable: true,
              },
            ]}
            isLoading={tenderWidgetResponse.isLoading}
            skeletonLoaderOptions={{ numberOfRows: 4 }}
            handleSorting={(columnId, direction) => sortData(columnId, direction)}
          ></Table>
        </StyledTableWrapper>
      </Container>
    );

    if (interval === StorePerformanceInterval.WEEKLY) return <WidgetCommonState isNoData isNoWeeklyData />;
    if (hasNoData) return <WidgetCommonState isNoData />;
    if (tenderWidgetResponse.isError)
      return (
        <WidgetCommonState isError errorText={t(tenderOverview.error.key, tenderOverview.error.defaultValue)} />
      );

    return defaultView;
  };

  return (
    <Card
      headerIcon={Icons.TRANSACTIONS}
      headerText={t(tenderOverview.title.key, tenderOverview.title.defaultValue)}
      subHeaderText={t(tenderOverview.subTitle.key, tenderOverview.subTitle.defaultValue)}
      content={getTemplate()}
      toolTipText={t(tenderOverview.toolTip.key, tenderOverview.toolTip.defaultValue)}
    />
  );
});
