export enum RoutePaths {
  HOME = '/home',
  TRANSACTION = '/transaction',
  EMPLOYEES = '/employees/:storeId',
  AVATARMANAGEMENT = '/avatar-management',
  KPITARGET = '/kpiTarget',
  SOLDPRODUCTS = '/soldProducts',
  DEVELOPMENT = '/development',
  PRODUCTCATEGORY = '/product-category',
  PROFITANDLOSS = '/profitAndLoss',
}
