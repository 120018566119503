import { ReportItem } from '../../hooks';

export const generateCardTitle = (departmentID: string, costName: string, isParent: boolean): string => {
  return `${!isParent && departmentID !== '' ? `${departmentID} - ` : ''} ${costName}`;
};

export const profitLossPropertyToValueMap = (details: ReportItem) => {
  return [
    { key: 'actuals', value: details?.actuals?.toLocaleString() },
    { key: 'indexVsLY', value: details?.indexVsLY },
    { key: 'indexVsBudget', value: details?.indexVsBudget },
    { key: 'sob', value: `${details?.sob}%` },
    { key: 'sobLY', value: `${details?.sobLY}%` },
    { key: 'sobBudget', value: `${details?.sobBudget}%` },
  ];
};
