import { useMediaQuery } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import articlePlaceholder from '../../../assets/articlePlaceholder.png';
import { SoldProductItem } from '../../../hooks';
import { Typography } from '../../../stories/atoms';
import { Animation, Animations, Table } from '../../../stories/molecules';
import { muiTheme } from '../../../theme';
import { soldProductsTranslations, transactionSearchTranslations } from '../../../translations';
import { getArticleAssetUrl } from '../../../utils/soldProducts';
import { BadgeWrapper, StyledAnimationWrapper, StyledItem } from './ProductTable.styles';

const { rowsPerpageLabel } = transactionSearchTranslations;

type ProductTableProps = {
  productTable: {
    rows: SoldProductItem[];
    pagination: {
      pageSize: number;
      currentPageNumber: number;
      rowsPerPageOptions: number[];
    };
  };
  isFetchingGarments: boolean;
  height: string;
  soldProductsCount: number;
  selectedRowIndex: number;
  onRowClick: (selectedRowId: number) => void;
  onPageChange: (pageNo: number) => void;
};

export const ProductTable: FC<ProductTableProps> = ({
  productTable,
  isFetchingGarments,
  height,
  soldProductsCount,
  selectedRowIndex,
  onRowClick,
  onPageChange,
}) => {
  const { t } = useTranslation();
  const isMobileView = useMediaQuery(muiTheme.breakpoints.down('desktop'));

  const [pagination, setPagination] = useState(productTable.pagination);

  // TODO: Update the data structure to match the actual data
  const getRows = () => {
    return productTable.rows.map((row, index) => {
      const {
        productName,
        productId,
        soldPieces,
        price,
        departmentId,
        departmentName,
        salesNet,
        season,
        mostSoldArticle,
      } = row;

      const imageUrl = getArticleAssetUrl({
        articleId: `${productId}${mostSoldArticle}`,
        assetType: 'descriptiveStillLife',
        rendition: 'extraSmall',
      });

      console.log(imageUrl);
      return {
        id: index,
        photo: (
          <StyledItem
            data-testid="article-image"
            src={imageUrl}
            alt=""
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.src = articlePlaceholder;
            }}
            width="100"
            height="100"
          />
        ),
        departmentId: (
          <>
            <Typography type="body3">{departmentId}</Typography>
            <Typography type="legal" color="textGray" padding={[1, 0, 0, 0]}>
              {departmentName}
            </Typography>
          </>
        ),
        productId: (
          <>
            <BadgeWrapper isMobileView={isMobileView}>
              <Typography type="body3" margin={[0, 2, 0, 0]}>
                {productId}
              </Typography>
              {/* TODO: add when BE spec is updated */}
              {/* <Badge backgroundColor={stockAvailable ? 'successLight' : 'errorLight'}>
                <Typography type="body3" color={stockAvailable ? 'validationGreen' : 'red'}>
                  {stockAvailable
                    ? t(soldProductsTranslations.inStock.key, soldProductsTranslations.inStock.defaultValue)
                    : t(soldProductsTranslations.outOfStock.key, soldProductsTranslations.outOfStock.defaultValue)}
                </Typography>
              </Badge> */}
            </BadgeWrapper>
            <Typography type="legal" color="textGray" padding={[1, 0, 0, 0]}>
              {productName}
            </Typography>
          </>
        ),
        season: season,
        // TODO: add when BE spec is updated
        // piecesRemaining: `${remainingPieces} pcs`,
        price: `${price}`,
        salesNet: `${salesNet}`,
        soldPieces: `${soldPieces} ${t(
          soldProductsTranslations.pieces.key,
          soldProductsTranslations.pieces.defaultValue,
        )}`,
        // TODO: add when BE spec is updated
        // speed: `${speed}%`,
      };
    });
  };

  const tableHeaders = [
    {
      id: 'photo',
      name: t(soldProductsTranslations.image.key, soldProductsTranslations.image.defaultValue),
      width: '10%',
    },
    {
      id: 'season',
      name: t(soldProductsTranslations.season.key, soldProductsTranslations.season.defaultValue),
      width: '10%',
    },
    {
      id: 'departmentId',
      name: `${t(
        soldProductsTranslations.departmentId.key,
        soldProductsTranslations.departmentId.defaultValue,
      )}/${t(soldProductsTranslations.name.key, soldProductsTranslations.name.defaultValue)}`,
      width: '12.5%',
    },
    {
      id: 'productId',
      name: `${t(soldProductsTranslations.productId.key, soldProductsTranslations.productId.defaultValue)}/${t(
        soldProductsTranslations.name.key,
        soldProductsTranslations.name.defaultValue,
      )}`,
      width: '15%',
    },
    // TODO: add when BE spec is updated
    // {
    //   id: 'piecesRemaining',
    //   name: t(soldProductsTranslations.piecesRemaining.key, soldProductsTranslations.piecesRemaining.defaultValue),
    //   width: '12.5%',
    // },
    {
      id: 'price',
      name: t(soldProductsTranslations.price.key, soldProductsTranslations.price.defaultValue),
      width: '10%',
    },
    {
      id: 'salesNet',
      name: t(soldProductsTranslations.salesNet.key, soldProductsTranslations.salesNet.defaultValue),
      width: '10%',
      isSortable: true,
    },
    {
      id: 'soldPieces',
      name: t(soldProductsTranslations.piecesSold.key, soldProductsTranslations.piecesSold.defaultValue),
      width: '10%',
      isSortable: true,
    },
    // TODO: add when BE spec is updated
    // { id: 'speed', name: 'Speed', width: '10%', isSortable: true },
  ];

  if (!productTable.rows.length && !isFetchingGarments) {
    return (
      <StyledAnimationWrapper>
        <Animation
          animation={Animations.CONFUSED}
          title={t(soldProductsTranslations.noData.title.key, soldProductsTranslations.noData.title.defaultValue)}
          subtitle={t(
            soldProductsTranslations.noData.subtitle.key,
            soldProductsTranslations.noData.subtitle.defaultValue,
          )}
        ></Animation>
      </StyledAnimationWrapper>
    );
  }

  return (
    <Table
      stickyHeader
      useZebraStyles
      rows={getRows()}
      columns={tableHeaders}
      onRowClick={onRowClick}
      paginationOptions={{
        enabled: soldProductsCount > 0,
        rowsPerPage: productTable.pagination.pageSize,
        rowsPerPageOptions: productTable.pagination.rowsPerPageOptions,
        totalRows: soldProductsCount,
        defaultPageIndex: productTable.pagination.currentPageNumber === 1 ? 0 : undefined, // reset when user select new dropdown filter or date selection
        nextIconButtonProps: {
          disabled:
            isFetchingGarments ||
            productTable.pagination.currentPageNumber * productTable.pagination.pageSize >= soldProductsCount,
        },
      }}
      selectedRowIndex={selectedRowIndex}
      fixedHeight={height}
      onPageChange={onPageChange}
      onRowsPerPageChange={(rowsPerPage) => setPagination({ ...pagination, pageSize: rowsPerPage })}
      labelRowsPerPage={t(rowsPerpageLabel.key, rowsPerpageLabel.defaultValue)}
      isLoading={isFetchingGarments}
      whiteSpace="nowrap"
      noBorder
    />
  );
};
