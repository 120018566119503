import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Icons, Typography } from '../../stories/atoms';
import { IconWithText } from '../../stories/molecules';
import { muiTheme } from '../../theme';
import { commonTranslations, productCategoryTranslations } from '../../translations';
import { groupByKey } from '../../utils';
import {
  GroupingKey,
  ProductItem,
  getSob,
  getTotalLastWeek,
  getTotalLastYear,
  getTotalSalesNet,
} from './ProductCategoryPage';
import { TableFooter } from './TableFooter';
import { TableHeader } from './TableHeader';
import { TableItem } from './TableItem/TableItem';

const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme: { spacing } }) => spacing(1)};
  margin: ${({ theme: { spacing } }) => spacing(4, 0)};
`;

const ItemsContainer = styled.div<{ isMobileView: boolean }>`
  flex: 1;
  overflow: auto;
  margin-bottom: ${({ isMobileView, theme: { spacing } }) => (isMobileView ? spacing(14) : spacing(10))};
`;

const TableFooterContainer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundPaperDark : color.white};
  padding: ${({ theme: { spacing } }) => spacing(0, 8)};
`;

type Props = {
  groupingKey: GroupingKey | null;
  groupingKeyValue: string | null;
  productItems: ProductItem[];
  backButtonHandler: () => void;
};

export const GarmentGroupView = ({ groupingKey, groupingKeyValue, productItems, backButtonHandler }: Props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(muiTheme.breakpoints.down('tabletPortrait'));

  const filteredProductItems = productItems.filter((productItem) => {
    if (!groupingKey) return true;
    return productItem[groupingKey] === groupingKeyValue;
  });

  const productItemGroups = groupByKey(filteredProductItems, 'garmentGroup');

  const totalSalesNet = getTotalSalesNet(productItems);
  const filteredTotalSalesNet = getTotalSalesNet(filteredProductItems);
  const totalSalesNetLastWeek = getTotalLastWeek(filteredProductItems);
  const totalSalesNetLastYear = getTotalLastYear(filteredProductItems);

  return (
    <Container>
      <IconWithText
        label={t(commonTranslations.back.key, commonTranslations.back.defaultValue)}
        onClick={backButtonHandler}
        iconOptions={{ icon: Icons.ARROW, rotate: 'up', size: 'small' }}
        textOptions={{ type: 'button' }}
        position="left"
        data-testid="previous-button"
      />

      <HeaderContainer>
        <Typography type="body2">
          {t(
            productCategoryTranslations.garmentGroup.title.key,
            productCategoryTranslations.garmentGroup.title.defaultValue,
          )}
        </Typography>
        <Typography type="body3">
          {!groupingKeyValue
            ? t(
                productCategoryTranslations.garmentGroup.allText.key,
                productCategoryTranslations.garmentGroup.allText.defaultValue,
              )
            : groupingKeyValue}
        </Typography>
      </HeaderContainer>

      {!isMobile && <TableHeader sortType={null} sortButtonHandler={() => {}} />}

      <ItemsContainer isMobileView={isMobile}>
        {productItemGroups.map((productItemGroup) => {
          const productItem = productItemGroup[0];
          const { garmentGroup } = productItem;

          const groupSalesNet = getTotalSalesNet(productItemGroup);
          const groupSalesNetLastWeek = getTotalLastWeek(productItemGroup);
          const groupSalesNetLastYear = getTotalLastYear(productItemGroup);
          const sob = getSob(groupSalesNet, totalSalesNet);

          return (
            <TableItem
              depth={0}
              isParent={false}
              isExpanded={false}
              title={garmentGroup}
              salesNet={groupSalesNet}
              lastWeek={groupSalesNetLastWeek}
              lastYear={groupSalesNetLastYear}
              sob={sob}
            />
          );
        })}
      </ItemsContainer>

      <TableFooterContainer>
        <TableFooter
          totalSalesNetFiltered={filteredTotalSalesNet}
          totalSalesNet={totalSalesNet}
          salesNetLastWeek={totalSalesNetLastWeek}
          salesNetLastYear={totalSalesNetLastYear}
        />
      </TableFooterContainer>
    </Container>
  );
};
