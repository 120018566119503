export type KpiType =
  | 'dailyConversionRate'
  | 'dailyNewMembers'
  | 'dailyTurnOver'
  | 'dailyShareOfReceipts'
  | 'dailyQueueLength';

export type KpiProperties = {
  widgetName: string;
  apiName: KpiType;
};

export type Kpi = {
  [key: string]: KpiProperties;
};

export const KpiToApiMapping: Kpi = {
  dailyConversionRate: {
    widgetName: 'Conversion Rate',
    apiName: 'dailyConversionRate',
  },
  dailyNewMembers: {
    widgetName: 'New Members',
    apiName: 'dailyNewMembers',
  },
  dailyTurnOver: {
    widgetName: 'Sales Performance',
    apiName: 'dailyTurnOver',
  },
  dailyShareOfReceipts: {
    widgetName: 'Share of Receipts',
    apiName: 'dailyShareOfReceipts',
  },
  dailyQueueLength: {
    widgetName: 'Queue Length',
    apiName: 'dailyQueueLength',
  },
};

export enum KpiModalType {
  KPI = 'Kpi',
  AREA_CAP = 'AreaCap',
}

export enum KpiEditTypes {
  ADD = 'Add',
  UPDATE = 'Update',
  REMOVE = 'Remove',
}

export enum KpiDropdownActions {
  ADDKPI = 'addKpi',
  REMOVEKPI = 'removeKpi',
  UPDATEKPI = 'editKpi',
  ADDAREACAP = 'addAreaCap',
  REMOVEAREACAP = 'removeAreaCap',
  UPDATEAREACAP = 'editAreaCap',
  HIGHLIGHT = 'highlightAreaKpi',
  UNHIGHLIGHT = 'unHighlightAreaKpi',
}

export enum Forecast {
  PERCENTAGE = 'percentage',
  NUMBER = 'number',
  CURRENCY = 'currency',
}

export enum KpiAreaValueType {
  TARGET = 'target',
  RECOMMENDED_TARGET = 'recommendedTarget',
  AREA_AVERAGE = 'areaAverage',
  AREA_MIN = 'areaMin',
  AREA_MAX = 'areaMax',
  AREA_CAP = 'areaCap',
}

export type KpiAreaValue = {
  kpiProperty: KpiAreaValueType;
  kpiValue: number | null;
};

export enum KpiModalViews {
  INITIAL = 'initial',
  SUCCESS = 'success',
  ERROR = 'error',
}

export type AreaCapProps = {
  [key in KpiType]: number | null;
};
export type TargetProps = {
  [key in KpiType]: {
    locked: boolean;
    target: number | null;
  };
};

export type Kpis = {
  targets: TargetProps;
  areaCaps: AreaCapProps;
};

export const hasPercentageinKpi = (kpiName: KpiType) =>
  kpiName === 'dailyConversionRate' || kpiName === 'dailyShareOfReceipts' || kpiName === 'dailyQueueLength';

export type KPIDataProp = {
  kpiName: KpiType;
  target: number | null;
  areaCap: number | null;
  isTargetChanged: boolean;
  isAreaCapChanged: boolean;
  isError: boolean;
  isTargetLocked: boolean;
};

export type UpdateKpiRequest = {
  kpiName: KpiType;
  target?: number | null;
  isLocked?: boolean;
  areaCap?: number | null;
};

export const getErrorStatus = (
  hasPercentage: boolean,
  inputValue: string,
  previousValue?: number | null,
): { status: boolean; text: string } => {
  // max value 100 or 10 digits
  const maximumValueCondition = hasPercentage ? parseInt(inputValue) > 100 : inputValue.length > 10;

  if (inputValue === '' && (previousValue === null || previousValue === undefined)) {
    return {
      status: false,
      text: '',
    };
  }

  if (inputValue === '' && previousValue !== null) {
    return {
      status: true,
      text: 'errorHelperText4',
    };
  }

  // No 0 value
  if (inputValue === '0') {
    return {
      status: true,
      text: 'errorHelperText5',
    };
  }

  // No special characters or alphabets
  if (!/^\d+$/.test(inputValue)) {
    return {
      status: true,
      text: 'errorHelperText1',
    };
  }

  // max value 100 or 10 digits
  if (maximumValueCondition) {
    return {
      status: true,
      text: hasPercentage ? 'errorHelperText3' : 'errorHelperText2',
    };
  }

  return {
    status: false,
    text: '',
  };
};

export const getKPIIcons = (kpiName: KpiType) => {
  return kpiName === 'dailyConversionRate' || kpiName === 'dailyTurnOver' ? 'SALESEFFICIENCY' : 'CUSTOMERVALUE';
};

// Get respective Units for KPI
export const getUnit = (kpiName: KpiType, currency: string | undefined) => {
  switch (kpiName) {
    case 'dailyConversionRate':
    case 'dailyShareOfReceipts':
    case 'dailyQueueLength':
      return '%';
    case 'dailyTurnOver':
      return currency;
    default:
      return '';
  }
};
