import styled from 'styled-components';
import { Container, Icon } from '../../../stories/atoms';
import { cardShadow } from '../../../theme';

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledNewMemberWrapper = styled(Container)`
  border-top: 1px solid ${({ theme: { color } }) => color.borderGray};
`;

export const StyledIcon = styled(Icon)`
  width: ${({ theme: { spacing } }) => spacing(7)};
  height: ${({ theme: { spacing } }) => spacing(7)};
  padding: ${({ theme: { spacing } }) => spacing(2)};
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
  background-color: ${({ theme: { color, palette } }) =>
    palette.mode === 'dark' ? color.backgroundSelectedDark : color.borderGray};
  justify-content: center;
  cursor: pointer;
`;

export const StyledSection = styled(Container)`
  ${cardShadow()};
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
`;
