import styled from 'styled-components';
import { Container } from '../../../stories/atoms';
import { Colors } from '../../../theme';

export const StyledContainer = styled(Container)<{ borderColor: Colors }>`
  border: 2px solid
    ${({ theme: { color, palette }, borderColor }) =>
      palette.mode === 'dark' ? borderColor && color[borderColor] : color.inherit};
  border-radius: ${({ theme: { spacing } }) => spacing(2)};
`;
